import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import TermsOfUseModal from './TermsOfUseModal';
import PrivacyPolicyModal from './PrivacyPolicyModal';
import VirtualInternshipModal from './VirtualInternshipModal';
import DataCollectionModal from './DataCollectionModal';
import { config } from '../constant';

function ApplyNowForm({ show, hide }) {
   const userAgent = navigator.userAgent;
   const isMobile = /Mobile/.test(userAgent);
   let apiKey = process.env.REACT_APP_API_KEY;
   const url = config.url.baseUrl;
   const [inputs, setInputs] = useState({
      firstName: '',
      lastName: '',
      email: '',
      gender: '',
      country: '',
      college: '',
      mobile: '',
      program: 'Virtual Internship',
      course: '',
      educationLevel: '',
      yearOfPassing: '',
      degreeType: '',
      state: '',
      city: '',
      specialization: '',
      switchbtn1: false,
      switchbtn2: false,
      switchbtn3: false,
      captcha: ''
   });
   const [message, setMessage] = useState('');
   const [errorMsg, setErrorMsg] = useState("");
   const [calerror, setCalerror] = useState("");
   const [isValid, setIsValid] = React.useState(true);
   const [base64Image, setBase64Image] = useState("");
   const [token, setToken] = useState("");
   const [stateData, setStateData] = useState(null);
   const [eduData, setEduData] = useState(null);
   const [degreeTypeData, setDegreeTypeData] = useState(null);
   const [specializationData, setSpecializationData] = useState(null);
   const [selectedEducation, setSelectedEducation] = useState('');
   const [selectedDegreeType, setSelectedDegreeType] = useState('');
   const currentYear = new Date().getFullYear();
   const [tmcModalShow, setTmcModalShow] = useState(false);
   const [ppModalShow, setPPModalShow] = useState(false);
   const [viModalShow, setVIModalShow] = useState(false);
   const [dcModalShow, setDCModalShow] = useState(false);
   const [isApply, setIsApply] = useState(false);
   const handletmcModalShow = () => {
      setTmcModalShow(true);
   }
   const handletmcModalClose = () => {
      setTmcModalShow(false);
   }

   const handleppModalClose = () => {
      setPPModalShow(false);
   }

   const handleppModalShow = () => {
      setPPModalShow(true);
   }

   const handleVIModalClose = () => {
      setVIModalShow(false);
   }

   const handleVIModalShow = () => {
      setVIModalShow(true);
   }

   const handleDCModalClose = () => {
      setDCModalShow(false);
   }

   const handleDCModalShow = () => {
      setDCModalShow(true);
   }

   const [validations, setValidations] = React.useState({
      firstName: '',
      lastName: '',
      email: '',
      gender: '',
      country: '',
      college: '',
      mobile: '',
      program: '',
      course: '',
      educationLevel: '',
      state: '',
      city: '',
      yearOfPassing: '',
      degreeType: '',
      others: '',
      specialization: '',
      switchbtn1: false,
      switchbtn2: false,
      switchbtn3: false,
      errorMessage: ''
   })

   useEffect(() => {
      const fetchStateData = async () => {
         try {
            const response = await fetch('./json/states.json');
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.indexOf('application/json') !== -1) {
               const jsonData = await response.json();
               setStateData(jsonData);
             }
           
         } catch (error) {
            console.log(error);
         }
      };

      fetchStateData();
   }, []);

   useEffect(() => {

      const fetchEduData = async () => {
         try {
            const response = await fetch('./json/educationalInformation.json');
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.indexOf('application/json') !== -1) {
               const jsonData = await response.json();
            setEduData(jsonData);
             }
           

         } catch (error) {
            console.log(error);
         }
      };

      fetchEduData();
   }, []);


   const handleChange = (event) => {
      const { name, value, type, checked } = event.target;
      const newValue = type === 'checkbox' ? checked : value;
      setInputs(values => ({ ...values, [name]: newValue }));

      if (name === "country" || name === "program") {
         handleFieldValidation('state', '');
         handleFieldValidation('city', '');
         handleFieldValidation('educationLevel', '');
         handleFieldValidation('yearOfPassing', '');
      }

      if (name === "educationLevel") {
         setInputs(prevInputs => ({
            ...prevInputs,
            degreeType: '',
            specialization: ''
          }));
         const degreeData = eduData?.degreeType;
         const filterDegreeData = degreeData?.filter((item) => {
            return item.edu_details_id === value;
         });
         setDegreeTypeData(filterDegreeData);
         const selectedOptionText = event.target.selectedOptions[0].text;
         setSelectedEducation(selectedOptionText);
      }

      if (name === "degreeType") {
         setInputs(prevInputs => ({
            ...prevInputs,
            specialization: ''
          }));
         const specializationData = eduData?.specialization;
         const filterSpecializationData = specializationData.filter((item) => {
            return item.edu_details_degree_id === value;
         });
         setSpecializationData(filterSpecializationData);
         const selectedOptionText = event.target.selectedOptions[0].text;
     
         setSelectedDegreeType(selectedOptionText);
      }

   }
   const handleFieldValidation = (field, errorMessage) => {
      if (!inputs[field]) {
         setValidations(prevState => ({
            ...prevState,
            [field]: errorMessage
         }));
         return false;
      } else {

         if (field === 'firstName') {
            const pattern = /^[A-Za-zÀ-ÿ ,.'-]+$/;
            if (!pattern.test(inputs[field])) {
               setValidations(prevState => ({
                  ...prevState,
                  [field]: 'Please enter valid first Name'
               }));
               return false;
            }
         }

         if (field === 'lastName') {
            const pattern = /^[A-Za-zÀ-ÿ ,.'-]+$/;
            if (!pattern.test(inputs[field])) {
               setValidations(prevState => ({
                  ...prevState,
                  [field]: 'Please enter valid last Name'
               }));
               return false;
            }
         }

         if (field === 'mobile') {
            const phoneRegex = /^(?:\+(?:[0-9] ?){6,14}[0-9]|(?:\+?1[2-9]\d{2}[2-9](?!11)\d{6})|(?:[6789]\d{9}))$/;
            if (!phoneRegex.test(inputs[field])) {
               setValidations(prevState => ({
                  ...prevState,
                  [field]: 'Please enter valid mobile number'
               }));
               return false;
            }
         }

         if (field === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(inputs[field])) {
               setValidations(prevState => ({
                  ...prevState,
                  [field]: 'Please enter valid email address'
               }));
               return false;
            }
         }

         else {
            setValidations(prevState => ({
               ...prevState,
               [field]: ''
            }));
         }
         return true;
      }
   };

   useEffect(() => {
      const requestOptions = {
         headers: {
            'Content-Type': 'application/json',
            "X-API-Key": apiKey
         }
      };
      const getImageCaptcha = () => {
         axios.get(url + '/captcha/token-image', requestOptions)
            .then(response => {
               setBase64Image(response.data.encodedImage);
               setToken(response.data.token);
            })
            .catch(error => {
               console.error('Error:', error);
            });
      }
      getImageCaptcha();
   }, [url, apiKey]);

   const handleSubmit = async (event) => {
      event.preventDefault();
      let isFormValid = true;
      setValidations(prevState => ({
         ...prevState,
         firstName: '',
         lastName: '',
         email: '',
         country: '',
         college: '',
         mobile: '',
         program: '',
         course: '',
         switchbtn1: false,
         switchbtn2: false,
      }));

      // Check each field and set error messages if required
      if (!handleFieldValidation('firstName', 'Please enter your First Name.')) {
         isFormValid = false;
      }

      if (!handleFieldValidation('lastName', 'Please enter your Last Name.')) {
         isFormValid = false;
      }

      if (!handleFieldValidation('email', 'Please enter your Email.')) {
         isFormValid = false;
      }

      if (!handleFieldValidation('gender', 'Please select your Gender.')) {
         isFormValid = false;
      }

      if (!handleFieldValidation('country', 'Please select your Country.')) {
         isFormValid = false;
      }

      if (!handleFieldValidation('college', 'Please enter your Company/College.')) {
         isFormValid = false;
      }

      if (!handleFieldValidation('mobile', 'Please enter your Phone.')) {
         isFormValid = false;
      }

      if (!handleFieldValidation('program', 'Please select your Programme.')) {
         isFormValid = false;
      }

      if (
         inputs.program === "Virtual Internship" &&
         (inputs.course === "CR_GENAI_EN_02" || inputs.course === "CR_GENAI_HI_01")
      ) {
         if (!handleFieldValidation('educationLevel', 'Please select your Education Level.')) {
            isFormValid = false;
         }
         if (!handleFieldValidation('yearOfPassing', 'Please select your Year of Passing.')) {
            isFormValid = false;
         }

         if (inputs.educationLevel !== "1" && inputs.educationLevel !== "") {
            if (!handleFieldValidation('degreeType', 'Please select your Degree Type.')) {
               isFormValid = false;
            }
            if (inputs.degreeType === "") {
               if (!handleFieldValidation('degreeType', 'Please select your Degree Type.')) {
                  isFormValid = false;
               }
            }
            if (inputs.specialization === "") {
               if (!handleFieldValidation('specialization', 'Please select your Specialization.')) {
                  isFormValid = false;
               }
            }

            if (inputs.degreeType !== "") {
               if (!handleFieldValidation('specialization', 'Please select your Specialization.')) {
                  isFormValid = false;
               }
            }
         }
      }
      else {
         inputs.state = "";
         inputs.city = "";
         inputs.educationLevel = "";
         inputs.yearOfPassing = "";
         inputs.city = "";
      }

      if (inputs.country === "IN") {
         if (!handleFieldValidation('state', 'Please select your University/College State.')) {
            isFormValid = false;
         }

         if (!handleFieldValidation('city', 'Please enter your University/College City.')) {
            isFormValid = false;
         }
      }

      if (!handleFieldValidation('course', 'Please select your Course.')) {
         isFormValid = false;
      }

      if (!handleFieldValidation('switchbtn1', '')) {
         isFormValid = false;
      }

      if (!handleFieldValidation('switchbtn2', '')) {
         isFormValid = false;
      }

      if (!handleFieldValidation('switchbtn3', '')) {
         isFormValid = false;
      }

      if (!handleFieldValidation('captcha', 'Please enter calculated value.')) {
         isFormValid = false;
      }

      setIsValid(isFormValid);
      if (isFormValid) {
         setIsApply(true);
         const requestOptions = {
            headers: {
               "X-API-Key": apiKey
            }
         };
         let payload = {
            captcha: {
               token: token,
               captchaId: inputs.captcha
            },
            user: {
               city: inputs.city,
               country: inputs.country,
               courseId: inputs.course,
               degreeType: selectedDegreeType,
               educationLevel: selectedEducation,
               email: inputs.email,
               firstname: inputs.firstName,
               institution: inputs.college,
               lastname: inputs.lastName,
               gender: inputs.gender,
               mobile: inputs.mobile,
               programme: inputs.program,
               source: isMobile ? 'Webiste/Mobile' : 'Website',
               specialization: inputs.specialization,
               state: inputs.state,
               yearOfPassing: inputs.yearOfPassing
            }
         }
         try {
            const result = await axios.post(url + '/enrollment/learner', payload, requestOptions);
            if (result.status === 200) {
               setErrorMsg('');
               setCalerror('');             
               if (result.data.status === 'enroll') {
                  setMessage(result.data.message);
                  setTimeout(() => {
                     modalClose();
                     setIsApply(false);
                  }, 4000);
               }
               if (result.data.status === 'already enroll') {
                  setMessage(result.data.message);
                  setIsApply(false);
               }
              
            }
         }
         catch (error) {
            console.log(error);
            setIsApply(false);
            if (error.code === "ERR_NETWORK") {
               setErrorMsg("Network Error");
            }
            if (error.response.data.detail.status === "calculation") {
               setCalerror(error.response.data.detail.message);
            }

            if (error.response.data.detail.status === "courses") {
               setErrorMsg(error.response.data.detail.message);
            }
          
         }
      }
      else {
         const modalDialog = document.querySelector('.apply-now-form-modal');
         modalDialog.scrollTo(0, 130);
      }
   }
   const modalClose = () => {
      hide();
   }

   const yearOptions = [];
   for (let i = currentYear - 3; i <= currentYear + 2; i++) {
      yearOptions.push(<option key={i} value={i}>{i}</option>);
   }

   yearOptions.unshift(<option key="beforePreviousYear" value={`Before ${currentYear - 3}`}>Before {currentYear - 3}</option>);


   return (
      <div>
         <Modal size="xl" backdrop="static" keyboard={false} show={show}
            onHide={modalClose} className="apply-now-form-modal applynow-common">
            <Modal.Header closeButton className="justify-content-center">
               <Modal.Title style={{ fontSize: '32px' }}>Apply Now</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div className="row">
                  <div className="col-md-12 pd-40">
                     <form className="row g-3" onSubmit={handleSubmit}>
                        <div className={`col-md-6 ${validations.firstName && 'has-error'}`}>
                           <div className={`bg_inputhelp1`}>
                              <label className="form-label">First Name</label>
                              <input type="text"
                                 className="form-control" name="firstName" value={inputs.firstName || ""}
                                 placeholder="First Name" onChange={handleChange} />
                              {validations.firstName && (
                                 <div className="error-message">{validations.firstName}</div>
                              )}
                           </div>
                        </div>
                        <div className={`col-md-6 ${validations.lastName && 'has-error'}`}>
                           <div className="bg_inputhelp1">
                              <label className="form-label">Last Name</label>
                              <input type="text"
                                 className="form-control" placeholder="Last Name" name='lastName' value={inputs.lastName || ""}
                                 onChange={handleChange} />
                              {validations.lastName && (
                                 <div className="error-message">{validations.lastName}</div>
                              )}
                           </div>
                        </div>
                        <div className={`col-md-6 ${validations.email && 'has-error'}`}>
                           <div className="bg_inputhelp1">
                              <label className="form-label">Email</label>
                              <input type="text"
                                 className="form-control"
                                 placeholder="Email ID" name='email' value={inputs.email || ""} onChange={handleChange} id="emailId" />
                              {validations.email && (
                                 <div className="error-message">{validations.email}</div>
                              )}
                           </div>
                        </div>
                        <div className={`col-md-6 ${validations.gender && 'has-error'}`}>
                           <div className="bg_inputhelp1">
                              <label className="form-label">Gender</label>
                              <select className="form-control form-select custom-select" name='gender' value={inputs.gender} onChange={handleChange}>
                                 <option value="">Select </option>
                                 <option value="male">Male</option>
                                 <option value="female">Female</option>
                                 <option value="other">Other</option>
                                 <option value="Prefer not to say">Prefer not to say</option>
                              </select>
                              {validations.gender && (
                                 <div className="error-message">{validations.gender}</div>
                              )}
                           </div>
                        </div>
                        <div className={`col-md-6 ${validations.country && 'has-error'}`}>
                           <div className="bg_inputhelp1">
                              <label className="form-label">Country</label>
                              <select className="form-control form-select custom-select" name='country' value={inputs.country} onChange={handleChange}>
                                 <option value="">Select </option>
                                 <option value="IN">India</option>
                                 <option value="Others">Others</option>
                              </select>
                              {validations.country && (
                                 <div className="error-message">{validations.country}</div>
                              )}
                           </div>
                        </div>
                        <div className={`col-md-6 ${validations.college && 'has-error'}`}>
                           <div className="bg_inputhelp1">
                              <label className="form-label">Company/College Name</label>
                              <input type="text"
                                 className="form-control" name='college' value={inputs.college || ""} onChange={handleChange} />
                              {validations.college && (
                                 <div className="error-message">{validations.college}</div>
                              )}
                           </div>
                        </div>
                        <div className={`col-md-6 ${validations.mobile && 'has-error'}`}>
                           <div className="bg_inputhelp1">
                              <label className="form-label">Phone Number</label>
                              <input type="text" className="form-control"
                                 maxLength="10"
                                 id="inputPhone" name="mobile" onChange={handleChange} placeholder="Phone Number"
                                 value={inputs.mobile || ""} />
                              {validations.mobile && (
                                 <div className="error-message">{validations.mobile}</div>
                              )}
                           </div>
                        </div>
                        <div className={`col-md-6 ${validations.program && 'has-error'}`}>
                           <div className="bg_inputhelp1">
                              <label className="form-label">Programmes</label>
                              <select className="form-control form-select custom-select"
                                 id="program" name='program' value={inputs.program} onChange={handleChange}>
                                 <option value="Virtual Internship">Virtual Internship</option>
                              </select>
                              {validations.program && (
                                 <div className="error-message">{validations.program}</div>
                              )}
                           </div>
                        </div>
                        <div className={`col-md-6 ${validations.course && 'has-error'}`}>
                           <div className="bg_inputhelp1">
                              <label className="form-label">Course</label>
                              <select className="form-control form-select custom-select" id="course"
                                 name='course' value={inputs.course} onChange={handleChange}>
                                 <option value=""> Select </option>
                                 <option value="CR_GENAI_EN_02">Gen AI in Eng, Registration closed, join waitlist</option>
                                 <option value="CR_GENAI_HI_01">Gen AI हिंदी में</option></select>
                              {validations.course && (
                                 <div className="error-message">{validations.course}</div>
                              )}
                           </div>
                        </div>
                        {inputs.program === "Virtual Internship" && (inputs.course === "CR_GENAI_EN_02" || inputs.course === "CR_GENAI_HI_01") && (
                           <>
                              <div className={`col-md-6 ${validations.educationLevel && 'has-error'}`}>
                                 <div className="bg_inputhelp1">
                                    <label className="form-label">Education Level</label>
                                    <select className="form-control form-select custom-select" name='educationLevel' value={inputs.educationLevel} onChange={handleChange}>
                                       <option value="">Select </option>
                                       <option value="1">SSC/10th</option>
                                       <option value="2">HSC/12th/Equivalent</option>
                                       <option value="3">Graduation/ Degree</option>
                                       <option value="4">Post Graduation</option>
                                    </select>
                                    {validations.educationLevel && (
                                       <div className="error-message">{validations.educationLevel}</div>
                                    )}
                                 </div>
                              </div>
                              {(inputs.educationLevel !== "1" && inputs.educationLevel !== "") && (
                                 <>
                                    <div className={`col-md-6 ${validations.degreeType && 'has-error'}`}>
                                       <div className="bg_inputhelp1">
                                          <label className="form-label">Degree Type</label>
                                          <select className="form-control form-select custom-select" name='degreeType' value={inputs.degreeType} onChange={handleChange}>
                                             <option value="">Select </option>
                                             {degreeTypeData?.length > 0 && degreeTypeData.map((degreeType) => (
                                                <option key={degreeType.edu_details_degree_id} value={degreeType.edu_details_degree_id}>
                                                   {degreeType.edu_degree_name}
                                                </option>
                                             ))}
                                          </select>
                                          {validations.degreeType && (
                                             <div className="error-message">{validations.degreeType}</div>
                                          )}
                                       </div>
                                    </div>
                                    {
  inputs.degreeType === "0" ? (
   <div className={`col-md-6 ${validations.specialization && 'has-error'}`}>
   <div className="bg_inputhelp1">
      <label className="form-label">Specialization</label>
      <input type="text"
         className="form-control" name='specialization' value={inputs.specialization || ""} onChange={handleChange} />
      {validations.specialization && (
         <div className="error-message">{validations.specialization}</div>
      )}
   </div>
</div>
  ) : (
    inputs.degreeType !== "" && (
      <div className={`col-md-6 ${validations.specialization ? 'has-error' : ''}`}>
        <div className="bg_inputhelp1">
          <label className="form-label">Specialization</label>
          <select
            className="form-control form-select custom-select"
            name='specialization'
            value={inputs.specialization}
            onChange={handleChange}
          >
            <option value="">Select </option>
            {specializationData?.length > 0 && specializationData.map((spec) => (
              <option key={spec.edu_details_degree_branch_id} value={spec.edu_details_degree_branch_name}>
                {spec.edu_details_degree_branch_name}
              </option>
            ))}
            <option value="other">other</option>
          </select>
          {validations.specialization && (
            <div className="error-message">{validations.specialization}</div>
          )}
        </div>
      </div>
    )
  )
}

                                    {/* {inputs.degreeType !== "" && (
                                       <div className={`col-md-6 ${validations.specialization && 'has-error'}`}>
                                          <div className="bg_inputhelp1">
                                             <label className="form-label">Specialization</label>
                                             <select className="form-control form-select custom-select" name='specialization' value={inputs.specialization} onChange={handleChange}>
                                                <option value="">Select </option>
                                                {specializationData?.length > 0 && specializationData?.map((spec) => (
                                                   <option key={spec.edu_details_degree_branch_id} value={spec.edu_details_degree_branch_name}>
                                                      {spec.edu_details_degree_branch_name}
                                                   </option>
                                                ))}
                                                <option value="other">other</option>
                                             </select>
                                             {validations.specialization && (
                                                <div className="error-message">{validations.specialization}</div>
                                             )}
                                          </div>
                                       </div>
                                    )} */}
                                 </>
                              )}
                              <div className={`col-md-6 ${validations.yearOfPassing && 'has-error'}`}>
                                 <div className="bg_inputhelp1">
                                    <label className="form-label">Year Of Passing</label>
                                    <select className="form-control form-select custom-select" name='yearOfPassing' value={inputs.yearOfPassing} onChange={handleChange}>
                                       <option value="">Select </option>
                                       {yearOptions}
                                    </select>
                                    {validations.yearOfPassing && (
                                       <div className="error-message">{validations.yearOfPassing}</div>
                                    )}
                                 </div>
                              </div>
                              {inputs.country === "IN" && (
                                 <>
                                    <div className={`col-md-6 ${validations.state && 'has-error'}`}>
                                       <div className="bg_inputhelp1">
                                          <label className="form-label">University/College State/UT</label>
                                          <select className="form-control form-select custom-select" name='state' value={inputs.state} onChange={handleChange}>
                                             <option value="">Select </option>
                                             {stateData?.map((option) => (
                                                <option key={option.code} value={option.name}>
                                                   {option.name}
                                                </option>
                                             ))}
                                          </select>
                                          {validations.state && (
                                             <div className="error-message">{validations.state}</div>
                                          )}
                                       </div>
                                    </div>
                                    <div className={`col-md-6 ${validations.city && 'has-error'}`}>
                                       <div className="bg_inputhelp1">
                                          <label className="form-label">University/College City</label>
                                          <input type="text"
                                             className="form-control" placeholder="University/College City" name='city' value={inputs.city || ""}
                                             onChange={handleChange} />
                                          {validations.city && (
                                             <div className="error-message">{validations.city}</div>
                                          )}
                                       </div>
                                    </div>
                                 </>
                              )}
                           </>
                        )}
                        <div className={`col-md-12 ${!isValid && inputs.switchbtn1 === false && 'has-error'}`}>
                           <div className="form-check form-switch">
                              <input className="form-check-input" type="checkbox" name='switchbtn1' value={inputs.switchbtn1} checked={inputs.switchbtn1} onChange={handleChange} />
                              <label className="form-check-label" htmlFor="mySwitch">
                                 By submitting this form, you agree to have your contact information,
                                 including email, <span className='prv-poly'> <Link onClick={handleDCModalShow} > collected and shared </Link> </span>   with the sponsors of this event for the purpose of following up on your interests. Additionally,
                                 you agree to abide by the <span className='prv-poly'> <Link onClick={handleppModalShow} > Privacy Policy
                                 </Link></span>  and <span className='prv-poly'><Link onClick={handletmcModalShow}> Terms and Conditions </Link> </span> of Skillible.
                              </label>
                           </div>
                        </div>
                        <div className={`col-md-12 mt-2 ${!isValid && inputs.switchbtn2 === false && 'has-error'}`}>
                           <div className="form-check form-switch">
                              <input className="form-check-input" type="checkbox" name='switchbtn2' value={inputs.switchbtn2} checked={inputs.switchbtn2} onChange={handleChange} />
                              <label className="form-check-label" htmlFor="mySwitch">I authorize Skillible and its representatives to Call, SMS, Email or Whatsapp me about its products and offers. This consent overrides any registration for DNC / NDNC.
                              </label>
                           </div>
                        </div>
                        <div className={`col-md-12 mt-2 ${!isValid && inputs.switchbtn3 === false && 'has-error'}`}>
                           <div className="form-check form-switch">
                              <input className="form-check-input" type="checkbox" name='switchbtn3' value={inputs.switchbtn3} checked={inputs.switchbtn3} onChange={handleChange} />
                              <label className="form-check-label" htmlFor="mySwitch">By submitting this form, you acknowledge that you are agreeing to the
                                 <span className='prv-poly'>
                                    <Link onClick={handleVIModalShow}> terms and conditions </Link>
                                    <Link to="/Skillible-Virtual-Internship–Terms&Conditions.pdf" target="_blank" download><i className="fa fa-download"></i></Link>
                                 </span> of the Skillible Virtual Internship Program.
                              </label>

                           </div>
                        </div>

                        <div className={`captcha-field ${validations.captcha && 'has-error'}`}>
                           <p className="mb-0">Please verify you are a human</p>
                           <div className='row'>
                              <div className='col-md-12 d-flex'>
                                 <img src={`data:image/png;base64, ${base64Image}`} alt="captcha" />
                                 <span className="equal-text">=</span>
                                 <div className="position-relative">
                                    <input type='number' className='form-control' name='captcha' value={inputs.captcha || ""} onChange={handleChange} />
                                    {validations.captcha ? validations.captcha && (
                                       <div className="error-message">{validations.captcha}</div>
                                    ) : <div className="error-message">{calerror}</div>}
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-12 text-center mb-0">
                           <button disabled={isApply} className="btn SubmitBtn1" type="submit">
                           {isApply ? "Please wait..." : "Apply"}
                              </button>
                        </div>
                        <div className="col-md-12 mt-1 text-center ">
                           {message ?
                              <div className="form-check sucss">
                                 <label className="succes-msg"> {message}
                                 </label>
                              </div> : ''
                           }
                           {errorMsg ?
                              <div className="form-check deng">
                                 <label className="danger-msg" id="not-store-data"> {errorMsg}
                                 </label>
                              </div> : ''
                           }
                        </div>
                     </form>
                  </div>
               </div>
            </Modal.Body>
         </Modal>
         {tmcModalShow && <TermsOfUseModal isModal={true} show={handletmcModalShow} hide={handletmcModalClose} />}
         {ppModalShow && <PrivacyPolicyModal isModal={true} show={handleppModalShow} hide={handleppModalClose} />}
         {viModalShow && <VirtualInternshipModal show={handleVIModalShow} hide={handleVIModalClose} />}
         {dcModalShow && <DataCollectionModal isModal={true} show={handleDCModalShow} hide={handleDCModalClose} />}
      </div>
   )
}

export default ApplyNowForm;
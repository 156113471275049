import React, { useState } from 'react';
import { SERVICE_URL } from '../constant';
import $ from "jquery";
import Axios from 'axios';

export default function ApplyNow() {
   const [firstname, setFirstname] = useState('');
   const [lastname, setLastname] = useState('');
   const [emailaddress, setEmailaddress] = useState('');
   const [mobile, setMobile] = useState('');
   const [college, setCollege] = useState('');
   const [sucess, setSucess] = useState(false);
   const [error, setError] = useState(false);
  // const [msg, setMsg] = useState('');
   const [btlapply, setBtlapply] = useState(false);
   const [switchbtn, setSwitchbtn] = useState(false);
   const [switchbtn2, setSwitchbtn2] = useState(false);
   const requestOptions = {
      headers: {
         'Content-Type': 'application/json',
         "x-api-key": "P3yWDK7Aur2Of8y8AJHDza4GtilPsOG06HKp66b0"
      }
   };

   const handleSubmit = (e) => {
      if (firstname === "" || lastname === "" || emailaddress === "" || mobile === "") {
         setBtlapply(false)
      }

      else {
         var data = {
            first_name: firstname,
            last_name: lastname,
            country: $("#country").find(":selected").text(),
            company_college: college,
            accept_tc: true,
            accept_dnc_override: true,
            emailId: emailaddress,
            apply_course: $("#course").find(":selected").text(),
            apply_program: $("#program").find(":selected").text(),
            mobile: mobile,
         };
         data = JSON.stringify(data)
         Axios.post(
            SERVICE_URL + "/enquiry/learner",
            data, requestOptions, { crossDomain: true })
            .then(res => {
               console.log("response: ", res);
               if (res.status === 200) {
                  setSucess(true)
                  document.getElementById("popupform-d").reset();
                  setFirstname('');
                  setEmailaddress('');
                  setLastname('');
                  setCollege('');
                  setMobile('');
                  setSwitchbtn(false);
                  setSwitchbtn2(false);
                  setTimeout(function () {
                     document.getElementById('closeButton').click();
                  }, 2000);
                 // setMsg('Thank you for contacting with us. We will back to you soon.')
                  console.log('test', sucess);
               } else {
                  setError(true);
                  alert("error");
               }
            })
            .catch((err) => {
               setBtlapply(false)
               console.log("AXIOS ERROR: ", err);

            })
      }

   };


   return (
      <div>
         <div className="modal fade Refersocail" id="apply-form" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabIndex="-1">
            <div className="modal-dialog modal-xl">
               <div className="modal-content apply-now-form-body">
                  <div className="modal-header">
                     <h3 className="modal-title" id="exampleModalToggleLabel2">Apply Now</h3>
                     <button type="button" className="close" id="closeButton" data-bs-dismiss="modal" aria-label="Close"><i className="fas fa-times">
                     </i>
                     </button>
                  </div>
                  <div className="modal-body">
                     <div className="row">
                        <div className="col-md-12 pd-40">
                           <form className="row g-3 needs-validation" noValidate="" id="popupform-d2"  >
                              <div className="col-md-6">
                                 <div className="bg_inputhelp1">
                                    <label className="form-label">First Name</label>
                                    <input type="text"
                                       className="form-control" name="fname" aria-describedby="inputGroupPrepend" id="fname" placeholder="First Name" required pattern="^[A-Za-zÀ-ÿ ,.'-]+$" onChange={e => setFirstname(e.target.value)} />
                                    <div className="invalid-feedback">
                                       Please enter your first name.
                                    </div>
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="bg_inputhelp1">
                                    <label className="form-label">Last Name</label>
                                    <input type="text"
                                       className="form-control" name="fname" aria-describedby="inputGroupPrepend" id="lname" placeholder="last Name" required pattern="^[A-Za-zÀ-ÿ ,.'-]+$" onChange={e => setLastname(e.target.value)} />
                                    <div className="invalid-feedback">
                                       Please enter your last name.
                                    </div>
                                 </div>
                              </div>

                              <div className="col-md-6">
                                 <div className="bg_inputhelp1">
                                    <label className="form-label">Email*</label>
                                    <input type="email"
                                       className="form-control" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" placeholder="Email ID" required onChange={e => setEmailaddress(e.target.value)} id="emailId" />
                                    <div className="invalid-feedback">
                                       Please enter your email*.
                                    </div>
                                 </div>
                              </div>

                              <div className="col-md-6">
                                 <div className="bg_inputhelp1">
                                    <label className="form-label">Country</label>
                                    <select className="form-control  form-select custom-select" id="country" required>
                                       <option defaultValue disabled value="">Country </option>
                                       <option value="1">India</option>
                                       <option value="2">Others</option>

                                    </select>
                                    <div className="invalid-feedback">
                                       Please enter your country.
                                    </div>
                                 </div>
                              </div>

                              <div className="col-md-6">
                                 <div className="bg_inputhelp1">
                                    <label className="form-label">Company/College Name*</label>
                                    <input type="text"
                                       className="form-control" required onChange={e => setCollege(e.target.value)} id="companyname" />
                                    <div className="invalid-feedback">
                                       Please enter your Company/College.
                                    </div>
                                 </div>
                              </div>

                              <div className="col-md-6">
                                 <div className="bg_inputhelp1">
                                    <label className="form-label">Phone Number*</label>
                                    {/* <input className="form-control" type="tel" pattern="[0-9\-\(\) +]{5,22}" maxlength="10" name="phone" id="phone" placeholder="Phone" required value="9090909090" oninput="numberOnly(this.id);" onChange={e=> setMobile(e.target.value)} /> */}
                                    <input type="text" className="form-control" pattern="[0-9\-\(\) +]{5,22}" maxLength="10" required id="inputPhone" name="mobile" onChange={e => setMobile(e.target.value)} placeholder="Enter your contact no." value={mobile} />

                                    <div className="invalid-feedback">
                                       Please enter phone.
                                    </div>
                                 </div>
                              </div>

                              <div className="col-md-6">
                                 <div className="bg_inputhelp1">
                                    <label className="form-label">Programmes</label>
                                    <select className="form-control form-select custom-select" id="program" required>
                                       <option defaultValue disabled value="">Programmes </option>
                                       <option>Woman In Tech</option>
                                       <option>Campus Connect</option>
                                       <option>Tech Scaler </option>
                                    </select>
                                    <div className="invalid-feedback">
                                       Please Select your Programme.
                                    </div>
                                 </div>
                              </div>


                              <div className="col-md-6">
                                 <div className="bg_inputhelp1">
                                    <label className="form-label">Course</label>
                                    <select className="form-control form-select custom-select" id="course" required>
                                       <option defaultValue disabled value=""> Courses </option>
                                       <option >Salesforce Development Programme </option>
                                       <option >Full Stack Development </option></select>
                                    <div className="invalid-feedback">
                                       Please enter your course.
                                    </div>
                                 </div>
                              </div>

                              <div className="col-md-12">
                                 <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" value={switchbtn} onChange={e => setSwitchbtn(e.target.value)} id="invalidCheck" required />
                                    <label className="form-check-label" htmlFor="mySwitch">By submitting this form, you agree to have your contact information, including email, passed on to the sponsors of this event for the purpose of following up on your interests, and agree to the Privacy Policy and T&C of Skillible.
                                    </label>
                                 </div>
                              </div>


                              <div className="col-md-12">
                                 <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" value={switchbtn2} onChange={e => setSwitchbtn2(e.target.value)} id="invalidCheck" required />
                                    <label className="form-check-label" htmlFor="mySwitch">I authorize Skillible and its representatives to Call, SMS, Email or Whatsapp me about its products and offers. This consent overrides any registration for DNC / NDNC.
                                    </label>
                                 </div>
                              </div>

                              <div className="col-md-12 text-center mb-0">
                                 <button className="btn SubmitBtn1" type="submit" onClick={(e) => handleSubmit(e)} disabled={btlapply}>Apply</button>
                              </div>

                              <div className="col-md-12 text-center ">
                                 {sucess ?
                                    <div className="form-check sucss">
                                       <label className="succes-msg"> Thank you! We will get in touch with you soon!
                                       </label>

                                    </div> : ''


                                 }

                                 {error ?
                                    <div className="form-check deng">
                                       <label className="danger-msg" id="not-store-data"> error
                                       </label>
                                    </div> : ''
                                 }

                              </div>

                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}
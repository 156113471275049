import React from "react";
import Modal from 'react-bootstrap/Modal';
import PrivacyPolicy from "./PrivacyPolicy";
const PrivacyPolicyModal = ({show, hide, isModal }) => {

    const modalClose = () => {
        hide();
     }

    return(
        
        <Modal className="tmcModal" size="xl" show={show} onHide={modalClose} scrollable>
        <Modal.Header closeButton>
        <h3 className="modal-title tnc-use">Privacy <span>Policy</span></h3>
        </Modal.Header>
        
        <div className="modal-body">
                     <div className="row">
                        <div className="col-md-12">
                           <div className="row">
                           <PrivacyPolicy isModal= {isModal} />
                             
                           </div>
                   
                        </div>
                     </div>
                  </div>
       
      </Modal>
        
    )
}

export default PrivacyPolicyModal;
import React, { useState } from 'react';
import { SERVICE_URL } from '../constant';
import Axios from 'axios';

export default function EnquireLightbox() {
   const [firstname, setFirstname] = useState('');
   const [lastname, setLastname] = useState('');
   const [emailaddress, setEmailaddress] = useState('');
   const [mobile, setMobile] = useState('');
   const [college, setCollege] = useState('NA');
   const [sucess, setSucess] = useState(false);
   const [switchbtn, setSwitchbtn] = useState(false);
   const [btlapply, setBtlapply] = useState(false);

   const requestOptions = {
      headers: {
         'Content-Type': 'application/json',
         "x-api-key": "P3yWDK7Aur2Of8y8AJHDza4GtilPsOG06HKp66b0"
      }
   };

   const closeForm = () => {
      document.querySelector(".popup").style.display = "none";
   }
   const handleSubmit = (e) => {
      setBtlapply(true)
      if (firstname === "" || lastname === "" || emailaddress === "" || mobile === "") {
         setBtlapply(false)
      }

      else {
         var data = {
            first_name: firstname,
            last_name: lastname,
            country: "India",
            company_college: college,
            accept_tc: true,
            accept_dnc_override: true,
            emailId: emailaddress,
            apply_course: "Salesforce Development Programme",
            apply_program: "Career Vridhi",
            mobile: mobile,
         };
         data = JSON.stringify(data)
         Axios.post(
            SERVICE_URL + "/enquiry/learner",
            data, requestOptions, { crossDomain: true })
            .then(res => {
               console.log("response: ", res);
               if (res.status === 200) {
                  setSucess(true)

                  document.getElementById("popupform-d").reset();
                  setFirstname(" ");
                  setEmailaddress(" ");
                  setLastname(" ");
                  setCollege(" ");
                  setMobile(" ");
                  setSwitchbtn(false);
                  setTimeout(function () {
                     document.getElementById('popup-snf').click();
                  }, 2000);

                 // setMsg('Thank you for contacting with us. We will back to you soon.')
                  console.log('test', sucess);
               }
            })
            .catch((err) => {
               setBtlapply(false)
               console.log("AXIOS ERROR: ", err);
            })
      }
   };
   return (
      <div>
         <div className="modal Refersocail popup" id='popupsho-hide' aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabIndex="-1" >
            <div className="modal-dialog modal-md">
               <div className="modal-content apply-now-form-body popup-show-new">
                  <div className="modal-header">
                     <h3 className="modal-title popup-show-content" id=""> Unleash the Power of <span className='salesforce-popup-t'> Salesforce
                        Development Program</span>, Exclusively for Today's  Empowered Women! </h3>
                     <p className='popup-small-text'> <span> Register Now ! </span> To embark upon a path to limitless opportunities.</p>
                     <button id="popup-snf" className="close" type="button" onClick={e => closeForm()} ><i className="fas fa-times"></i></button>
                  </div>
                  <div className="modal-body">
                     <div className="row">
                        <div className="col-md-12 pd-40">
                           <form className="row g-3 needs-validation" id="popupform-d" noValidate=""  >
                              <div className="col-md-12">
                                 <div className="bg_inputhelp1">
                                    <label className="form-label">First Name</label>
                                    <input type="text"
                                       className="form-control" value={firstname} name="fname" aria-describedby="inputGroupPrepend" id="fname" placeholder="First Name" required pattern="^[A-Za-zÀ-ÿ ,.'-]+$" onChange={e => setFirstname(e.target.value)} />
                                    <div className="invalid-feedback">
                                       Please enter your first name.
                                    </div>
                                 </div>
                              </div>
                              <div className="col-md-12">
                                 <div className="bg_inputhelp1">
                                    <label className="form-label">Last Name</label>
                                    <input type="text"
                                       className="form-control" value={lastname} name="fname" aria-describedby="inputGroupPrepend" id="lname" placeholder="last Name" required pattern="^[A-Za-zÀ-ÿ ,.'-]+$" onChange={e => setLastname(e.target.value)} />
                                    <div className="invalid-feedback">
                                       Please enter your last name.
                                    </div>
                                 </div>
                              </div>

                              <div className="col-md-12">
                                 <div className="bg_inputhelp1">
                                    <label className="form-label">Email*</label>
                                    <input type="email"
                                       className="form-control" value={emailaddress} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" placeholder="Email ID" required onChange={e => setEmailaddress(e.target.value)} id="emailId" />
                                    <div className="invalid-feedback">
                                       Please enter your email*.
                                    </div>
                                 </div>
                              </div>
                              <div className="col-md-6 hide-field">
                                 <div className="bg_inputhelp1">
                                    <label className="form-label">Country</label>
                                    <select className="form-control  form-select custom-select" id="country" required>
                                       <option defaultValue disabled value="">India </option>
                                       <option value="1">India</option>


                                    </select>
                                    <div className="invalid-feedback">
                                       Please enter your country.
                                    </div>
                                 </div>
                              </div>
                              <div className="col-md-6 hide-field"  >
                                 <div className="bg_inputhelp1">
                                    <label className="form-label">Company/College Name*</label>
                                    <input type="text"
                                       className="form-control" required onChange={e => setCollege(e.target.value)} id="companyname" />
                                    <div className="invalid-feedback">
                                       Please enter your Company/College.
                                    </div>
                                 </div>
                              </div>

                              <div className="col-md-12">
                                 <div className="bg_inputhelp1">
                                    <label className="form-label">Phone Number*</label>
                                    <input type="tel" className="form-control" pattern="[0-9\-\(\) +]{5,22}" maxLength="10" required id="inputPhone" name="mobile" onChange={e => setMobile(e.target.value)} placeholder="Enter your contact no." value={mobile} />
                                    <div className="invalid-feedback">
                                       Please enter phone.
                                    </div>
                                 </div>
                              </div>

                              <div className="col-md-6 hide-field">
                                 <div className="bg_inputhelp1">
                                    <label className="form-label">Programmes</label>
                                    <select className="form-control form-select custom-select" id="program" required>
                                       <option defaultValue disabled value="Woman In Tech">Woman In Tech </option>
                                       <option>Career Vridhi</option>
                                    </select>
                                    <div className="invalid-feedback">
                                       Please Select your Programme.
                                    </div>
                                 </div>
                              </div>
                              <div className="col-md-6 hide-field">
                                 <div className="bg_inputhelp1">
                                    <label className="form-label">Course</label>
                                    <select className="form-control form-select custom-select" id="course" required>
                                       <option defaultValue disabled value=""> Courses </option>
                                       <option >Salesforce Development Programme </option>
                                       <option >Full Stack Development </option></select>
                                    <div className="invalid-feedback">
                                       Please enter your course.
                                    </div>
                                 </div>
                              </div>

                              <div className="col-md-12">
                                 <div className="form-check form-switch poup-swtich">
                                    <input className="form-check-input" type="checkbox" value={switchbtn} onChange={e => setSwitchbtn(e.target.value)} id="invalidCheck" required />
                                    <label className="form-check-label" htmlFor="mySwitch">
                                       By submitting this form, you agree to share your contact information with event sponsors, adhere to Skillible's policies, and authorize Skillible to contact you regarding their products and offers.
                                    </label>
                                 </div>
                              </div>
                              <div className="col-md-12 text-center mb-0">
                                 <button className="btn SubmitBtn1" type="submit" onClick={(e) => handleSubmit(e)} disabled={btlapply}>Apply</button>
                              </div>

                              <div className="col-md-12 text-center ">
                                 {sucess ?
                                    <div className="form-check sucss">
                                       <label className="succes-msg"> Thank you! We will get in touch with you soon!
                                       </label>
                                    </div> : ''
                                 }
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}
import React from 'react'
import { Link } from "react-router-dom";
import HeaderHome from './HeaderHome'
export default function Faq() {
  return (

    <React.Fragment>
      <HeaderHome />
      <div>
        <section className="faq-banner2d mb-3">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="all_banner_text">
                  <h2>Frequently Asked Questions</h2>
                  <ul className="breadcrumb">
                    <li>
                      <Link to="">Home</Link>
                      <span> / </span>
                    </li>
                    <li>
                      <Link to=""> Frequently Asked Questions </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="faqScen faqScenbg mb-0 pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="bg_tabsfaq1">
                <div className="row">
                  <div className="col-md-3">
                    <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                      <button className="nav-link active mt-0" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">
                        <span className="imgs">
                        <img src={require(`../assets/images/a1_black.png`)} className="img-fluid img1" alt="" />
                        <img src={require(`../assets/images/a1_white.png`)} className="img-fluid img2" alt="" />
                        </span>
                        <span>General Questions</span>
                      </button>
                      <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                        <span className="imgs">
                        <img src={require(`../assets/images/a2_black.png`)} className="img-fluid img1" alt="" />
                        <img src={require(`../assets/images/a2_white.png`)} className="img-fluid img2" alt="" />
                        </span>
                        <span> Fees & Income </span>
                      </button>

                      <button className="nav-link mb-0" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                        <span className="imgs">
                        <img src={require(`../assets/images/a3_black.png`)} className="img-fluid img1" alt="" />
                        <img src={require(`../assets/images/a3_white.png`)} className="img-fluid img2" alt="" />
                        </span>
                        <span>Application Questions </span>
                      </button>
                      <button className="nav-link mb-0" id="v-pills-placement-tab" data-bs-toggle="pill" data-bs-target="#v-pills-placement" type="button" role="tab" aria-controls="v-pills-placement" aria-selected="false">
                        <span className="imgs">
                        <img src={require(`../assets/images/a3_black.png`)} className="img-fluid img1" alt="" />
                        <img src={require(`../assets/images/a3_white.png`)} className="img-fluid img2" alt="" />
                        </span>
                        <span>Placement Questions </span>
                      </button>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="tab-content general_questions" id="v-pills-tabContent">
                      <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                        <div className="general-s">
                          <h3>General Questions</h3>
                          <div className="">
                            <div className="accordion" id="accordionExample">
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="heading1">
                                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1"> Can I do this programme along with my job or college? </button>
                                </h2>
                                <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <p> Yes, the programme is specially designed for graduates, final year students (Bachelor's), and people who are already working. </p>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="heading2">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2"> Am I eligible to apply for a course at Skillible? </button>
                                </h2>
                                <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <p> To be eligible for a course you must have: General - Applications are open to Indian Nationals residing in India with a valid PAN card and Aadhar Card. You are required to have a laptop and a good internet connection to continue attending our coding classes. Basic knowledge of the English language is also a must. Age - The current offerings at Skillible are designed for applicants with a minimum age of 20 years. Entrance Test - You will have to appear for an Online Assessment. After this, there will be two rounds of interviews. The first will be a Technical Interview to assess your logic, reasoning and technical aptitude. The final one will be a personal interview with the management. On failing to clear the entrance process, you will have the option of re-applying for the next batch. Academic Background - To be eligible for our programme, you need to be a fresh graduate or a final year student in any STEM course of your choice. You should not have any backlogs. Work Background - You may or may not be working currently while applying at Skillible. </p>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="heading3">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3"> Does Skillible help me get a job? </button>
                                </h2>
                                <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <p> The placements team will help you prepare your resume, portfolio, build your digital profile, practice interviews, and even help you in salary negotiation. However, at the end of the day, you are the one who would apply, give the interview and get the job. </p>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="heading4">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4"> What is the medium of instruction? </button>
                                </h2>
                                <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <p> The medium of instruction will be in English. You must be able to understand the lectures that are delivered in the English language. As part of the curriculum, we will also help you prepare for your employment interviews. </p>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="heading5">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5"> The course hours don’t work for me. Can I attend in my own time? </button>
                                </h2>
                                <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <p> No. The most important parts of the Skillible curriculum happen in small, interactive groups, and require 90% attendance for all hours of the coding classes. Although we do record and archive all of our lectures, the lectures alone are not what makes Skillible a great upskilling platform. Skillible does have part-time programmes that may work better for those who have jobs or are working. </p>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="heading6">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6"> Will I receive a degree or certificate when I graduate from Skillible? </button>
                                </h2>
                                <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <p> Skillible is not a degree-granting institution, but we do issue a certificate after course completion. Thus, the core focus at Skillible is on skill development and hands-on industry experience. With that in mind, we have not tied up with any educational institutions or industry bodies for certification. </p>
                                  </div>
                                </div>
                              </div>

                              <div className="accordion-item">
                                <h2 className="accordion-header" id="heading7">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">  Is attendance mandatory?</button>
                                </h2>
                                <div id="collapse7" className="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <p> Yes, a minimum of 90% lecture attendance is required to complete these technology courses. You also need to follow the guidelines mentioned in the Skillible User Guidelines. Please go through this link to understand the User Guidelines.
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="accordion-item">
                                <h2 className="accordion-header" id="heading8">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">  What do I do if I don't have a Government Photo ID? </button>
                                </h2>
                                <div id="collapse8" className="accordion-collapse collapse" aria-labelledby="heading8" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <p>It is mandatory to provide these for registration to the course.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">  <div className="general-s">
                        <h3> Fees & Income Share Agreement (ISA) Questions </h3>
                        <div className="">
                          <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="heading1">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">  What payment methods are available to me?  </button>
                              </h2>
                              <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                  <p>Skillible offers multiple payment options for your convenience</p>
                                  <ul>
                                    <li> <b>Upfront Payment -</b> You can pay the tuition fee for the course upfront, all at once. </li>
                                    <li> <b>Sponsored Payment -</b> Your employer may sponsor the tuition fee for skill development either in full or a small percentage. Please contact your employer for the same. </li>
                                    <li> <b>Income Share Agreement (ISA) -</b> Sign an Income Share Agreement with Skillible and convert your fees into easy EMIs based on your Salary. </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="heading2">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">  What is an ISA and how does it work?  </button>
                              </h2>
                              <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                  <p>
                                    An Income Share Agreement (ISA) is an option for paying your course fee, wherein you won't be required to make any upfront payments until you start earning a minimum annual salary of 3.5 Lakhs. Once you secure employment, the ISA contract stipulates that you will pay 11% of your monthly salary (excluding GST) for 36 months or until you reach the maximum total amount of the course fees for our online Full-Time Course. This arrangement allows you to start your course without immediate financial burden and aligns your payments with your earning capacity.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="heading3">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">  Can I see a copy of the ISA?  </button>
                              </h2>
                              <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                  <p> Yes, of course. As you go through our admission process, a copy of the same will be made available to you at the end.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="heading4">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">  When do my ISA repayments start and what if I get fired or leave a job in between? </button>
                              </h2>
                              <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                  <p>
                                    The ISA comes into effect only when you secure a job with a minimum annual salary of 3.5 LPA within 1 year after completing the course. Our ISA includes a safeguard for financial difficulties. If, for any reason, you lose your job or experience a decline in income below 3.5 LPA, your payments will be automatically paused upon reporting the change. This feature ensures that you won't face financial strain during challenging times, providing added security and flexibility.</p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="heading6">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">   Do I need to submit any document post getting a job?  </button>
                              </h2>
                              <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                  <p>As per the ISA, you will be legally bound to furnish all the documents relevant to your Income such as Offer Letters, Salary Slips, Income Tax Returns, Bank Statements on a monthly basis, once employed.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                      <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                        <div className="general-s">
                          <h3>Application Questions </h3>
                          <div className="">
                            <div className="accordion" id="accordionExample">
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="heading1">
                                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1"> What Is the Application Process?  </button>
                                </h2>
                                <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <ul><li>When you apply for the course, you will receive an email for document verification <ul className="alert-text"><li>List of documents here </li><li>Resume, Photo</li></ul></li><li> Skillible has partnered with Neer Info Solutions to provide placement opportunities, but clearing the interview and securing a job is the responsibility of the student in the end. </li><li> You will have to appear for an Online Assessment, followed by a Technical Interview to assess your logic, reasoning and technical aptitude. The final round will be a personal interview with the management. </li><li> On failing to clear the entrance process, you will have the option of re-applying for the next batch. </li></ul>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="heading2">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2"> Additional Questions  </button>
                                </h2>
                                <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <p>If you have any additional questions, feel free to write to us at admissions@skillible.io or go to the contact us page.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="v-pills-placement" role="tabpanel" aria-labelledby="v-pills-placement-tab">
                        <div className="general-s">
                          <h3>Placement Questions </h3>
                          <div className="">
                            <div className="accordion" id="accordionExample">
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="heading1">
                                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1"> Does Skillible guarantee a job after the course? </button>
                                </h2>
                                <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <ul><li>No, we do not guarantee a job after the course. Skillible is an upskilling platform that offers holistic development focusing on technical skills, soft skills, and employability quotient. </li><li> Skillible has partnered with Neer Info Solutions to provide placement opportunities, but clearing the interview and securing a job is the responsibility of the student in the end. </li></ul>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="heading2">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">  When will the placements start? What will be the procedure?  </button>
                                </h2>
                                <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <p> The actual placement process begins upon the completion of your software course which is dependent on final project submission. However, the preparation for the placement process begins during the last leg of your curriculum and runs for a few weeks alongside the coursework.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="heading3">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">  Does Skillible prepare me for the interviews as well?  </button>
                                </h2>
                                <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <p> Our placement cell will work with you during the last few weeks of the programme, helping you build a portfolio, resume, online profiles and run you through multiple mock interviews. You will be given preparatory information for any opportunity or company coming in for hiring as well. Additional one-on-one guidance is provided during the placement process, as deemed necessary by the team
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  )
}



import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot from "react-dom/client"
import App from './App';
import reportWebVitals from './reportWebVitals';

// Use createRoot to render your application
const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Call reportWebVitals to measure and report various performance metrics
reportWebVitals();



import React from "react";
import Modal from 'react-bootstrap/Modal';
import TermsOfUse from "./TermsOfUse";
const TermsOfUseModal = ({show, hide, isModal }) => {

    const modalClose = () => {
        hide();
     }

    return(
        
        <Modal className="tmcModal" size="xl" show={show} onHide={modalClose} scrollable>
        <Modal.Header closeButton>
          <h3 className="modal-title tnc-use">Terms <span>of Use</span></h3>
        </Modal.Header>
        <Modal.Body>
        <div className="row">
        <TermsOfUse isModal= {isModal} />
                       
                     </div>
        </Modal.Body>
              
      </Modal>
        
    )
}

export default TermsOfUseModal;
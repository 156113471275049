import React from 'react';
import HeaderHome from './HeaderHome';

export default function Demo() {

   return (
      <React.Fragment>
         <HeaderHome />
         <div>
            <section className="conatct-section contact-formsce contact-formsce1 mt-5" id="hfuform" >
               <div className="container">
                  <div className="row">
                     <div className="col-mc-12">
                        <div className="bg_contactForm">
                           <div className="row">
                              <div className="col-md-12 pb-4 text-start hding2">
                                 <h2>Demo <span></span>
                                 </h2>
                              </div>
                              <div className="col-12">
                                 <p id="cntrepmsg"></p>
                              </div>
                           </div>
                           <form className="row needs-validation" id="free_inquiry" noValidate=""  >
                              <input type="hidden" name="country_code" id="contact_country_code" />
                              <div className="col-md-12 col-lg-6">
                                 <div className="row">
                                    <div className="col-md-12">
                                       <div className="fildsstr">
                                          <input type="text" className="form-control" name="fname" id="fname" placeholder="Full Name" required pattern="^[A-Za-zÀ-ÿ ,.'-]+$" />
                                          <div className="invalid-feedback"> Please enter full Name </div>
                                       </div>
                                    </div>
                                    <div className="col-md-12">
                                       <div className="fildsstr">
                                          <input type="tel" className="form-control" pattern="[0-9\-\(\) +]{5,22}" maxLength="10" required id="inputPhone" name="mobile" placeholder="Enter your contact no." />
                                          <div className="invalid-feedback"> Please enter phone. </div>
                                       </div>
                                    </div>
                                    <div className="col-md-12">
                                       <div className="fildsstr">
                                          <input type="text" className="form-control" required placeholder="Designation" name="company_Designation" id="designation" />
                                          <div className="invalid-feedback"> Please provide a Designation. </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <div className="col-md-12 col-lg-12">
                                 <div className="text-center">
                                    <button className="btn" type="button" id="buttonSubmitNow" name="temp" >Submit Now</button>
                                 </div>
                              </div>


                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </div>
      </React.Fragment>
   )
}
import React from "react";
import Modal from 'react-bootstrap/Modal';
import DataCollection from "./DataCollection";
const DataCollectionModal = ({show, hide, isModal }) => {

    const modalClose = () => {
        hide();
     }

    return(
        <Modal size="xl" show={show} onHide={modalClose} scrollable>
        <Modal.Header closeButton>
        <h3 className="modal-title tnc-use">Data <span> Collection</span></h3>
        </Modal.Header>
        <div className="modal-body">
        <DataCollection isModal={isModal} />
        </div>
      </Modal>
        
    )
}

export default DataCollectionModal;
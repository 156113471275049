import React from 'react';
import HeaderHome from './HeaderHome';
import { Link } from 'react-router-dom';
export default function ErrorBoundary() {

  return (

    <React.Fragment>
      <HeaderHome />
      <section className="section error_textpage">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center ml-auto mr-auto">
              <div>
                <h2>Oops!</h2>
                <div className="circles">
                  <p>
                    500<br />
                    <small>PAGE NOT FOUND</small>
                  </p>
                  <span className="circle big"></span>
                  <span className="circle med"></span>
                  <span className="circle small"></span>

                  <p className="bck-home-text">
                    The page you are searching for might have been removed or is
                    temporarily unavailable
                    <Link to="mailto:support@skillible.io"> support@skillible.io </Link>
                    for resolutions.
                  </p>
                </div>
              </div>
              <div className="btn1-s">
                <Link to="/" className="back-to-home">
                  <span className="fa fa-angle-left"></span> Back to Home</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}
import React from 'react'
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HeaderHome from './HeaderHome';
export default function Salesforce() {
  return (
    <React.Fragment>
      <HeaderHome />
<HelmetProvider>
      <Helmet>
        <meta charset="UTF-8" />
        <title>Top Salesforce Training Course in India | Skillible</title>
        <meta name="description" content="Learn Salesforce from Certified Salesforce Trainers and industry projects.
     Get job placement after completion. Enroll for the salesforce training course today!" />
        <link rel="canonical" href="http://skillible.io/" />
        <meta property="og:title" content="Top Salesforce Training Course in India | Skillible" />
        <meta property="og:description" content="Learn Salesforce from Certified Salesforce Trainers and industry projects. Get job placement after completion. Enroll for the salesforce training course today!" />
        <meta property="og:url" content="http://skillible.io/" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Skillible" />
      </Helmet>
      </HelmetProvider>
      <div>
        <section className="banner-home service-banner servs-edt">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="text-banner">
                  <h1>
                    INCREASE
                    <br />
                    <span>
                      Your Real-World Value:
                    </span>
                    Be A Salesforce Developer
                  </h1>
                  <p className="banner-para3">
                    Salesforce's commitment to secure workforce development and Skillible's allegiance to achieve diversity and inclusion has led to the development of some of the most diverse programs in the IT ecosystem.
                  </p>

                  <ul className="get-quote mb-5">
                    <li>
                      <Link to="#" className="active">
                        <button type="button">
                          Coming Soon
                        </button>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 text-center">
                <div className="img-banner servs-img1">
                  <div className="service-bannerimg">
                    <span>
                      <img src={require(`../assets/images/salesforce-banner.png`)}
                        className="img-fluid" alt="Salesforce Development Program  Program" />
                    </span>
                    <span className="img_greenleft">
                    </span>
                    <span className="img_blueright">
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="delivering-accurate sce991">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center pb-5">
                <div className="hding2 colr-spanh2">
                  <h2>Skillible's Inclusive Initiative: <span>To Secure</span> Equity & Diversity </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5 ps-0 key-issue-imgdv">
                <div className="key-issue-img">
                  <img src={require(`../assets/images/Courses.webp`)} className="img-fluid keyimg-main1" alt="Skillible spreads Equity and Diversity" />
                  <div className="transpratgdnt-keyissues">
                    <span>
                      <img src={require(`../assets/images/icon-key-issues.png`)} className="img-fluid" alt="icon key issues" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="keyissuesText">
                  <ul>
                    <li>
                      <span className="icon">
                        <i className="fas fa-check"></i>
                      </span>
                      <div> Skillible believes in spreading a gender neutrality outlook throughout the IT
                        ecosystem. We do not look at diversity just as a promotional activity but as a continuous process
                        to ensure constant change through our dynamic programs. </div>
                    </li>
                    <li>
                      <span className="icon">
                        <i className="fas fa-check"></i>
                      </span>
                      <div>Attaining equity and inculcating the right mix of technical skill sets in all
                        its learners remains an imperative task for Skillible. Now all our learners without any prohibition or
                        discrimination on any ground can get enrolled and build job-ready skills from Day 1. </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="opi-services-sec opi-services-sec2 sec5 sce991">
          <div className="container-fluid">
            <div className="row displ-lrt2">
              <div className="col-md-6 opi-services opi-services2 text-end float-end">
                <div className="opi-services-img">
                  <img src={require(`../assets/images/our-swag.webp`)} className="img-fluid opi-comm-img" alt="Skillible is Salesforce Authorized Workforce Development Partner" />
                </div>
              </div>
              <div className="col-md-6 float-start">
                <div className="opi-servicesText hding2 swag-partner">
                  <h2>
                    <span> Our Swag: </span> Skillible is Salesforce Authorized Workforce Development Partner
                  </h2>
                  <ul>
                    <li>
                      <div>Now you can take your next step towards upskilling with Skillible, which is now an authorized
                        <strong> Salesforce Development Partner.</strong> Skillible empowers individuals and businesses to create
                        and manifest in-demand digital skills with expert-led learning programs, role-based credentials,
                        and workforce development strategies designed to fuel our new talents. </div>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section subscribe_here_bg">
          <div className="subscribe_here_bg_img">
            <span>
              <img src={require(`../assets/images/subscribe_here_bg.webp`)} data-src={require(`../assets/images/subscribe_here_bg.webp`)} alt="" /> </span>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-right partner-logo-banner">
                <span>
                  <Link target="_blank" to="https://www.salesforce.com/in/campaign/career-building/?d=7010M000001z0RNQAY&nc=7013y000002RD0aAAG&eid=dy-cs-th&utm_source=dynamic&utm_campaign=demgen-apac-india-in-csg-none-&utm_content=trailhead-o1-gb-INDIA%20Career%20Programs%20Primary-awareness-a0U7y000000KTttEAG--&utm_medium=email&">
                    <img src={require(`../assets/images/subscribe_here.webp`)} data-src={require(`../assets/images/subscribe_here.webp`)} alt="Skillible on Salesforce Career Building Page " />
                  </Link></span>
              </div>

              <div className="col-md-6">
                <div className="text_subscribe_here apply-now-with-ptnre">
                  <h3> Unlock the Power of Diversity & Inclusion
                    <span> with Skillible and Salesforce Partnership!</span></h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="about-our-banking hd2">
          <div className="container">
            <div className="row aboutbg-befr">
              <div className="col-md-5">
                <div className="about-img">
                  <span>
                    <img src={require(`../assets/images/women-for-tech.png`)} className="img-fluid" alt="Women In Tech- A Program by Skillible" />
                  </span>
                </div>
              </div>
              <div className="col-md-7">
                <div className="same-steps step1">
                  <h2> Women In Tech </h2>
                  <ul>
                    <li>
                      <span className="icon">
                        <img src="" className="img-fluid" alt="" />
                      </span> Women are walking shoulder to shoulder with men in every walk of life. But they often face stiffness in their career graph when they try to move ahead of their counterparts. Technological incompetency can be a major obstacle in such a scenario.
                    </li>
                    <li>
                      <span className="icon">
                        <img src="" className="img-fluid" alt="" />
                      </span> Upskilling is the new buzzword to acquaint yourself with the newest trend and developments through various professional courses. In this highly competitive edgy world, one has to accelerate their progress by investing in reskilling and upskilling courses.
                    </li>
                    <li>
                      <span className="icon">
                        <img src="" className="img-fluid" alt="" />
                      </span> Our program <strong> "Women in Tech”  </strong>is an effort to uplift your existing career graph. This is a program that will give you an opportunity to fetch those lucrative salary hikes which you have been kept away, from in the name of technical inadequacy.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="translation-solutions">
          <div className="container">
            <div className="row displ-lrt1">
              <div className="col-md-6 float-end">
                <div className="translart-img1">
                  <span className="img">
                    <img src={require(`../assets/images/Who-Should-Enrol-man.png`)} className="img-fluid" alt="Skillible-Features" />
                  </span>
                </div>
              </div>
              <div className="col-md-6 text-start">
                <div className="same-steps translation-step1 hdsame1">
                  <h2> So How Do We Help? </h2>
                  <ul className="how-do-help">
                    <li> We provide a comprehensive and globally recognized curriculum. </li>
                    <li> We assure you of a holistic training environment.</li>

                    <li>We acquaint you with top-rated mentors/trainers to help you grow. </li>
                    <li>We give you a great interactive experience through our online live classes.</li>
                    <li>We assure seamless job switch and hiring assistance. </li>
                  </ul>

                  <ul>
                    <li className="get-started-btn">
                      <Link to="#"> Coming Soon</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="interpretation-solutions">
          <div className="bg-circlsce1"></div>
          <div className="container">
            <div className="row width90pst">
              <div className="col-md-6">
                <div className="inter-solimgs">
                  <span className="img1">
                    <img src={require(`../assets/images/salesforce-admin.png`)} className="img-fluid" alt="Eligible Criteria" />
                  </span>
                  <span className="img2">
                    <img src={require(`../assets/images/salesforce-dev-man.png`)} className="img-fluid" alt="Skillible- Eligible Criteria" />
                  </span>
                </div>
              </div>
              <div className="col-md-6 text-left">
                <div className="same-steps inter-soluc1 hdsame1">
                  <h2> Am I Eligible? </h2>
                  <div className="services-expertise">
                    <p className="salesforce-admin"> Salesforce Development Program  </p>
                    <ul className="salesforce-admin-skill">
                      <li>This program is exclusively available for working women.</li>
                      <li>Basic knowledge of programming skills is required. </li>
                      <li> Minimum 2 years of working experience is required </li>
                      <li>This program is exclusively available for working women. </li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="translation-solutions">
          <div className="container">
            <div className="row displ-lrt1">
              <div className="col-md-6 float-end">
                <div className="translart-img1">
                  <span className="img">
                    <img src={require(`../assets/images/Who-Should-Enrol.png`)} className="img-fluid" alt="Skillible Program- Career Vriddhi " />
                  </span>
                  <div className="img-btnm1">
                    <ul>
                      <li> Career <span></span></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 text-start">
                <div className="same-steps translation-step1 hdsame1">
                  <h2> CAREER VRIDDHI</h2>
                  <p>As women, we often compromise with our career and opt for inevitable career breaks.
                    Women on a career break often come across situations where they find their area of expertise has
                    changed a lot while they were away from work. Without <strong> upskilling/reskilling </strong> they can never think of moving back into the game. </p>
                  <p><strong> It's Time to Reclaim Your Career! </strong> </p>
                  <p className="career-virdh-help"> Career Vriddhi is a unique program that assists women in returning to the workforce after a long career break. If you've been
                    out of the workforce for more than two years and believe now is the time to return, this is the program for you. </p>

                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="interpretation-solutions">
          <div className="bg-circlsce1"></div>
          <div className="container">
            <div className="row width90pst">
              <div className="col-md-6">
                <div className="inter-solimgs">
                  <span className="img1">
                    <img src={require(`../assets/images/salesforce-admin.png`)} className="img-fluid" alt="Salesforce Admin" />
                  </span>
                  <span className="img2">
                    <img src={require(`../assets/images/salesforce-dev.png`)} className="img-fluid" alt="Skillible - Career Vriddhi - Eligibility criteria" />
                  </span>
                </div>
              </div>
              <div className="col-md-6 text-left">
                <div className="same-steps inter-soluc1 hdsame1">
                  <h2> Am I Eligible? </h2>
                  <div className="services-expertise">
                    <p className="salesforce-admin"> Salesforce Development Program </p>
                    <ul className="salesforce-admin-skill">
                      <li>This program is exclusively available to women on a career break. </li>
                      <li> No prior knowledge of the IT field is required. </li>
                      <li>Career break should not be more than two years </li>
                      <li> Basic knowledge of programming skills is required. </li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="price-bg-area">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="hding2">
                  <h2> Select a plan based on your needs </h2>
                  <p className="heading-plans"> Skillible Encourages Women To Build A Career In Tech </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="tabinfirst text-center">
                  <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="pills-tab-11" data-bs-toggle="pill" data-bs-target="#pills-tab11" type="button" role="tab" aria-controls="pills-tab11" aria-selected="true"> Women in Tech <span className="women-quote"> " Embrace Constant Change " </span>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="pills-tab-22" data-bs-toggle="pill" data-bs-target="#pills-tab22" type="button" role="tab" aria-controls="pills-tab22" aria-selected="false"> Career Vriddhi <span className="women-quote"> " Be The Change " </span>
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="tab-content" id="pills-tabContent">
                  <div className="procced-flow tab-pane fade show active" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab-11">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="nav nav-pills bdre-prosecclft me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">

                          <button className="nav-link active" id="v-pills-2-tab" data-bs-toggle="pill" data-bs-target="#v-pills-2" type="button" role="tab" aria-controls="v-pills-2" aria-selected="false">
                            <span className="proceesBtn">
                              <span className="proceesIcon">
                                <img src={require(`../assets/images/development.png`)} className="img-fluid" alt="project icon" />
                              </span>Salesforce Development Program </span>
                          </button>

                          <span className="ads-nh">
                            <img src={require(`../assets/images/pay-after-placement.webp`)} className="img-fluid" alt="Skillible- Pay After Placement " />
                          </span>
                          <span className="ads-nh">
                            <img src={require(`../assets/images/flat-off.png`)} className="img-fluid" alt="Skillible- Financial Partner- Kuhoo" />
                          </span>
                          <span className="ads-nh">
                            <img src={require(`../assets/images/upcomming-batch.png`)} className="img-fluid" alt="Skillible- last registration date" />
                          </span>
                          <span className="ads-nh">
                            <img src={require(`../assets/images/million-icon.png`)} className="img-fluid" alt="Salesforce ecosystem - skillible" />
                          </span>
                          <span className="ads-nh">
                            <img src={require(`../assets/images/one-time-payment.png`)} className="img-fluid" alt="skillible- Easy Payments option- One time payment" />
                          </span>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="tab-content price_discript" id="v-pills-tabContent">
                          <div className="tab-pane fade show active" id="v-pills-1" role="tabpanel" aria-labelledby="v-pills-1-tab">
                            <div className="col-md-12">
                              <div className="ourclient ourclient1 online-shedule">
                                <span>
                                  <img src={require(`../assets/images/time-9741.png`)} className="img-fluid" alt="Skillible- 40 hours" />
                                </span>
                                <p> 40 hours </p>
                              </div>
                              <div className="ourclient ourclient2 online-shedule">
                                <span>
                                  <img src={require(`../assets/images/videos-8803.png`)} className="img-fluid" alt="Skillible- MIN. 2 YEARS OF EXP." />
                                </span>
                                <p> MIN. 2 YEARS OF EXP. </p>
                              </div>
                              <div className="ourclient ourclient3 online-shedule">
                                <span>
                                  <img src={require(`../assets/images/batchs.png`)} className="img-fluid" alt="Skillible- Agility Training Batches" />
                                </span>
                                <p> Agility Training Batches </p>
                              </div>
                              <div className="ourclient ourclient4 online-shedule">
                                <span>
                                  <img src={require(`../assets/images/morning-batch-1.png`)} className="img-fluid" alt="Skillible- Batch Timing" />
                                </span>
                                <p> Morning - 9 to 1 pm   Afternoon - 2 to 6 pm </p>
                              </div>

                            </div>
                            <div className="hding2 bonushead">
                              <h2> Women In Tech </h2>
                              <ul className="bonus">
                                <li className="women-tc">
                                  <span>
                                    <img src={require(`../assets/images/women.png`)} className="img-fluid" alt="Skillible- Women In Tech - A Program for working professionals" />
                                  </span>
                                  <h5> A skillfully planned program to scale up your existing career graph to the next level. </h5>
                                </li>
                              </ul>
                            </div>
                            <div className="hding2 bonushead text_q_nd_ans">
                              <h2> Curriculum Categorization</h2>
                              <ul className="bonus">
                                <li>
                                  <img src={require(`../assets/images/check-all.png`)} className="img-fluid" alt="Info Icon" />
                                  <strong> PART-1: Salesforce Development Program : PREREQUISITE </strong>
                                  <ul className="bonus2">
                                    <li className="gray-color-d">
                                      <img src={require(`../assets/images/arrow-right-gray.png`)} className="img-fluid" alt="Info Icon" /> Java
                                    </li>
                                    <li className="gray-color-d">
                                      <img src={require(`../assets/images/arrow-right-gray.png`)} className="img-fluid" alt="Info Icon" /> Database (MySQL)
                                    </li>
                                    <li className="gray-color-d">
                                      <img src={require(`../assets/images/arrow-right-gray.png`)} className="img-fluid" alt="Info Icon" /> HTML, CSS
                                    </li>
                                    <li className="gray-color-d">
                                      <img src={require(`../assets/images/arrow-right-gray.png`)} className="img-fluid" alt="Info Icon" /> JavaScript
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <img src={require(`../assets/images/check-all.png`)} className="img-fluid" alt="Info Icon" />
                                  <strong> PART-2: SALESFORCE DEVELOPMENT PROGRAM </strong>
                                  <ul className="bonus2">
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Salesforce Platform Basics
                                    </li>
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Picklist Administration
                                    </li>
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Screen Flows
                                    </li>
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Data Management
                                    </li>
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Event Monitoring
                                    </li>
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Platform API Basics
                                    </li>
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" />  SOQL For Admins
                                    </li>
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" />  Live Projects & Super Badges
                                    </li>

                                  </ul>
                                </li>

                              </ul>
                            </div>
                            <div className="hding2 bonushead text_q_nd_ans">
                              <h2> Extra Special Features </h2>
                              <ul className="bonus extra-special">
                                <li>
                                  <img src={require(`../assets/images/industry-mentor-sessions-9654.png`)} className="img-fluid" alt="Info Icon" /> Live classes by Top Rated Faculties
                                </li>
                                <li>
                                  <img src={require(`../assets/images/workshops-10260.png`)} className="img-fluid" alt="Info Icon" /> Special Interview Skill Workshop Sessions to get closer to your dream job.
                                </li>
                                <li>
                                  <img src={require(`../assets/images/extention-10759.png`)} className="img-fluid" alt="Info Icon" /> Exclusive Sponsorship Programs to remove all barriers in upskilling your career.
                                </li>
                              </ul>
                            </div>
                            <div className="formUsp pay-under-dic">
                              <h2 className="pay-under-isa">  Pay Under An ISA -  <span className="price-hsg"> 99,000₹/- </span>   <span className="payment-gst-h">  (excl. GST) </span> </h2>
                              <ul className="fees-icon-isa">
                                <li>
                                  <img src={require(`../assets/images/no-fees-o.png`)} className="img-fluid" alt="Skillible- Zero Enrolment Fees" />
                                  <p>Enrol @ Zero Enrolment Fees  </p>
                                </li>
                                <li>
                                  <img src={require(`../assets/images/unlock.png`)} className="img-fluid" alt="Skillible-  Zero Upfront Fees" />
                                  <p>Unleash Your Potential With Zero Upfront Fees!</p>
                                </li>
                                <li>
                                  <img src={require(`../assets/images/emi-option.png`)} className="img-fluid" alt="Skillible- Easy EMI Options" />
                                  <p>Flexible EMI Options: 6 to 36 Months! </p>
                                </li>
                              </ul>
                            </div>
                            <div className="hding2 bonushead text_q_nd_ans">
                              <ul className="upcomming-batches-jan mt-2">
                                <li className="batch-w" role="presentation">
                                  <span>
                                    <img src={require(`../assets/images/time-9741.png`)} className="img-fluid" alt="clients" /> Earliest Batch : </span>
                                  <span className="women-batches">Coming Soon </span>
                                </li>
                               
                              </ul>
                            </div>
                          </div>
                          <div className="tab-pane fade" id="v-pills-2" role="tabpanel" aria-labelledby="v-pills-2-tab">
                            <div className="col-md-12">
                              <div className="ourclient ourclient1 online-shedule">
                                <span>
                                  <img src={require(`../assets/images/time-9741.png`)} className="img-fluid" alt="clients" />
                                </span>
                                <p> 40 hours </p>
                              </div>
                              <div className="ourclient ourclient2 online-shedule">
                                <span>
                                  <img src={require(`../assets/images/videos-8803.png`)} className="img-fluid" alt="project" />
                                </span>
                                <p> MIN. 2 YEARS OF EXP. </p>
                              </div>
                              <div className="ourclient ourclient3 online-shedule">
                                <span>
                                  <img src={require(`../assets/images/batchs.png`)} className="img-fluid" alt="language support" />
                                </span>
                                <p> Agility Training Batches </p>
                              </div>
                              <div className="ourclient ourclient4 online-shedule">
                                <span>
                                  <img src={require(`../assets/images/morning-batch-1.png`)} className="img-fluid" alt="Word Translation" />
                                </span>
                                <p> Weekend classes </p>
                              </div>
                            </div>
                            <div className="hding2 bonushead">
                              <h2> Women In Tech </h2>
                              <ul className="bonus">
                                <li className="women-tc">
                                  <span>
                                    <img src={require(`../assets/images/women.png`)} className="img-fluid" alt="Info Icon" />
                                  </span>
                                  <h5> A skillfully planned program to scale up your existing career graph to the next level. </h5>
                                </li>
                              </ul>
                            </div>
                            <div className="hding2 bonushead text_q_nd_ans">
                              <h2> Curriculum Categorization</h2>
                              <ul className="bonus">
                                <li>
                                  <img src={require(`../assets/images/check-all.png`)} className="img-fluid" alt="Info Icon" />
                                  <strong> PART-1: Salesforce Development Program : PREREQUISITE </strong>
                                  <ul className="bonus2">
                                    <li className="">
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Java
                                    </li>
                                    <li className="">
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Database (MySQL)
                                    </li>
                                    <li className="">
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> HTML, CSS
                                    </li>
                                    <li className="">
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> JavaScript
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <img src={require(`../assets/images/check-all.png`)} className="img-fluid" alt="Info Icon" />
                                  <strong> PART-2: Salesforce Development Program  </strong>
                                  <ul className="bonus2">
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Salesforce Platform Basics
                                    </li>
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Picklist Administration
                                    </li>
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Screen Flows
                                    </li>
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Data Management
                                    </li>
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Event Monitoring
                                    </li>
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Platform API Basics
                                    </li>
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" />  SOQL For Admins
                                    </li>
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" />  Live Projects & Super Badges
                                    </li>

                                  </ul>
                                </li>

                              </ul>
                            </div>
                            <div className="hding2 bonushead text_q_nd_ans">
                              <h2> Extra Special Features </h2>
                              <ul className="bonus extra-special">
                                <li>
                                  <img src={require(`../assets/images/industry-mentor-sessions-9654.png`)} className="img-fluid" alt="Info Icon" /> Live classes by Top Rated Faculties
                                </li>
                                <li>
                                  <img src={require(`../assets/images/workshops-10260.png`)} className="img-fluid" alt="Info Icon" /> Special Interview Skill Workshop Sessions to get closer to your dream job.
                                </li>
                                <li>
                                  <img src={require(`../assets/images/extention-10759.png`)} className="img-fluid" alt="Info Icon" /> Exclusive Sponsorship Programs to remove all barriers in upskilling your career.
                                </li>
                              </ul>
                            </div>
                            <div className="hding2 bonushead text_q_nd_ans">
                              <h2> PAY UNDER AN ISA </h2>
                              <ul className="bonus extra-special">
                                <li>
                                  <strong> 75,000₹/- (excl. GST) </strong>
                                </li>
                                <li>
                                  <img src={require(`../assets/images/extention-10759.png`)} className="img-fluid" alt="Info Icon" /> Simply pay us 12% of your CTC for the first year if you get a 20% raise.
                                </li>
                                <li>
                                  <img src={require(`../assets/images/industry-mentor-sessions-9654.png`)} className="img-fluid" alt="Info Icon" /> 100% scholarship based on Pre & Post Assessment.
                                </li>
                                <li>
                                  <img src={require(`../assets/images/workshops-10260.png`)} className="img-fluid" alt="Info Icon" /> Pay after Placement ( PAP)
                                </li>
                                <li>
                                  <img src={require(`../assets/images/mock-test-series-9652.png`)} className="img-fluid" alt="Info Icon" /> Flat 20% off on one-time settlement.
                                </li>
                              </ul>
                              <ul className="upcomming-batches-jan">
                                <li className="batch-w" role="presentation">
                                  <span>
                                    <img src={require(`../assets/images/time-9741.png`)} className="img-fluid" alt="clients" /> Earliest Batch : </span>
                                  <span className="women-batches"> 5 Jan, 2023 </span>
                                </li>
                                <li className="batch-w" role="presentation">
                                  <span>
                                    <img src={require(`../assets/images/time-9741.png`)} className="img-fluid" alt="clients" /> Proposed Batch : </span>
                                  <span className="women-batches"> March, 2023 </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="procced-flow tab-pane fade" id="pills-tab22" role="tabpanel" aria-labelledby="pills-tab-22">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="nav nav-pills bdre-prosecclft me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">

                          <button className="nav-link active" id="v-pills-2-tab" data-bs-toggle="pill" data-bs-target="#v-pills-24" type="button" role="tab" aria-controls="v-pills-24" aria-selected="false">
                            <span className="proceesBtn">
                              <span className="proceesIcon">
                                <img src={require(`../assets/images/development.png`)} className="img-fluid" alt="project icon" />
                              </span>Salesforce Development Program </span>
                          </button>
                          <span className="ads-nh">
                            <img src={require(`../assets/images/women-break.webp`)} className="img-fluid" alt="endar icon" />
                          </span>
                          <span className="ads-nh">
                            <img src={require(`../assets/images/flat-off.png`)} className="img-fluid" alt="endar icon" />
                          </span>
                          <span className="ads-nh">
                            <img src={require(`../assets/images/upcomming-batch.png`)} className="img-fluid" alt="endar icon" />
                          </span>
                          <span className="ads-nh">
                            <img src={require(`../assets/images/million-icon.png`)} className="img-fluid" alt="endar icon" />
                          </span>
                          <span className="ads-nh">
                            <img src={require(`../assets/images/one-time-payment.png`)} className="img-fluid" alt="endar icon" />
                          </span>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="tab-content price_discript" id="v-pills-tabContent">
                          <div className="tab-pane fade show active" id="v-pills-14" role="tabpanel" aria-labelledby="v-pills-1-tab">
                            <div className="col-md-12">
                              <div className="ourclient ourclient1 online-shedule">
                                <span>
                                  <img src={require(`../assets/images/time-9741.png`)} className="img-fluid" alt="clients" />
                                </span>
                                <p> 12 weeks </p>
                              </div>
                              <div className="ourclient ourclient2 online-shedule">
                                <span>
                                  <img src={require(`../assets/images/videos-8803.png`)} className="img-fluid" alt="project" />
                                </span>
                                <p> MIN. 2 YEARS OF EXP. </p>
                              </div>
                              <div className="ourclient ourclient3 online-shedule">
                                <span>
                                  <img src={require(`../assets/images/batchs.png`)} className="img-fluid" alt="language support" />
                                </span>
                                <p> Agility Training Batches </p>
                              </div>
                              <div className="ourclient ourclient4 online-shedule">
                                <span>
                                  <img src={require(`../assets/images/morning-batch-1.png`)} className="img-fluid" alt="Word Translation" />
                                </span>
                                <p> Morning - 9 to 1 pm    </p>
                              </div>
                            </div>
                            <div className="hding2 bonushead">
                              <h2> Career Vriddhi </h2>
                              <ul className="bonus">
                                <li className="women-tc">
                                  <span>
                                    <img src={require(`../assets/images/women.png`)} className="img-fluid" alt="Info Icon" />
                                  </span>
                                  <h5> An exclusive program to reconstruct your halted career graph. </h5>
                                </li>
                              </ul>
                            </div>
                            <div className="hding2 bonushead text_q_nd_ans">
                              <h2> Curriculum Categorization</h2>
                              <ul className="bonus">
                                <li>
                                  <img src={require(`../assets/images/check-all.png`)} className="img-fluid" alt="Info Icon" />
                                  <strong> PART-1: Salesforce Development Program : PREREQUISITE </strong>
                                  <ul className="bonus2">
                                    <li className="">
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Java
                                    </li>
                                    <li className="">
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Database (MySQL)
                                    </li>
                                    <li className="">
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> HTML, CSS
                                    </li>
                                    <li className="">
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> JavaScript
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <img src={require(`../assets/images/check-all.png`)} className="img-fluid" alt="Info Icon" />
                                  <strong> PART-2: Salesforce Development Program  </strong>
                                  <ul className="bonus2">
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Salesforce Platform Basics
                                    </li>
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Picklist Administration
                                    </li>
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Screen Flows
                                    </li>
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Data Management
                                    </li>
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Event Monitoring
                                    </li>
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Platform API Basics
                                    </li>
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" />  SOQL For Admins
                                    </li>
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" />  Live Projects & Super Badges
                                    </li>

                                  </ul>
                                </li>

                              </ul>
                            </div>
                            <div className="hding2 bonushead text_q_nd_ans">
                              <h2> Extra Special Features </h2>
                              <ul className="bonus extra-special">
                                <li>
                                  <img src={require(`../assets/images/industry-mentor-sessions-9654.png`)} className="img-fluid" alt="Info Icon" /> Live classes by Top Rated Faculties
                                </li>
                                <li>
                                  <img src={require(`../assets/images/workshops-10260.png`)} className="img-fluid" alt="Info Icon" /> Special Interview Skill Workshop Sessions to get closer to your dream job.
                                </li>
                                <li>
                                  <img src={require(`../assets/images/extention-10759.png`)} className="img-fluid" alt="Info Icon" /> Exclusive Sponsorship Programs to remove all barriers in upskilling your career.
                                </li>
                              </ul>
                            </div>
                            <div className="formUsp pay-under-dic">
                              <h2 className="pay-under-isa">  Pay Under An ISA -  <span className="price-hsg"> 99,000₹/- </span>   <span className="payment-gst-h">  (excl. GST) </span> </h2>
                              <ul className="fees-icon-isa">
                                <li>
                                  <img src={require(`../assets/images/no-fees-o.png`)} className="img-fluid" alt="" />
                                  <p>Enrol @ Zero Enrolment Fees  </p>
                                </li>
                                <li>
                                  <img src={require(`../assets/images/unlock.png`)} className="img-fluid" alt="" />
                                  <p>Unleash Your Potential With Zero Upfront Fees!</p>
                                </li>
                                <li>
                                  <img src={require(`../assets/images/emi-option.png`)} className="img-fluid" alt="" />
                                  <p>Flexible EMI Options: 6 to 36 Months! </p>
                                </li>
                              </ul>
                            </div>
                            <div className="hding2 bonushead text_q_nd_ans">
                              <ul className="upcomming-batches-jan mt-2">
                                <li className="batch-w" role="presentation">
                                  <span>
                                    <img src={require(`../assets/images/time-9741.png`)} className="img-fluid" alt="clients" /> Earliest Batch : </span>
                                  <span className="women-batches">Coming Soon</span>
                                </li>
                                {/* <li className="batch-w get-started-btn" role="presentation">
                                    <Link to="#" data-bs-toggle="modal" data-bs-target="#apply-form"> APPLY NOW </Link>
                                </li> */}
                              </ul>
                            </div>

                          </div>
                          <div className="tab-pane fade" id="v-pills-24" role="tabpanel" aria-labelledby="v-pills-2-tab">
                            <div className="col-md-12">
                              <div className="ourclient ourclient1 online-shedule">
                                <span>
                                  <img src={require(`../assets/images/time-9741.png`)} className="img-fluid" alt="clients" />
                                </span>
                                <p> 12 weeks </p>
                              </div>
                              <div className="ourclient ourclient2 online-shedule">
                                <span>
                                  <img src={require(`../assets/images/videos-8803.png`)} className="img-fluid" alt="project" />
                                </span>
                                <p> MIN. 2 YEARS OF EXP. </p>
                              </div>
                              <div className="ourclient ourclient3 online-shedule">
                                <span>
                                  <img src={require(`../assets/images/batchs.png`)} className="img-fluid" alt="language support" />
                                </span>
                                <p> Agility Training Batches </p>
                              </div>
                              <div className="ourclient ourclient4 online-shedule">
                                <span>
                                  <img src={require(`../assets/images/morning-batch-1.png`)} className="img-fluid" alt="Word Translation" />
                                </span>
                                <p> Morning - 9 to 1 pm   Afternoon - 2 to 6 pm </p>
                              </div>
                            </div>
                            <div className="hding2 bonushead">
                              <h2> Career Vriddhi </h2>
                              <ul className="bonus">
                                <li className="women-tc">
                                  <span>
                                    <img src={require(`../assets/images/women.png`)} className="img-fluid" alt="Info Icon" />
                                  </span>
                                  <h5> An exclusive program to reconstruct your halted career graph. </h5>
                                </li>
                              </ul>
                            </div>
                            <div className="hding2 bonushead text_q_nd_ans">
                              <h2> Modules Covered </h2>
                              <ul className="bonus">
                                <li>
                                  <img src={require(`../assets/images/check-all.png`)} className="img-fluid" alt="Info Icon" />
                                  <strong> PROGRAMMING BASIC </strong>
                                  <ul className="bonus2">
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Frontend: HTML, CSS, Javascript
                                    </li>
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Java Basic: Java Syntax, Java Class and Core Java Concepts
                                    </li>
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> RDBMS: Concept, MySQL
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <img src={require(`../assets/images/check-all.png`)} className="img-fluid" alt="Info Icon" />
                                  <strong> SALESFORCE ADMIN </strong>
                                  <ul className="bonus2">
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Salesforce Fundamentals
                                    </li>
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Salesforce Standard Application & Object, Custom App, Security & Data management
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <img src={require(`../assets/images/check-all.png`)} className="img-fluid" alt="Info Icon" />
                                  <strong>Salesforce Development Program </strong>
                                  <ul className="bonus2">
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Data management
                                    </li>
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> VisualForce
                                    </li>
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Apex & Trigger, SOQL & SOSL, LWC, Integration
                                    </li>
                                    <li>
                                      <img src={require(`../assets/images/arrow-right.png`)} className="img-fluid" alt="Info Icon" /> Lightning App Builder
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                            <div className="hding2 bonushead text_q_nd_ans">
                              <h2> Extra Special Features </h2>
                              <ul className="bonus extra-special">
                                <li>
                                  <img src={require(`../assets/images/industry-mentor-sessions-9654.png`)} className="img-fluid" alt="Info Icon" /> Live classes by Top Rated Faculties
                                </li>
                                <li>
                                  <img src={require(`../assets/images/workshops-10260.png`)} className="img-fluid" alt="Info Icon" /> Special Interview Skill Workshop Sessions to get closer to your dream job.
                                </li>
                                <li>
                                  <img src={require(`../assets/images/extention-10759.png`)} className="img-fluid" alt="Info Icon" /> Exclusive Sponsorship Programs to remove all barriers in upskilling your career.
                                </li>
                              </ul>
                            </div>
                            <div className="hding2 bonushead text_q_nd_ans">
                              <h2> PAY UNDER AN ISA </h2>
                              <ul className="bonus extra-special">
                                <li>
                                  <strong> ₹1,50,000/- (excl. GST) </strong>
                                </li>
                                <li>
                                  <img src={require(`../assets/images/industry-mentor-sessions-9654.png`)} className="img-fluid" alt="Info Icon" /> 100% scholarship based on Pre & Post Assessment.
                                </li>
                                <li>
                                  <img src={require(`../assets/images/workshops-10260.png`)} className="img-fluid" alt="Info Icon" /> Pay after Placement ( PAP)
                                </li>
                                <li>
                                  <img src={require(`../assets/images/extention-10759.png`)} className="img-fluid" alt="Info Icon" /> Pay easy EMIs for two years @12% of your CTC or aforesaid amount whichever is less.
                                </li>
                                <li>
                                  <img src={require(`../assets/images/mock-test-series-9652.png`)} className="img-fluid" alt="Info Icon" /> Flat 20% off on one-time settlement.
                                </li>
                              </ul>
                              <ul className="upcomming-batches-jan">
                                <li className="batch-w" role="presentation">
                                  <span>
                                    <img src={require(`../assets/images/time-9741.png`)} className="img-fluid" alt="clients" /> Earliest Batch : </span>
                                  <span className="women-batches"> 23 Feb, 2023 </span>
                                </li>
                                <li className="batch-w" role="presentation">
                                  <span>
                                    <img src={require(`../assets/images/time-9741.png`)} className="img-fluid" alt="clients" /> Proposed Batch : </span>
                                  <span className="women-batches"> March, 2023 </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>



        <section className="section course-objective">
          <div className="container course">
            <div className="piece-content2">
              <div className="clearfix">
                <div className="heading-text">
                  <h2> Course Objective </h2>
                </div>
              </div>
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> Is Salesforce Development Program a good choice for my career? </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body"> Yes,Salesforce Development Program is a good choice for a career. Salesforce has high powerful areas with the best career opportunities in future. Salesforce is the top cloud-based CRM on the market. Salesforce has positioned itself well to take advantage of the future growth of the industry. There is huge demand for salesforce skills talent, its time for you to get into action in aSalesforce Development Program field. Salesforce has changed the way a business treats and maintains its customer base. </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> What is the scope of Salesforce Developer? </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body"> Salesforce implementation skill is in demand for adoption consulting services in the market. Working for Product based companies is a different ballgame. If you have interest in touching the core of software products and want to have your hands on the creation of the product itself rather than into consulting , then switching is desired. </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> What are some Salesforce admin jobs for freshers? </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body"> Mostly freshers spend their time in learning the technology in their early days. Jobs like Salesforce Admins, Developers, Marketing Automation Expert, Database Analysts for reporting, BI Analysts, Project Managers, Business Analysts, Consultants, Architects and so on are top and hot in the market and will remain up in the market. Hike in Salesforce in future is defined due to its innovation concept. </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="how-t-works">
          <div className="bg-circlsce1"></div>
          <div className="bg-circlsce2"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="infrotin-projt text-center mt-5">
                  <h2> User Journey </h2>
                  <ul>

                    <li>
                      <span>
                        <img src={require(`../assets/images/candidate-reach.png`)} className="img-fluid" alt="Skillible- Career Support" />
                      </span>
                      <div className=""><strong>1. </strong> Candidates reach us via our website, email, or phone </div>
                    </li>

                    <li>
                      <div className="infor-outline">
                        <img src={require(`../assets/images/top-arrow.png`)} className="img-fluid" alt="Skillible- top-arrow" />
                      </div>
                      <span>
                        <img src={require(`../assets/images/submit-application.png`)} className="img-fluid" alt="Skillible- Submit Application" />
                      </span>
                      <div className=""><strong>2. </strong> Application form submitted </div>
                    </li>

                    <li>
                      <div className="infor-outline2">
                        <img src={require(`../assets/images/botton-arrow.png`)} className="img-fluid" alt="Skillible- botton-arrow" />
                      </div>
                      <span>
                        <img src={require(`../assets/images/candidate-eligible.png`)} className="img-fluid" alt="Skillible- Candidate Eligibility" />
                      </span>
                      <div className=""><strong>3. </strong> Candidate Eligibility </div>
                    </li>
                    <li>
                      <div className="infor-outline">
                        <img src={require(`../assets/images/top-arrow.png`)} className="img-fluid" alt="Skillible- top-arrow " />
                      </div>
                      <span>
                        <img src={require(`../assets/images/technical-assessment.png`)} className="img-fluid" alt="Skillible-  Technical Assessment" />
                      </span>
                      <div className=""><strong>4. </strong> Technical Assessment  </div>
                    </li>
                    <li>
                      <div className="infor-outline2">
                        <img src={require(`../assets/images/botton-arrow.png`)} className="img-fluid" alt="Skillible-botton-arrow" />
                      </div>
                      <span>
                        <img src={require(`../assets/images/virtual-interview.png`)} className="img-fluid" alt="Skillible- Virtual Interview " />
                      </span>
                      <div className=""><strong>5. </strong>  Virtual Interview </div>
                    </li>
                  </ul>


                  <ul>
                    <li>
                      <span>
                        <img src={require(`../assets/images/signin.png`)} className="img-fluid" alt="Skillible- Sign an ISA " />
                      </span>
                      <div className=""> <strong>6. </strong> Sign an ISA and register for the Course/Program

                      </div>
                    </li>

                    <li>
                      <div className="infor-outline">
                        <img src={require(`../assets/images/top-arrow.png`)} className="img-fluid" alt="Skillible- top-arrow " />
                      </div>
                      <span>
                        <img src={require(`../assets/images/techinal-traning.png`)} className="img-fluid" alt="Skillible- Technical Training " />
                      </span>
                      <div className=""><strong>7. </strong> Technical Training</div>
                    </li>

                    <li>
                      <div className="infor-outline2">
                        <img src={require(`../assets/images/botton-arrow.png`)} className="img-fluid" alt="Skillible- botton-arrow" />
                      </div>
                      <span>
                        <img src={require(`../assets/images/assignment-assement.png`)} className="img-fluid" alt="Skillible- Assignment Assessment" />
                      </span>
                      <div className=""><strong>8. </strong> Assignment Assessment </div>
                    </li>

                    <li>
                      <div className="infor-outline">
                        <img src={require(`../assets/images/top-arrow.png`)} className="img-fluid" alt="Skillible- top-arrow" />
                      </div>
                      <span>
                        <img src={require(`../assets/images/mentorship1.png`)} className="img-fluid" alt="Skillible- Mentorship" />
                      </span>
                      <div className=""><strong>9. </strong> Mentorship </div>
                    </li>
                    <li>
                      <div className="infor-outline2">
                        <img src={require(`../assets/images/botton-arrow.png`)} className="img-fluid" alt="Skillible- botton-arrow" />
                      </div>
                      <span>
                        <img src={require(`../assets/images/resume.png`)} className="img-fluid" alt="Skillible- Draft Your Resume" />
                      </span>
                      <div className=""><strong>10. </strong> Resume Preparation </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section course-objective mb-5
        ">
          <div className="container Curriculum">
            <div className="piece-content2">
              <div className="clearfix">
                <div className="heading-text">
                  <h2> Course Curriculum </h2>
                </div>
              </div>
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> Salesforce Development Program : Prerequisite </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">


                      <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                        <div className="general-s">

                          <div className="">

                            <div className="accordion" id="accordionExample">
                              <div className="accordion-item">
                                <h2 className="accordion-header corse-circu" id="heading1">
                                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1"> Java </button>
                                </h2>
                                <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <ul className="cources-cal-cub">
                                      <li>Java Introduction, OOPs Concept </li>
                                      <li>Basic Java: Class, method, this, super, variable, access modifier </li>
                                      <li>scope, final static, operators</li>
                                      <li>Control Statement: Loops and Conditions, Jump statements.</li>
                                      <li>Array Structure, Collections, Generics , Lambda Expression</li>                                      <li>File IO: files, Byte Stream etc</li>
                                      <li>Calendar, Date time</li>
                                      <li>Error Handling, try/catch, logging</li>
                                      <li>Assignment and Q&A </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>

                              <div className="accordion-item">
                                <h2 className="accordion-header corse-circu" id="heading2">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">Database (MySQL)</button>
                                </h2>
                                <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <ul className="cources-cal-cub">
                                      <li>Characteristics of DBMS</li>
                                      <li>Data Models & Relational DBMS</li>
                                      <li>The SQL Language: Standard SQL Statement Groups, Data Query Language, The SELECT statement, The WHERE clause, Comparison, Mathematical, and Logical operators, The ORDER BY clause, Tips and Tricks in SELECT Statements</li>
                                      <li>Assignment and Q&A</li>

                                    </ul>
                                  </div>
                                </div>
                              </div>

                              <div className="accordion-item">
                                <h2 className="accordion-header corse-circu" id="heading3">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3"> HTML CSS</button>
                                </h2>
                                <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <ul className="cources-cal-cub">
                                      <li>Web Basics: HTML tags, Page Structure, HTML Elements, Text Formatting, HTML Tables, User input, and Output. </li>
                                      <li>Intro to CSS, SCSS, colours, background, borders, Text decoration, Images, Combinators, Grouping, Text decoration, Images, Padding, Margin. Navbar, etc</li>

                                    </ul>

                                  </div>
                                </div>
                              </div>

                              <div className="accordion-item">
                                <h2 className="accordion-header corse-circu" id="heading4">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4"> JavaScript</button>
                                </h2>
                                <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <ul className="cources-cal-cub">
                                      <li>Understanding Java Script, Embedding it with HTML,</li>
                                      <li>Basic things: String, Numbers, Variable, operators: Logical, comparison, loops, array, etc</li>
                                      <li>Objects, function, bind the objects, grabbing data from the screen, validation.</li>
                                      <li>Context, Scope, and Closures</li>
                                      <li>Asynchronous JavaScript, Ajax, Json, Arrow function</li>
                                      <li>Applying Actions in Workflows</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Salesforce Development Program  </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">

                      <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                        <div className="general-s">

                          <div className="">

                            <div className="accordion" id="accordionExample">
                              <div className="accordion-item">
                                <h2 className="accordion-header corse-circu" id="heading01">
                                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse01" aria-expanded="true" aria-controls="collapse01"> Trailhead & Trailblazer Community​ </button>
                                </h2>
                                <div id="collapse01" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <ul className="cources-cal-cub">
                                      <li>Get Started with Trailhead and Trailblazer Community</li>
                                      <li>Find Your Way Around</li>
                                      <li>Build Your Network with Trailblazer Community</li>
                                      <li>Troubleshoot and Solve Problems Together</li>

                                    </ul>
                                  </div>
                                </div>
                              </div>

                              <div className="accordion-item">
                                <h2 className="accordion-header corse-circu" id="heading02">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse02" aria-expanded="false" aria-controls="collapse02">Salesforce Platform Basics ​​ </button>
                                </h2>
                                <div id="collapse02" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <ul className="cources-cal-cub">
                                      <li>Get Started with the Salesforce Platform​​</li>
                                      <li>Discover Use Cases for the Platform​</li>
                                      <li>Understand the Salesforce Architecture​​</li>
                                      <li>Navigate Setup​</li>
                                      <li>Power Up with AppExchange</li>

                                    </ul>
                                  </div>
                                </div>
                              </div>

                              <div className="accordion-item">
                                <h2 className="accordion-header corse-circu" id="heading03">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse03" aria-expanded="false" aria-controls="collapse03"> Lightning Experience Customization​​ </button>
                                </h2>
                                <div id="collapse03" className="accordion-collapse collapse" aria-labelledby="heading03" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <ul className="cources-cal-cub">
                                      <li> Set Up Your Org</li>
                                      <li>Create and Customize Lightning Apps    </li>
                                      <li>Create and Customize List Views</li>
                                      <li>Customize Record Highlights with Compact Layouts </li>
                                      <li>Customize Record Details with Page Layouts</li>
                                      <li>Create Custom Buttons and Links </li>
                                      <li>Empower your user with Quick Action</li>


                                    </ul>

                                  </div>
                                </div>
                              </div>

                              <div className="accordion-item">
                                <h2 className="accordion-header corse-circu" id="heading04">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse04" aria-expanded="false" aria-controls="collapse04"> Data Modeling​ ​ </button>
                                </h2>
                                <div id="collapse04" className="accordion-collapse collapse" aria-labelledby="heading04" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <ul className="cources-cal-cub">
                                      <li>Understand Custom & Standard</li>
                                      <li>Create Object Relationships</li>
                                      <li>Work with Schema Builder</li>

                                    </ul>
                                  </div>
                                </div>
                              </div>


                              <div className="accordion-item">
                                <h2 className="accordion-header corse-circu" id="heading05">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse05" aria-expanded="false" aria-controls="collapse05"> Picklist Administration​ </button>
                                </h2>
                                <div id="collapse05" className="accordion-collapse collapse" aria-labelledby="heading05" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <ul className="cources-cal-cub">
                                      <li>Get Started with Picklists</li>
                                      <li>Manage Your Picklist Values</li>
                                      <li> Share Values with Global Value Sets</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>



                              <div className="accordion-item">
                                <h2 className="accordion-header corse-circu" id="heading06">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse06" aria-expanded="false" aria-controls="collapse06"> Duplicate Management​​ </button>
                                </h2>
                                <div id="collapse06" className="accordion-collapse collapse" aria-labelledby="heading06" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <ul className="cources-cal-cub">

                                      <li>Improve Data Quality in Salesforce Resolve and Prevent Duplicate Data in Salesforce​</li>

                                    </ul>
                                  </div>
                                </div>
                              </div>




                              <div className="accordion-item">
                                <h2 className="accordion-header corse-circu" id="heading07">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse07" aria-expanded="false" aria-controls="collapse07"> Customize A Salesforce Object​</button>
                                </h2>
                                <div id="collapse07" className="accordion-collapse collapse" aria-labelledby="heading07" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <ul className="cources-cal-cub">

                                      <li>Work with Standard and Custom Fields​</li>
                                      <li>Create Picklists and Field Dependencies​</li>
                                      <li>Create Lookup Filters​</li>
                                      <li>Create Formula Fields​</li>
                                      <li>Create Record Types ​</li>
                                      <li>Create Account Page Layouts </li>
                                      <li>Enable Account Field History Tracking </li>
                                      <li>Create Validation Rules  </li>

                                    </ul>
                                  </div>
                                </div>
                              </div>



                              <div className="accordion-item">
                                <h2 className="accordion-header corse-circu" id="heading08">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse08" aria-expanded="false" aria-controls="collapse08"> Build A Data Model for A Travel Approval App ​ </button>
                                </h2>
                                <div id="collapse08" className="accordion-collapse collapse" aria-labelledby="heading08" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <ul className="cources-cal-cub">

                                      <li>Create a Travel Approval Lightning App ​</li>
                                      <li>Create a Department Object ​</li>
                                      <li>Create a Travel Approval Object ​</li>
                                      <li>Create an Expense Item Object </li>
                                      <li>Import Data and Test the App </li>

                                    </ul>
                                  </div>
                                </div>
                              </div>


                              <div className="accordion-item">
                                <h2 className="accordion-header corse-circu" id="heading09">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse09" aria-expanded="false" aria-controls="collapse09"> Formulas & Validation​</button>
                                </h2>
                                <div id="collapse09" className="accordion-collapse collapse" aria-labelledby="heading09" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <ul className="cources-cal-cub">

                                      <li>Use Formula Fields ​</li>
                                      <li> Implement Roll-Up Summary Fields​</li>
                                      <li>Create Validation Rules​</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>



                              <div className="accordion-item">
                                <h2 className="accordion-header corse-circu" id="heading010">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse010" aria-expanded="false" aria-controls="collapse010">  Improve Data Quality for A Recruiting App​ </button>
                                </h2>
                                <div id="collapse010" className="accordion-collapse collapse" aria-labelledby="heading010" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <ul className="cources-cal-cub">

                                      <li>Create Cross-Object Formulas  ​</li>
                                      <li>Create Validation Rules  ​</li>
                                      <li>Create Formula and Roll-Up Summary Field​</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>


                              <div className="accordion-item">
                                <h2 className="accordion-header corse-circu" id="heading011">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse011" aria-expanded="false" aria-controls="collapse011"> Customize the User Interface For A Recruiting App</button>
                                </h2>
                                <div id="collapse011" className="accordion-collapse collapse" aria-labelledby="heading011" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <ul className="cources-cal-cub">

                                      <li>Create a Tab for the Review Object</li>
                                      <li>Create an Object-Specific Quick Action</li>
                                      <li>Customize the Review Page Layout  Create a Custo</li>
                                      <li>Candidate Record Page</li>
                                      <li>Create Record Types on the Position Object</li>
                                      <li>Customize the Position Page Layout</li>
                                      <li>Enable Chatter on the Review Object </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>


                              <div className="accordion-item">
                                <h2 className="accordion-header corse-circu" id="heading012">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse012" aria-expanded="false" aria-controls="collapse012"> Flow Builder Basics ​​ </button>
                                </h2>
                                <div id="collapse012" className="accordion-collapse collapse" aria-labelledby="heading012" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <ul className="cources-cal-cub">

                                      <li>Get Started with Automation​</li>
                                      <li>Go with the Flow​</li>
                                      <li>Meet Flow Builder ​</li>
                                      <li> Learn About Flow Variables ​</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>

                              <div className="accordion-item">
                                <h2 className="accordion-header corse-circu" id="heading013">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse013" aria-expanded="false" aria-controls="collapse013">  Build A Simple Flow ​</button>
                                </h2>
                                <div id="collapse013" className="accordion-collapse collapse" aria-labelledby="heading013" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <ul className="cources-cal-cub">

                                      <li>Collect Contact Info from Your User​</li>
                                      <li>Check for a Matching Contact in Your Org​</li>
                                      <li>Branch the Flow</li>
                                      <li> Create or Update a Contact</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>

                              <div className="accordion-item">
                                <h2 className="accordion-header corse-circu" id="heading014">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse014" aria-expanded="false" aria-controls="collapse014">  Screen Flows ​ </button>
                                </h2>
                                <div id="collapse014" className="accordion-collapse collapse" aria-labelledby="heading014" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <ul className="cources-cal-cub">

                                      <li>Get Started with Screen Flows</li>
                                      <li>Build a Screen Flow </li>
                                      <li>Give Users a Choice</li>
                                      <li>Improve the Look and Feel of Your Screens</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>


                              <div className="accordion-item">
                                <h2 className="accordion-header corse-circu" id="heading015">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse015" aria-expanded="false" aria-controls="collapse015"> Quick Start- Lightning App Builder</button>
                                </h2>
                                <div id="collapse015" className="accordion-collapse collapse" aria-labelledby="heading015" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <ul className="cources-cal-cub">

                                      <li>Create Your First Page</li>
                                      <li>Add More Components </li>
                                      <li>Add Quick Actions and Activate the App</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>




                              <div className="accordion-item">
                                <h2 className="accordion-header corse-circu" id="heading016">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse016" aria-expanded="false" aria-controls="collapse016"> Data Management</button>
                                </h2>
                                <div id="collapse016" className="accordion-collapse collapse" aria-labelledby="heading016" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <ul className="cources-cal-cub">

                                      <li>Import Data​</li>
                                      <li>Export Data</li>

                                    </ul>
                                  </div>
                                </div>
                              </div>



                              <div className="accordion-item">
                                <h2 className="accordion-header corse-circu" id="heading017">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse017" aria-expanded="false" aria-controls="collapse017"> Event Monitoring ​</button>
                                </h2>
                                <div id="collapse017" className="accordion-collapse collapse" aria-labelledby="heading017" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <ul className="cources-cal-cub">

                                      <li>Get Started with Event Monitoring​​</li>
                                      <li>Query Event Log Files</li>
                                      <li>Download and Visualize Event log Files </li>

                                    </ul>
                                  </div>
                                </div>
                              </div>


                              <div className="accordion-item">
                                <h2 className="accordion-header corse-circu" id="heading018">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse018" aria-expanded="false" aria-controls="collapse018">  Data Security ​ ​</button>
                                </h2>
                                <div id="collapse018" className="accordion-collapse collapse" aria-labelledby="heading018" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <ul className="cources-cal-cub">

                                      <li>Overview of Data Security ​​​</li>
                                      <li>Control Access to the Org ​</li>
                                      <li>Control Access to Objects ​</li>
                                      <li>Control Access to Fields ​</li>
                                      <li>Control Access to Records​</li>
                                      <li>Define Sharing Rules ​</li>

                                    </ul>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingsix">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix"> Capston Project </button>
                  </h2>
                  <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <ul>
                        <li> Work on the industry project in a collaborative way which cover all the concept of a SalesForce developer. </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="faqScen faqScenbg mb-0 pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="mb-4 mt-4"> Frequently Asked Questions </h2>
              </div>
              <div className="bg_tabsfaq1">
                <div className="row">
                  <div className="col-md-3">
                    <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                      <button className="nav-link active mt-0" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">
                        <span className="imgs">
                          <img src={require(`../assets/images/a1_black.png`)} className="img-fluid img1" alt="Skillible- FAQ- General Questions" />
                          <img src={require(`../assets/images/a1_white.png`)} className="img-fluid img2" alt="Skillible- FAQ- General Questions" />
                        </span>
                        <span>General Questions</span>
                      </button>
                      <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                        <span className="imgs">
                        <img src={require(`../assets/images/a2_black.png`)} className="img-fluid img1" alt="Skillible- FAQ- Fees & Income" />
                        <img src={require(`../assets/images/a2_white.png`)} className="img-fluid img2" alt="Skillible- FAQ- Fees & Income" />
                        </span>
                        <span> Fees & Income </span>
                      </button>
                      <button className="nav-link mb-0" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                        <span className="imgs">
                        <img src={require(`../assets/images/a3_black.png`)} className="img-fluid img1" alt="Skillible- Admission Queries" />
                        <img src={require(`../assets/images/a2_white.png`)} className="img-fluid img2" alt="Skillible- Admission Queries" />
                        </span>
                        <span>Admission Queries </span>
                      </button>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="tab-content general_questions" id="v-pills-tabContent">
                      <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                        <div className="general-s">
                          <h3>General Questions</h3>
                          <div className="">
                            <div className="accordion" id="accordionExample">
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="heading1">
                                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1"> What will happen after the training? </button>
                                </h2>
                                <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <p> Skillible will offer full support to get you placed in a good company, after successful completion of the training course, with salary as per industry standards.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="heading2">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2"> Can I do this training course while doing my job? </button>
                                </h2>
                                <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <p> No, you cannot do this course along with your job. The curriculum is time intensive so at the moment this is a full-time training course, and you should only apply if you are not working anywhere currently.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="heading3">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3"> If any student doesn't have a PC due to some financial issues, do you provide them with the same?  </button>
                                </h2>
                                <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <p> No, we don't offer any such facility as of now. </p>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="heading4">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">  I don't know basic programming languages, still I'm interested in learning coding. Can I apply?  </button>
                                </h2>
                                <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <p> Yes, you can apply but you should know basic things related to computers and have a technological mindset.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">  <div className="general-s">
                        <h3> Fees & Income  </h3>
                        <div className="">
                          <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="heading1">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1"> How are you training us at zero-upfront cost? Is this a scam?  </button>
                              </h2>
                              <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                  <p>
                                    "We would like to assure you that Skillible is not engaged in any type of fraudulent activity. Our organization
                                    is firmly committed to investing in the advancement of the workforce. As part of this commitment, we cover the expenses
                                    of your training, and you will not be required to make any upfront payments until you secure a placement with a minimum
                                    annual package of 3.5 LPA (Excluding  GST). Once you secure a placement, you can conveniently repay for the training
                                    through simple and manageable EMIs."</p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="heading2">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">

                                  How much should I pay for the "Career Vriddhi" Program?  </button>
                              </h2>
                              <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                  <p>
                                    As part of the payment terms, the Learner shall be required to make monthly payments during the Payment Term, commencing when she starts earning the Qualified Monthly Earned Income. The total amount to be paid is 99,000 (excluding GST).
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="accordion-item">
                              <h2 className="accordion-header" id="heading3">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3"
                                  aria-expanded="false" aria-controls="collapse3">   I want to enroll by paying upfront, how much fee do I need to pay?   </button>
                              </h2>
                              <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                  <p>
                                    You get 20% off on the course fees if you choose to make an upfront Payment. The upfront fee is INR 79,200 (excl. GST).</p>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="heading4">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">    Is there any registration fees to apply for the course?  </button>
                              </h2>
                              <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                  <p>No, the registration is completely free.</p>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      </div>
                      <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                        <div className="general-s">
                          <h3>Application Questions </h3>
                          <div className="">
                            <div className="accordion" id="accordionExample">
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="heading1">
                                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">  Is Salesforce the right choice for my career?  </button>
                                </h2>
                                <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <p>Salesforce is the leading cloud services provider globally and has been aggressively expanding its market presence in India creating tons of job opportunities here. There is something for everyone, regardless of your professional background and the demand for Salesforce Professionals is increasing year-on-year.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="heading2">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">  What are the pre-requisites to join the course?  </button>
                                </h2>
                                <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <ul>
                                      <li>Final year students of a technical discipline can apply.</li>
                                      <li>Women on a Career Break </li>
                                      <li>Women who have graduated recently</li>
                                      <li>Women in STEM</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>

                              <div className="accordion-item">
                                <h2 className="accordion-header" id="heading3">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">   Am I eligible for this course if I'm from a non-technical background?  </button>
                                </h2>
                                <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <p>Sadly, no. You need to be a graduate in any technical discipline of your choice.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="heading4">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">   Can someone who has 2 years gap after his graduation and doesn't have a job for this year apply for the course?  </button>
                                </h2>
                                <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <p>Yes, absolutely. If you are a woman who has been on a career break (from 6 months to 8 years), you can apply for this course.

                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="heading4">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">    How long will it take to complete the course?  </button>
                                </h2>
                                <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionExample">
                                  <div className="accordion-body">
                                    <p>The Salesforce Training Program will run for 12 weeks (3 months)
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>


      </div>



    </React.Fragment>
  )
}
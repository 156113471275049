import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HeaderHome from './HeaderHome'
import axios from 'axios';
import { SERVICE_URL } from '../constant';
export default function HireTrainDeploy() {

  const [inputs, setInputs] = useState({
    fullName: '',
    email: '',
    designation: '',
    company: '',
    mobile: '',
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [validations, setValidations] = React.useState({
    fullName: '',
    email: '',
    designation: '',
    company: '',
    mobile: '',
    errorMessage: ''
  })

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs(values => ({ ...values, [name]: value }));
  }

  const handleFieldValidation = (field, errorMessage) => {
    if (!inputs[field]) {
      setValidations(prevState => ({
        ...prevState,
        [field]: errorMessage
      }));
      return false;
    } else {

      if (field === 'fullName') {
        const pattern = /^[A-Za-zÀ-ÿ ,.'-]+$/;
        if (!pattern.test(inputs[field])) {
          setValidations(prevState => ({
            ...prevState,
            [field]: 'Please enter full Name'
          }));
          return false;
        }
      }

      if (field === 'designation') {
        const pattern = /^[A-Za-zÀ-ÿ ,.'-]+$/;
        if (!pattern.test(inputs[field])) {
          setValidations(prevState => ({
            ...prevState,
            [field]: 'Please enter Designation'
          }));
          return false;
        }
      }

      if (field === 'mobile') {
        const phoneRegex = /^(?:\+(?:[0-9] ?){6,14}[0-9]|(?:\+?1[2-9]\d{2}[2-9](?!11)\d{6})|(?:[789]\d{9}))$/;
        if (!phoneRegex.test(inputs[field])) {
          setValidations(prevState => ({
            ...prevState,
            [field]: 'Please enter valid mobile Number'
          }));
          return false;
        }
      }

      if (field === 'email') {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(inputs[field])) {
          setValidations(prevState => ({
            ...prevState,
            [field]: 'Please enter valid Email'
          }));
          return false;
        }
      }

      else {
        setValidations(prevState => ({
          ...prevState,
          [field]: ''
        }));
      }
      return true;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let isFormValid = true;
    setValidations(prevState => ({
      ...prevState,
      fullName: '',
      company: '',
      email: '',
      mobile: '',
      designation: '',
    }));

    // Check each field and set error messages if required
    if (!handleFieldValidation('fullName', 'Please enter your full Name')) {
      isFormValid = false;
    }

    if (!handleFieldValidation('company', 'Please enter your Company Name')) {
      isFormValid = false;
    }

    if (!handleFieldValidation('designation', 'Please enter your Designation')) {
      isFormValid = false;
    }

    if (!handleFieldValidation('email', 'Please enter your Email')) {
      isFormValid = false;
    }

    if (!handleFieldValidation('mobile', 'Please enter your Phone')) {
      isFormValid = false;
    }

    if (isFormValid) {
      const requestOptions = {
        headers: {
          'Content-Type': 'application/json',
          "x-api-key": "P3yWDK7Aur2Of8y8AJHDza4GtilPsOG06HKp66b0"
        }
      };
      var data = {
        fullname: inputs.fullName,
        emailId: inputs.email,
        phone: inputs.mobile,
        company_name: inputs.company,
        designation: inputs.designation,
        contact_reason: "Hire from us",
      };
      try {
        const result = await axios.post(SERVICE_URL + "/enquiry/company", data, requestOptions, { crossDomain: true });
        console.log(result);
        if (result.data.statusCode === "201") {
          setSuccess(true);
          setErrorMsg('');
          setError(false);
          setInputs({});
        }
        else {
          setErrorMsg(result.data.detail.message);
          setSuccess(false);
          setError(true);
        }

      }
      catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <React.Fragment>
      <HeaderHome />
      <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <title>Bridge your Talent demand-supply gap with Skillible</title>
          <meta name="description" content="Our HTD model aims to provide value to job aspirants, empowering young people with the skills and knowledge necessary to meet the growing demand of industry." />
          <link rel="canonical" href="http://skillible.io/" />
          <meta property="og:title" content="Skillible - We Help You Succeed In Your Career!" />
          <meta property="og:description" content="Our HTD model aims to provide value to job aspirants, empowering young people with the skills and knowledge necessary to meet the growing demand of industry." />
          <meta property="og:url" content="http://skillible.io/" />
          <meta property="og:type" content="website" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:site_name" content="Skillible" />

        </Helmet>
      </HelmetProvider>
      <div>
        <section className="industries-banner she-leads">
          <div className="container">
            <div className="row">
              <div className="col-md-6">

                <div className="text-banner hire-tr-td">
                  <h1>Looking Out  <span>For Trained</span> Industry Resources?? </h1>
                  <p> Let's start Bridging Talent <span>demand-supply gap with</span> Skillible !!!</p>

                </div>
              </div>
              <div className="col-md-6 text-center">
                <div className="img-banner pair-imgdv ndvt-2 hire-from-us-s">
                  <span className="pair-img"><img src={require(`../assets/images/pairw.png`)} className="img-fluid" alt="" /></span>
                  <div className="pairbanner_girl">
                    <img src={require(`../assets/images/banner_girl2.png`)} className="img-fluid" alt="Hire with Skillible" />
                  </div>

                  <div className="pewilly">

                    <div className="pewilly-img">
                      <img src={require(`../assets/images/build.png`)} className="img-fluid" alt="Expand your workforce with Skillible" />
                    </div>
                    <div className="pewilly-text">
                      <p>Build <span>We can expand your workforce and contrive skill needs </span></p>

                    </div>
                  </div>
                  <div className="pewilly-operate">
                    <div className="pewilly-img">
                      <img src={require(`../assets/images/operate.png`)} className="img-fluid" alt="Live Bootstrap Training" />
                    </div>
                    <div className="pewilly-text">
                      <p>Operate <span>Benefit from live bootstrap training to upskill your workforce. </span></p>
                    </div>
                  </div>
                  <div className="pewilly-transfer">
                    <div className="pewilly-img">
                      <img src={require(`../assets/images/trasfer.png`)} className="img-fluid" alt="Deployment Ready Candidates from Day 1" />
                    </div>
                    <div className="pewilly-text">
                      <p>Transfer <span>Our Trained Talents are ready to work on client projects from day 1.</span></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pair-sectiontext pair-sectiontext2 hd2 ndvt-3">
          <div className="container">
            <div className="row mx-wth">
              <div className="col-md-12 col-lg-6">
                <div className="frenceimg-pair">
                  <div className="img-frence-pair"><img src={require(`../assets/images/hIRE-S.png`)} className="img-fluid" alt="Hire and customize talent as per your need" />
                    <div className="hire-hfs">
                      <div className="hire-hfs-img">
                        <img src={require(`../assets/images/hiring-icon-h.png`)} className="img-fluid" alt="" />
                      </div>
                      <div className="pewilly-text-hire">
                        <p>Hire <span>Not just freshers, recruit mid-level experienced professionals
                          through tests and interviews  </span></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="languapair-steps step1">
                  <h2>HIRE</h2>
                  <ul>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className="">Hire our candidates at Free of cost</div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className="">Customize Talents as per your demands.</div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className="">Training cost is borne by us</div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className="">Gurranteed higher Productivity. </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className="">Not just programming, but our learners have been intensely trained in soft-skills to become able leaders. </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pair-sectiontext hd2 ndvt-3">
          <div className="container">
            <div className="row displ-lrtd mx-wth">
              <div className="col-md-12 col-lg-6 text-end">
                <div className="frenceimg-pair">
                  <div className="img-frence-pair"><img src={require(`../assets/images/train.png`)} className="img-fluid" alt="Hire trained candidates with Skillible" />

                    <div className="train-hfs">
                      <div className="train-hfs-img">
                        <img src={require(`../assets/images/skill-train.png`)} className="img-fluid" alt="Customized training with Skillible" />
                      </div>
                      <div className="pewilly-text-train">
                        <p>Train <span>An opportunity to select candidates as per training charter and skill requirements.</span>
                        </p>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="languapair-steps step1 ">
                  <h2>TRAIN</h2>
                  <ul>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className="">All of our selected applicants have received at least 6 months of training.</div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className="">This training can be customized as per the client needs or the project requirement.</div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className="">Mentorship and guidance throughout the program.</div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className="">Continuous monitoring & live interactive sessions.</div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className="">Final Written technical test as well as presentation on the Application or Use Case developed during the training by the candidate for client evaluation.</div>
                    </li>
                  </ul>

                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pair-sectiontext pair-sectiontext2 hd2 ndvt-3">
          <div className="container">
            <div className="row mx-wth">
              <div className="col-md-12 col-lg-6">
                <div className="frenceimg-pair">
                  <div className="img-frence-pair"><img src={require(`../assets/images/depoly.png`)} className="img-fluid" alt="Hire deploy ready candidates from Day 1 -Skillible" />

                    <div className="hire-hfs">
                      <div className="hire-hfs-img">
                        <img src={require(`../assets/images/deploy.png`)} className="img-fluid" alt="Reduce attrition rate with Skillible" />
                      </div>
                      <div className="pewilly-text-hire">
                        <p>Depoly <span>From day one, candidates may be employed as FTE or CWR resources.</span></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="languapair-steps step1">
                  <h2>DEPLOY</h2>
                  <ul>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className="">Unique deployment strategy to reduce employee attrition rates.</div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className="">On Completion of the classroom training, the trainee’s will be deployed in OJT (on-job-training)
                        from Day 1.</div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className="">
                        Appropriate strategies are implemented to mitigate any risk and ensure talent is supported throughout the deployment process.
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="hire-skillible-s hd2 ">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="">
                  <h2>Skillible Creating Solutions For Everybody </h2>
                  <p>Save on time and cost with the <span className="htd-skill"> SKILLIBLE HTD </span>model </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="transcription-solutions">
          <div className="container">
            <div className="row d-full">
              <div className="col-md-12 col-lg-6 text-left">
                <div className="same-steps translation-step1 hdsame1 employers">
                  <h2> For Employers</h2>
                  <ul>
                    <li><span className="icon"><img src={require(`../assets/images/talents.png`)} className="img-fluid" alt="" /></span>Creating customized Talents </li>
                    <li><span className="icon"><img src={require(`../assets/images/hiring.png`)} className="img-fluid" alt="" /></span> Mass Hiring Facility Based On specific industry skill set </li>
                    <li><span className="icon"><img src={require(`../assets/images/free.png`)} className="img-fluid" alt="" /></span> No cost is involved in sourcing and training candidates. </li>
                    <li><span className="icon"><img src={require(`../assets/images/candidates.png`)} className="img-fluid" alt="" /></span> Candidates ready to be deployed from Day 1 </li>
                    <li><span className="icon"><img src={require(`../assets/images/time.png`)} className="img-fluid" alt="" /></span> Face no more shortage of time and resources required for training new candidates. </li>
                    <li><span className="icon"><img src={require(`../assets/images/tracks.png`)} className="img-fluid" alt="" /></span> Customized training tracks are available in areas like Full Stack Developers & Salesforce certified Admin & Developers. </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="transcription-img"><span className="img"><img src={require(`../assets/images/employers.png`)} className="img-fluid" alt="Access customized talents with Skillible" /><span className="icon2-trans">
                  <img src={require(`../assets/images/employer-icon.png`)} className="img-fluid" alt="Hire candidates at zero cost only with Skillible" /></span></span></div>
              </div>
            </div>
          </div>
        </section>
        <section className="subtitling-solutions htd-bg">
          <div className="container">
            <div className="row d-full">
              <div className="col-md-12 col-lg-5 text-end beforcircl">
                <div className="subtitling-img1"><span className="img"><img src={require(`../assets/images/for-candidate.png`)} className="img-fluid" alt="Customized training programs based on industry needs-Skillible" /><span></span></span>
                  <div className="img-btnm1">
                    <ul>
                      <li>
                        <Link to="#"><span><img src={require(`../assets/images/candidate-hire.png`)} className="img-fluid"
                          alt="Hire Candidate with Skillible" /></span></Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-7 text-left">
                <div className="same-steps translation-step1 hdsame1 employers">
                  <h2> For Candidates </h2>
                  <ul>
                    <li><span className="icon"><img src={require(`../assets/images/cost-hire.png`)} className="img-fluid" alt="" /></span>Robust, Flexible & Cost Effective. </li>
                    <li><span className="icon"><img src={require(`../assets/images/support-hire.png`)} className="img-fluid" alt="" /></span>Complete training support to candidates till they get deployed. </li>
                    <li><span className="icon"><img src={require(`../assets/images/skill-hire.png`)} className="img-fluid" alt="" /></span>You are exclusively trained to develop specific skill sets as per industry demands. </li>
                    <li><span className="icon"><img src={require(`../assets/images/hiring-hire.png`)} className="img-fluid" alt="" /></span>We are equipped with the best training and hiring infrastructure. </li>
                    <li><span className="icon"><img src={require(`../assets/images/job-hire.png`)} className="img-fluid" alt="" /></span>Advanced Training while on the job. </li>
                    <li><span className="icon"><img src={require(`../assets/images/industry-hire.png`)} className="img-fluid" alt="" /></span>
                      A tailored training program based on industry needs.

                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="interpretr-sec1 sce991 mt-6">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="hding2 colr-spanh2">
                  <h2> Key <span>Benefits </span>of our HTD Model</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row displ-lrt1">
              <div className="col-md-6 float-end">
                <div className="inter-li1">
                  <span>
                    <img src={require(`../assets/images/businees-htd.png`)} className="img-fluid" alt=" A value adding Business Model - Skillible" />
                  </span>
                  <div className="">
                    <h4> A value adding Business Model</h4>
                  </div>
                </div>
                <div className="inter-li1 inter-li2">
                  <span>
                    <img src={require(`../assets/images/expert-htd.png`)} className="img-fluid" alt="Trained through Global Industry Experts - Skillible" />
                  </span>
                  <div className="">
                    <h4>  Trained through Global Industry Experts
                    </h4>
                  </div>
                </div>
                <div className="inter-li1 inter-li3">
                  <span>
                    <img src={require(`../assets/images/record-htd.png`)} className="img-fluid" alt="Proven Track Record of Success - Skillible" />
                  </span>
                  <div className="">
                    <h4> Proven Track Record of Success </h4>
                  </div>
                </div>
                <div className="inter-li1 inter-li4">
                  <span>
                    <img src={require(`../assets/images/placement-htd.png`)} className="img-fluid" alt=" Placement Assistance - Skillible" />
                  </span>
                  <div className="">
                    <h4> Placement Assistance </h4>
                  </div>
                </div>
              </div>
              <div className="col-md-6 text-center float-start">
                <span>
                  <img src={require(`../assets/images/htd-key-benfits.png`)} className="img-fluid" alt="Hire-Train and Deploy with Skillible" />
                </span>
              </div>
            </div>
          </div>
        </section>
        <section className="section-full p-t100 p-b90 site-bg-gray twm-bg-ring-wrap2">
          <div className="twm-bg-ring-right"></div>
          <div className="twm-bg-ring-left"></div>
          <div className="container-fluid">
            <div className="wt-separator-two-part width90pst">
              <div className="row">
                <div className="col-md-12 text-center our-capabli">
                  <div className="">
                    <h2>Our Capabilities </h2>
                  </div>
                </div>
              </div>
              <div className="section-content">
                <div className="twm-jobs-grid-wrap">
                  <div className="row">
                    <div className="col-lg-3 col-md-6">
                      <div className="twm-jobs-grid-style1  m-b30">
                        <div className='d-flex justify-content-start'>
                          <div className="twm-media">
                            <img src={require(`../assets/images/fullstack-developers.png`)} alt="Full stack developers candidates available at Skillible" />
                          </div>
                          <span className="twm-job-post-duration">Full stack developers </span>
                        </div>
                        <div className="twm-mid-content">
                          <h4>Full stack developers</h4>
                          <p className="twm-job-address">Candidates have knowledge of both elements (front-end and back-end) of web applications </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="twm-jobs-grid-style1  m-b30">
                        <div className='d-flex justify-content-start'>
                          <div className="twm-media">
                            <img src={require(`../assets/images/salesforce-devloper.png`)} alt="Salesforce Development Program  - skillible" />
                          </div>
                          <span className="twm-job-post-duration">Salesforce Development Program </span>
                        </div>
                        <div className="twm-mid-content">
                          <h4>Salesforce Development Program </h4>
                          <p className="twm-job-address">Get Salesforce certified developers who can work with enterprise CRM </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="twm-jobs-grid-style1  m-b30">
                        <div className='d-flex justify-content-start'>
                          <div className="twm-media">
                            <img src={require(`../assets/images/enterprise-mobility.png`)} alt="Enterprise mobility management with Skillible" />
                          </div>
                          <span className="twm-job-post-duration">Enterprise mobility </span>
                        </div>
                        <div className="twm-mid-content">
                          <h4>Enterprise mobility management</h4>
                          <p className="twm-job-address">EMM Professionals can manage mobile devices, wireless networks, and other mobile computing services in an organization </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="twm-jobs-grid-style1  m-b30">
                        <div className='d-flex justify-content-start'>
                          <div className="twm-media">
                            <img src={require(`../assets/images/microsoft.png`)} alt="Microsoft sccm manager - Skillible" />
                          </div>
                          <span className="twm-job-post-duration">Microsoft sccm manager </span>
                        </div>
                        <div className="twm-mid-content">
                          <h4>Microsoft sccm manager</h4>
                          <p className="twm-job-address">SCCM professionals are trained in management, deployment and security of devices and apps across an enterprise </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section section1 step_change">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="heading_black mb-5">
                  <h5>Our Core Values <br />
                    <span></span>
                  </h5>
                </div>
              </div>
              <div className="tn_assignment">
                <div className=" text-center Submit_Assinment2">
                  <ul>
                    <li>
                      <span>
                        <img src={require(`../assets/images/opportunity1.png`)} className="img-fluid" alt="Promote Diversity & Equality - Skillible" />
                      </span>
                    </li>
                    <li>
                      <h3>To Promote Diversity & Equality </h3>
                    </li>
                    <li className="number">3</li>
                  </ul>
                </div>
                <div className=" text-center Submit_Assinment2">
                  <ul>
                    <li>
                      <span>
                        <img src={require(`../assets/images/pay-iu.png`)} className="img-fluid" alt="  Skillible is Committed To Give Back To the society" />
                      </span>
                    </li>
                    <li>
                      <h3>Committed To Give Back To the society </h3>
                    </li>
                    <li className="number">4</li>
                  </ul>
                </div>
                <div className="text-center Submit_Assinment2">
                  <ul>
                    <li>
                      <span>
                        <img src={require(`../assets/images/job.png`)} className="img-fluid" alt=" Enhance your growth with Skillible" />
                      </span>
                    </li>
                    <li>
                      <h3>Built for Amplification </h3>
                    </li>
                    <li className="number">1</li>
                  </ul>
                </div>
                <div className="text-center Submit_Assinment2">
                  <ul>
                    <li>
                      <span>
                        <img src={require(`../assets/images/industry.png`)} className="img-fluid" alt="Ensured Customer Satisfaction with Skillible" />
                      </span>
                    </li>
                    <li>
                      <h3> Customer Gratification </h3>
                    </li>
                    <li className="number">2</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="why-our-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="owl-carousel owl-theme slider-for-business dottdsn dottdsn-fxtst" id="testimon-home">
                  <div className="item">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="why-our-text text-center testim-home-page">
                          <h2>Testimonials</h2>
                          <div className="name_tstitext">
                            <div className="arrow_testimoil">&ldquo;</div>
                            <p>" We used Skillible to locate a software security expert with knowledge of the finance industry who could examine our architecture as well as our server, and web service. He was a long shot, but we discovered him utilizing this site in just a matter of few days. "  </p>
                            <div className="name_tsti1">
                              <div className="name_tsti">   Ash Grey
                                <span>Senior Recruiter    </span></div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="item">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="why-our-text text-center testim-home-page">
                          <h2>Testimonials</h2>
                          <div className="name_tstitext">
                            <div className="arrow_testimoil">&ldquo;</div>
                            <p>" It is so difficult to find a perfect job match for yourself. Getting trained with Skillible meant being exposed to multiple opportunities that are looking to hire well-trained talents like me.    "</p>
                            <div className="name_tsti1">
                              <div className="name_tsti">       Sarah M.  <span>       Sarah M.  </span></div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="item">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="why-our-text text-center testim-home-page">
                          <h2>Testimonials</h2>
                          <div className="name_tstitext">
                            <div className="arrow_testimoil">&ldquo;</div>
                            <p>" I'm writing to thank Skillible for assisting me in obtaining the position of Technical Consultant at a reputable company. Her prompt response, open communication, and timely and effective cooperation were highly appreciated. " </p>
                            <div className="name_tsti1">
                              <div className="name_tsti">  Malthi Sinha  <span>   Skillible Candidate</span></div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="conatct-section contact-formsce contact-formsce1">
          <div className="container">
            <div className="row">
              <div className="col-mc-12">
                <div className="bg_contactForm">
                  <div className="row">
                    <div className="col-md-12 pb-4 text-start hding2">
                      <h2>Find the Right Candidates <span>Today!</span>
                      </h2>
                    </div>
                    <div className="col-12">
                      <p id="cntrepmsg"></p>
                    </div>
                  </div>
                  <form className="row needs-validation" id="free_inquiry" >
                    <input type="hidden" name="country_code" id="contact_country_code" />
                    <div className="col-md-12 col-lg-6">
                      <div className="row">
                        <div className={`col-md-12 ${validations.fullName && 'has-error'}`}>
                          <div className="fildsstr">
                            <input type="text"
                              className="form-control" name="fullName" value={inputs.fullName || ""}
                              placeholder="Full Name" onChange={handleChange} />
                            {validations.fullName && (
                              <div className="error-message">{validations.fullName}</div>
                            )}
                          </div>

                        </div>
                        <div className={`col-md-12 ${validations.mobile && 'has-error'}`}>
                          <div className="fildsstr">
                            <input type="text" className="form-control"
                              maxLength="10"
                              id="inputPhone" name="mobile" onChange={handleChange} placeholder="Phone Number"
                              value={inputs.mobile || ""} />

                            {validations.mobile && (
                              <div className="error-message">{validations.mobile}</div>
                            )}
                          </div>
                        </div>
                        <div className={`col-md-12 ${validations.designation && 'has-error'}`}>
                          <div className="fildsstr">
                            <input type="text"
                              className="form-control" name="designation" value={inputs.designation || ""}
                              placeholder="Designation" onChange={handleChange} />
                            {validations.designation && (
                              <div className="error-message">{validations.designation}</div>
                            )}
                          </div>

                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className={`col-md-12 ${validations.email && 'has-error'}`}>
                        <div className="fildsstr">
                          <input type="text"
                            className="form-control"
                            placeholder="Email ID" name='email' value={inputs.email || ""} onChange={handleChange} />
                          {validations.email && (
                            <div className="error-message">{validations.email}</div>
                          )}
                        </div>
                      </div>
                      <div className={`col-md-12 ${validations.company && 'has-error'}`}>
                        <div className="fildsstr">
                          <input type="text"
                            className="form-control" name="company" value={inputs.company || ""}
                            placeholder="Company Name" onChange={handleChange} />
                          {validations.company && (
                            <div className="error-message">{validations.company}</div>
                          )}
                        </div>

                      </div>
                    </div>
                    <div className="col-md-12 col-lg-12">
                      <div className="text-center">
                        <button className="btn" type="button" id="buttonSubmitNow" name="temp" onClick={(e) => handleSubmit(e)}>Submit Now</button>
                      </div>
                    </div>
                    <div className="col-md-12 text-center ">
                    {success ?
                                    <div className="form-check pt-3 sucss">
                                       <label className="succes-msg"> Thank you! We will get in touch with you soon!
                                       </label>
                                    </div>
                                    : ''
                                 }
                                 {error ?
                           <div className="form-check pt-3 deng">
                              <label className="danger-msg" id="not-store-data"> {errorMsg}
                              </label>
                           </div> : ''
                        } 

                    </div>
                    <div className="col-md-12 text-center">
                      <div className="or_bodr1">
                        <span>or</span>
                      </div>
                    </div>
                    <div className="col-md-12 text-center">
                      <div className="sign_up_link">Email us at -
                        <a href="mailto:hire@skillible.io">hire@skillible.io</a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>

    </React.Fragment>
  )
}
import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HeaderHome from './HeaderHome';
export default function report() {
return (
<React.Fragment>
   <HeaderHome />
   <HelmetProvider>
      <Helmet>
         <meta charset="UTF-8" />
         <title>Report</title>
         <meta name="description" content="An Initiative by Skillible to Upskill Women in India.
            Skillible & salesforce unites to bring more women back to the workforce." />
         <link rel="canonical" href="http://skillible.io/" />
         <meta property="og:title" content="Skillible - We Help You Succeed In Your Career!" />
         <meta property="og:description" content="An Initiative by Skillible to Upskill Women in India.
            Skillible & salesforce unites to bring more women back to the workforce." />
         <meta property="og:url" content="http://skillible.io/" />
         <meta property="og:type" content="website" />
         <meta property="og:locale" content="en_US" />
         <meta property="og:site_name" content="Skillible" />
      </Helmet>
   </HelmetProvider>
   <div>
      <section className="report-banner">
         <div className="container">
            <div className="row">
               <div className="col-md-12 text-center">
                  <div className="text-banner hire-tr-td">
                     <h3>  </h3>
                     <h1> Latest Industry Reports  <span>  </span>  </h1>
                     <p className="report-para">  Stay ahead of the curve with our exclusive reports on the latest developments in AI and new-age tech! With cutting-edge insights, stats, and trends from leading platforms and companies, these reports are your guide to mastering the future. Dive in now and stay ahead in the fast-evolving world of technology!
 
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </section>

      
      <section className="section bg_colr1 pt-4 pb-4">
         <div className="container">
            <div className="row">
               <div className="col-md-5">
                  <span className='report-gif'>
                  <img src={require(`../assets/images/home-revamp/report1.gif`)} className="img-fluid" alt="Skillible is the future of work" height="384" width="570" />
                  </span>

                  

               </div>
               <div className="col-md-7">
                  <div className="Text_title text_tb_center pb-3 reskilled-hg">
                     <h2> India Skills Report 2024: Bridging Talent and Opportunity</h2>
                     <p> The India Skills Report 2024 provides a comprehensive analysis of talent demand and supply, highlighting critical employability trends across industries. The report draws insights from a survey of over 3.88 lakh students and 152 corporates, focusing on skill gaps, AI's role in future job markets, and emerging industry requirements.  </p>
                  </div>
               </div>
            </div>
            <div className="women-tech-sn">
               {/* <h5 className="small-text-s pb-2"> Key Highlights: </h5> */}
               <h2 className='key-high-s'> Key Highlights: </h2>
               {/* <h3> India Skills Report 2024: <span class="aictc-partner"> The Future of AI and Skilling</span></h3> */}
               <div className="key-highlights">
                  <p><strong>1. </strong>  AI Growth and Impact: </p>
                  <ul className="">
                     <li> The Indian AI market is projected to grow to $28.8 billion by 2025, with a CAGR of 45%.  </li>
                     <li>AI is expected to add 4.6 million full-time jobs in India over the next five years. </li>
                     <li>India's AI talent demand is forecasted to reach 1 million by 2026, with significant growth in healthcare, finance, and manufacturing sectors.  </li>
                  </ul>
               </div>
               <div className="key-highlights">
                  <p><strong>2. </strong>  Rising Skill Demands:  </p>
                  <ul className="">
                     <li>A 33% increase in demand for AI skills globally between 2019 and 2022.  </li>
                     <li>Key AI-related roles include Machine Learning Engineers, NLP Engineers, and Computer Vision Engineers, with exponential growth projected by 2025.  </li>
                     <li>The demand for professionals with AI skills in India has surged, with a 14-fold increase in AI-skilled individuals from 2016 to 2023.  </li>
                  </ul>
               </div>
               <div className="key-highlights">
                  <p><strong>3. </strong>  New Job Opportunities:   </p>
                  <ul className="">
                     <li>AI is set to create 1.6 million new jobs in healthcare and 5 million new jobs in manufacturing by 2030. </li>
                     <li>Fintech solutions driven by AI are transforming financial inclusion, while AI-driven sustainability tools are playing a key role in environmental conservation.   </li>
                  </ul>
               </div>
               <div className="key-highlights">
                  <p><strong>4. </strong>  Government Initiatives:    </p>
                  <ul className="">
                     <li>India’s National AI Strategy, National Skill Development Mission, and Skill India initiatives are integral to equipping the youth with cutting-edge AI and tech skills, addressing the talent gap in emerging industries.  </li>
                  </ul>
               </div>
            </div>
            <div className='report-download-link'> 
            <button class="btn brouch-down mb-4"><a download="" href="https://wheebox.com/india-skills-report.htm" target="_blank"> Read more
            </a></button> </div>
         </div>
      </section>


      <section className="section bg_colr2 pt-4 pb-4">
         <div className="container">
            <div className="row clearfix1">
               <div className="col-md-7 float-left">
                  <div className="Text_title text_tb_center pb-3 reskilled-hg">
                     <h2> NASSCOM Future Skills Report</h2>
                     <p> The NASSCOM Future Skills report provides a comprehensive analysis of the growing demand for digital talent in India and the significant gap between the available fresh talent pool and industry needs. As technology adoption surges, particularly in areas like AI, Cloud, and Big Data, the report highlights the urgent need for reskilling and collaborative efforts between the government and industry to meet this demand. 
                     </p>
                  </div>
               </div>
               <div className="col-md-5 text-center float-right">
                 
               <span className='report-gif'>
                  <img src={require(`../assets/images/home-revamp/skills-report.gif`)} className="img-fluid" alt="Skillible is the future of work" height="384" width="570" />
                  </span>
               </div>
            </div>
            <div className="women-tech-sn">
            <h2 className='key-high-s'> Key Highlights: </h2>
               {/* <h5 className="small-text-s pb-2"> Key Highlights: </h5> */}
               {/* <h3> Future Skills Talent in India:  <span class="aictc-partner"> Demand and Supply Analysis</span></h3> */}
               <div className="key-highlights">
                  <p><strong>1. </strong>  Digital Talent Demand-Supply Gap: </p>
                  <ul className="">
                     <li>India’s demand for digital talent is projected to be 8x larger than the available fresh talent pool, growing to 20x by 2024. </li>
                     <li>By 2024, India’s digital talent workforce will reach 3.5M - 3.7M, driven by AI, Cloud, and Big Data Analytics roles.  </li>
                  </ul>
               </div>
               <div className="key-highlights">
                  <p><strong>2. </strong>  Key Growth Sectors:   </p>
                  <ul className="">
                     <li>AI, Cloud Computing, and Cybersecurity are seeing accelerated adoption across industries post-COVID, particularly in healthcare, finance, and manufacturing.  </li>
                     <li>Start-ups in emerging hubs contribute to 11-13% of the digital talent pool.  </li>
                  </ul>
               </div>
               <div className="key-highlights">
                  <p><strong>3. </strong>  Reskilling Opportunities:  </p>
                  <ul className="">
                     <li>India produces 45,000 - 50,000 job-ready digital graduates annually, with upskilling programs projected to close the demand gap. </li>
                     <li>Government and industry collaboration is fueling growth through Digital Centers of Excellence (CoEs) and nationwide skill development programs.    </li>
                  </ul>
               </div>
            </div>
            <div className='report-download-link'> 
               <button class="btn brouch-down mb-4"><a download="" href="https://community.nasscom.in/communities/talent/futureskills-talent-in-india-demand-supply-analysis.html" target="_blank"> Read more
               </a></button> 
            </div>
         </div>
      </section>


      <section className="interpretr-sec1 sce991 mt-6">
         <div className="container">
            <div className="row">
               <div className="col-md-5">
               <span className='report-gif'>
                  <img src={require(`../assets/images/home-revamp/key-trend.gif`)} className="img-fluid" alt="Skillible is the future of work" height="384" width="570" />
                  </span>
               </div>
               <div className="col-md-7">
                  <div className="Text_title text_tb_center pb-3 reskilled-hg">
                     <h2> Key Trends in   Technology 2024</h2>
                     <p> The McKinsey Technology Trends Outlook 2024 highlights key technological advancements that are transforming industries and reshaping the global economy. From the rise of generative AI to investments in sustainable energy, the report outlines the significant developments driving innovation and growth. 
                     </p>
                  </div>
               </div>
            </div>
            <div className="women-tech-sn">
            <h2 className='key-high-s'> Key Highlights: </h2>
               {/* <h5 className="small-text-s pb-2"> Key Highlights: </h5> */}
               {/* <h3> Technology's Next Frontier:  <span class="aictc-partner">   Key Trends Shaping 2024 </span></h3> */}
               <div className="key-highlights">
                  <p><strong>1. </strong>  Generative AI: </p>
                  <ul className="">
                     <li> Over $180 billion invested, significantly impacting sectors like healthcare and marketing.  </li>
                  </ul>
               </div>
               <div className="key-highlights">
                  <p><strong>2. </strong>  Electrification & Renewables:    </p>
                  <ul className="">
                     <li>Expected to receive $1.7 trillion in investments by 2030, enhancing sustainability.   </li>
                  </ul>
               </div>
               <div className="key-highlights">
                  <p><strong>3. </strong>  Cybersecurity: </p>
                  <ul className="">
                     <li> Anticipated annual spending of $200 billion as digital ecosystems grow.</li>
                  </ul>
               </div>
               <div className="key-highlights">
                  <p><strong>4. </strong>  Robotics & Automation: </p>
                  <ul className="">
                     <li> Contributing to 10% productivity growth in manufacturing. </li>
                  </ul>
               </div>
               <div className="key-highlights">
                  <p><strong>5. </strong>  AI Software: </p>
                  <ul className="">
                     <li> 50% of enterprises plan to integrate generative AI into their workflows. </li>
                  </ul>
               </div>
            </div>
            <div className='report-download-link'> <button class="btn brouch-down mb-4"><a download="" href="McKinsey Technology Trends 2024" target="_blank"> Read more 
               </a></button>
            </div>
         </div>
      </section>


      <section className="section bg_colr1 pt-4 pb-4">
         <div className="container">
            <div className="row">
               <div className="col-md-5">
               <span className='report-gif'>
                  <img src={require(`../assets/images/home-revamp/govt-upskill.gif`)} className="img-fluid" alt="Skillible is the future of work" height="384" width="570" />
                  </span>
               </div>
               <div className="col-md-7">
                  <div className="Text_title text_tb_center pb-3 reskilled-hg">
                     <h2>Govt to upskill skilling  plan for new-age need</h2>
                     <p> The Indian government's Skills Development and Entrepreneurship Ministry is launching a comprehensive upskilling initiative aimed at aligning training programs with national priorities and industry needs. This effort is designed to prepare the workforce for emerging technologies and sectors, thereby enhancing employability in a rapidly evolving job market.  </p>
                  </div>
               </div>
            </div>
            <div className="women-tech-sn">

             <h2 className='key-high-s'> Key Highlights: </h2>

               {/* <h5 className="small-text-s pb-2"> Key Highlights: </h5>
               <h3> Empowering India's Workforce:    <span class="aictc-partner"> Government Launches National Upskilling Initiative</span></h3>
              */}
               <div className="key-highlights">
                  <p><strong>1. </strong>  Alignment with National Initiatives:  </p>
                  <ul className="">
                     <li>The upskilling programs will be integrated with significant initiatives such as Make in India, green hydrogen, drones, semiconductors, and smart mobility to address current and future industry demands.</li>
                  </ul>
               </div>
               <div className="key-highlights">
                  <p><strong>2. </strong>  Focus on Private Sector Collaboration:   </p>
                  <ul className="">
                     <li>Enhanced engagement with the private sector is emphasized to ensure that the skills being taught are relevant and meet international standards.   </li>
                  </ul>
               </div>
               <div className="key-highlights">
                  <p><strong>3. </strong>  Industry-Driven Training:    </p>
                  <ul className="">
                     <li> The plan will leverage insights from industry leaders to create a robust framework for skill development, ensuring that training is aligned with the latest technological advancements. </li>
                  </ul>
               </div>
               <div className="key-highlights">
                  <p><strong>4. </strong>  Long-Term Goals:    </p>
                  <ul className="">
                     <li>The government aims to reskill and upskill a substantial portion of the workforce, focusing on creating a talent pool equipped for modern challenges.  </li>
                  </ul>
               </div>
               <div className="key-highlights">
                  <p><strong>5. </strong>  Investment in New Technologies:    </p>
                  <ul className="">
                     <li>There is a specific focus on emerging technologies that are set to drive economic growth, reflecting a commitment to fostering innovation in sectors critical to India's future.  </li>
                  </ul>
               </div>
               <div className="key-highlights">
                  <p>This initiative underscores the government's commitment to preparing its workforce for the demands of a new-age economy, emphasizing a proactive approach to skilling in various critical sectors​.     </p>
               </div>
            </div>
            <div className='report-download-link'> 
               <button class="btn brouch-down mb-4"><a download="" href="https://economictimes.indiatimes.com/jobs/hr-policies-trends/govt-to-upskill-skilling-plan-for-new-age-needs/articleshow/107174650.cms?utm_source=contentofinterest&utm_medium=text&utm_campaign=cppst" target="_blank"> Read More
               </a></button> 
            </div>
         </div>
      </section>


      <section className="section bg_colr2 pt-4 pb-4">
         <div className="container">
            <div className="row clearfix1">
               <div className="col-md-7 float-left">
                  <div className="Text_title text_tb_center pb-3 reskilled-hg">
                     <h2> Future-Proofing Talent:
                     NASSCOM's Blueprint for Digital Skilling in the New Economy</h2>
                     <p> The NASSCOM report on Future Skilling for the Digital Economy outlines the urgent need for organizations to integrate digital skilling into their core business strategies. It emphasizes building "composite" capabilities that combine technical, professional, and domain skills while leveraging diverse learning methods, including project-based training.
                     </p>
                     <p>Collaboration among organizations, academia, and government is essential to create a robust learning ecosystem for future-ready talent.</p>
                  </div>
               </div>
               <div className="col-md-5 text-center float-right">
               <span className='report-gif'>
                  <img height="422" width="524" src={require(`../assets/images/home-revamp/Future-Proofing.gif`)} className="img-fluid" alt="Upskill and Reskill with Skillible   " />
                 
                  </span>
               </div>
            </div>
            <div className="women-tech-sn">
            <h2 className='key-high-s'> Key Highlights: </h2>

               {/* <h5 className="small-text-s pb-2"> Key Highlights: </h5>
               <h3> Future-Proofing Talent:   <span class="aictc-partner"> NASSCOM's Blueprint for Digital Skilling in the New Economy </span></h3>
              */}
               <div className="key-highlights">
                  <p><strong>1. </strong>  Digital Skilling:: </p>
                  <ul className="">
                     <li> Integral to business strategies.  </li>
                  </ul>
               </div>
               <div className="key-highlights">
                  <p><strong>2. </strong>  Composite Capabilities:   </p>
                  <ul className="">
                     <li>Shift towards blending technical, professional, and domain skills.   </li>
                  </ul>
               </div>
               <div className="key-highlights">
                  <p><strong>3. </strong>  Multi-Modal Learning: </p>
                  <ul className="">
                     <li> Emphasis on hands-on and social learning approaches.</li>
                  </ul>
               </div>
               <div className="key-highlights">
                  <p><strong>4. </strong>  Collaborative Ecosystem: </p>
                  <ul className="">
                     <li> Partnerships with MOOCs and training providers to enhance skill development. </li>
                  </ul>
               </div>
            </div>
            <div className='report-download-link'> 
               <button class="btn brouch-down mb-4"><a download="" href="https://nasscom.in/knowledge-center/publications/future-skilling-digital-economy" target="_blank"> Read more
               </a></button> 
            </div>
         </div>
      </section>

      <section className="section text-center disclaimer">
         <p>
 <strong>Disclaimer:</strong> The reports available on this section of our website are aggregated from various publicly accessible sources across the web. We do not claim ownership of the information contained within these reports. To view the original source, Please click on "Read more" button, which will direct you to the respective source links.
  
         </p>
         </section>

   </div>
</React.Fragment>
)
}
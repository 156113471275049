import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HeaderHome from './HeaderHome';
import { Link } from 'react-router-dom';
export default function Fullstack() {

  return (
    <React.Fragment>
      <HeaderHome />
      <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <title>Top Full Stack Developer Course in India | Skillible</title>
          <meta name="description" content="Learn full stack development from top industry professionals. Get job placement after completion. Enrol for the full stack developer course today!" />
          <link rel="canonical" href="http://skillible.io/" />
          <meta property="og:title" content="Top Full Stack Developer Course in India | Skillible" />
          <meta property="og:description" content="Learn full stack development from top industry professionals. Get job placement after completion. Enrol for the full stack developer course today!" />
          <meta property="og:url" content="http://skillible.io/" />
          <meta property="og:type" content="website" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:site_name" content="Skillible" />
        </Helmet>
      </HelmetProvider>
      <div>
        <section className="banner-home service-banner servs-edt">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="text-banner">
                  <h1>
                    FOSTER YOUR CAREER AS A
                    <br />
                    Full-Stack Developer
                    with skillible's comprehensive <span> Job Guaranteed </span> program
                  </h1>
                  <p className="banner-para3">
                    An Intense Live Bootcamp to verse our learners with the most unique skills required to build database-backed APIs & web applications.
                  </p>
                  <p className="banner-para3">
                    <strong>IT'S TIME TO LEARN END- TO- END DEVELOPMENT ONLY WITH SKILLIBLE!! </strong>
                  </p>
                  <ul className="get-quote mb-5">
                    <li>
                      <Link to="#" className="active">
                        <button type="button">
                        Coming Soon
                        </button>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 text-center">
                <div className="img-banner servs-img1">
                  <div className="service-bannerimg" >
                    <span>
                      <img src={require(`../assets/images/fullstck-banner.png`)}
                        className="img-fluid" alt="Become A Full Stack Developer with Skillible Job Guaranteed Program " />
                    </span>
                    <span className="img_greenleft5t">
                    </span>
                    <span className="img_bluerightgt">
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-blue youjust-work">
          <div className="greenline-bg1">
            <img src={require(`../assets/images/bg-greenline.png`)} className="img-fluid" alt="" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <h2> You Just Work. We'll take care of your worries. </h2>
                <div className="aplus_box1 text-end">
                  <div className="tutor_havtx">
                    <span>
                      <img src={require(`../assets/images/logo.png`)} className="img-fluid" alt="" />
                    </span>
                    <div className="text-tutorscn">
                      <h4> Minimum <span> Qualification </span>
                      </h4>
                      <p> GRADUATION </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7 ps-0 pe-0">
                <div className="">
                  <img src={require(`../assets/images/right-side-img.png`)} className="img-fluid" alt="Full Stack Program Description- Skillible " />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="services-industry">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center hding2 ">
                <h2>Benefits of Full Stack Development Professional</h2>
              </div>
            </div>
          </div>
        </section>
        <section className="services-industry2">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-4 text-center">
                <div className="services-in">
                  <span className="trans-img">
                    <img src={require(`../assets/images/remuneration.png`)} className="img-fluid" alt="Benefits of Full Stack Development - Excellent Remuneration - Skillible" /></span>
                  <div className="services-intext">
                    <h3>Excellent Remuneration</h3>
                    <p>Full-stack developers in India earn about 6 LPA on average. For specialists with a lot of knowledge and expertise, it can reach up to 14 LPA. The minimum salary for a full-stack developer in India is around 3.5 LPA, according to a recent study conducted by Glassdoor.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 text-center">
                <div className="services-in">
                  <span className="trans-img">
                    <img src={require(`../assets/images/high-productivity.png`)} className="img-fluid" alt="Benefits of Full Stack Development - Higher Productivity Rate - Skillible" /></span>
                  <div className="services-intext">
                    <h3>Higher Productivity Rate</h3>
                    <p>Full Stack Developers have an advantage over other developers since they have the potential to make technical decisions more quickly and see the broader view.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 text-center">
                <div className="services-in">
                  <span className="trans-img">
                    <img src={require(`../assets/images/as-demand.png`)} className="img-fluid" alt="Benefits of Full Stack Development - Always In Demand - Skillible" /></span>
                  <div className="services-intext">
                    <h3>Always In Demand</h3>
                    <p>Full-stack engineers are always trending as they work on all three major stages of the process: display, logic, and database. Organizations desire multi-skilled personnel that can keep up with the emerging behests and fulfill a variety of tasks.
                    </p>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </section>
        <section className="section-box bg-patern why-us-full">
          <div className="container">
            <div className="row">
              <div className="hding2 colr-spanh2 text-center mt-5">
                <h2>WHY <span>US?</span> </h2>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="aboutlanguage-img">
                  <div className="greenlgu1">
                    <div className="greenlgu2">
                      <p>Job Guarranteed
                      </p>
                      <p><span>Program</span></p>
                    </div>
                  </div>
                  <div className="bluelight-lgu1">
                    <div className="bg-imgline">
                      <span className="cuntr-flagimg"><img src={require(`../assets/images/job-ps.png`)} className="img-fluid" alt="Job - Skillible" /></span>
                      <span className="line-lgb1"><img src={require(`../assets/images/linee.png`)} className="img-fluid" alt="Line - bg - Skillible" /></span>
                    </div>
                    <div className="bluelight-lgu2">
                      <p>Support</p>
                      <span className="fres-img1"><img src={require(`../assets/images/why-us-img.png`)} className="img-fluid" alt="Why Join Skillible" /></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="loveus-txet whsn">
                  <ul>
                    <li>
                      <h3>Role Readiness</h3>
                      <p><span className="icon"><i className="fas fa-angle-right"></i></span> Technical mentor support</p>
                      <p><span className="icon"><i className="fas fa-angle-right"></i></span> Customized Career Mentoring</p>
                      <p><span className="icon"><i className="fas fa-angle-right"></i></span> Free session on Resume Building</p>
                      <p><span className="icon"><i className="fas fa-angle-right"></i></span> 1:1 Mock interview session</p>
                      <p><span className="icon"><i className="fas fa-angle-right"></i></span> Free LinkedIn Profile customization</p>
                    </li>
                    <li>
                      <h3>Job Guarranteed Program</h3>
                      <p>Land a job with a minimum salary of 5 LPA or don't pay us *</p>
                    </li>
                    <li>
                      <h3>Industry Recognized Curriculum</h3>
                      <p><span className="icon"><i className="fas fa-angle-right"></i></span> Engage in Top Rated Curriculum</p>
                      <p><span className="icon"><i className="fas fa-angle-right"></i></span> Work on Real Use Case Project</p>
                      <p><span className="icon"><i className="fas fa-angle-right"></i></span> Regular Assessment to enhance your skill and profile.</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="usp">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2> Our Offerings</h2>
                <h3>Skills Covered</h3>
              </div>
            </div>
          </div>
          <div className="subUsp">
            <div className="container">
              <div className="row">
                <div className="col-sm-4">
                  <div className="uspPoint">
                    <span className="">
                      <img src={require(`../assets/images/front-end-icon.png`)} className="img-fluid" alt=" Skillible Offers - Front End Languages & Framework: Such as HTML, CSS & JavaScript
" />
                    </span>
                    <h3> Front End Languages & Framework: Such as HTML, CSS & JavaScript </h3>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="uspPoint triangle">
                    <span className="">
                      <img src={require(`../assets/images/backend-icon.png`)} className="img-fluid" alt="Skillible Offers - Backend Technologies & Frameworks" />
                    </span>
                    <h3> Backend Technologies & Frameworks </h3>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="uspPoint circle">
                    <span className="">
                      <img src={require(`../assets/images/database.png`)} className="img-fluid" alt=" Skillible Offers - Database Management System  " />
                    </span>
                    <h3> Database Management System </h3>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="uspPoint triangle grn">
                    <span className="">
                      <img src={require(`../assets/images/version-control.png`)} className="img-fluid" alt=" Skillible Offers - Version Control  " />
                    </span>
                    <h3> Version Control </h3>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="uspPoint circle ylo">
                    <span className="">
                      <img src={require(`../assets/images/hosting.png`)} className="img-fluid" alt="  Skillible Offers - Web Hosting Platforms " />
                    </span>
                    <h3> Web Hosting Platforms </h3>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="uspPoint sqblu">
                    <span className="">
                      <img src={require(`../assets/images/problem.png`)} className="img-fluid" alt=" Skillible Offers - Creativity, Analytical Skills, Problem solving skills & Time Management Skills" />
                    </span>
                    <h3> Creativity, Analytical Skills, Problem solving skills & Time Management Skills </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="orderForm keyArea">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 lft">
                <h2>Tools Covered</h2>
              </div>
              <div className="col-lg-6">
                <div className="banRt">
                  <ul>
                    <li>
                      <span className="icon">
                        <img src={require(`../assets/images/angularjs.png`)} className="img-fluid" alt="Skillible - HTML" />
                      </span>HTML
                    </li>
                    <li>
                      <span className="icon">
                        <img src={require(`../assets/images/bootStrap.png`)} className="img-fluid" alt="Skillible - BootStrap " />
                      </span>BootStrap
                    </li>
                    <li>
                      <span className="icon">
                        <img src={require(`../assets/images/javascript-1.png`)} className="img-fluid" alt="Skillible - JavaScript" />
                      </span>JavaScript
                    </li>
                    <li>
                      <span className="icon">
                        <img src={require(`../assets/images/sql.png`)} className="img-fluid" alt="Skillible - SQL" />
                      </span>SQL
                    </li>
                    <li>
                      <span className="icon">
                        <img src={require(`../assets/images/react.png`)} className="img-fluid" alt="Skillible - React" />
                      </span>React
                    </li>
                    <li>
                      <span className="icon">
                        <img src={require(`../assets/images/docker.png`)} className="img-fluid" alt="Skillible - Docker" />
                      </span>Docker
                    </li>
                    <li>
                      <span className="icon">
                        <img src={require(`../assets/images/redux.png`)} className="img-fluid" alt="Skillible - Redux" />
                      </span>Redux
                    </li>
                    <li>
                      <span className="icon">
                        <img src={require(`../assets/images/nodejs-icon-1.png`)} className="img-fluid" alt="Skillible - NodeJS" />
                      </span>NodeJS
                    </li>
                    <li>
                      <span className="icon">
                        <img src={require(`../assets/images/css.png`)} className="img-fluid" alt="Skillible - CSS" />
                      </span>CSS
                    </li>
                    <li>
                      <span className="icon">
                        <img src={require(`../assets/images/mongodb.png`)} className="img-fluid" alt="Skillible - MongoDB" />
                      </span>MongoDB
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="full-stack-page sce991 mt-6">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="hding2 colr-spanh2">
                  <h2 className="mb-2"> Key <span>Features</span></h2>
                  <p className="mb-4">Earn <strong>All in One Solution</strong> to all aspects of software development only with Skillible
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row displ-lrt1">
              <div className="col-md-6 float-end">
                <div className="inter-li1">
                  <span>
                    <img src={require(`../assets/images/businees-htd.png`)} className="img-fluid" alt="Skillible offers flexible solutions to earn while you learn " />
                  </span>
                  <div className="">
                    <h4> Flexibility to learn while you're earning
                    </h4>
                  </div>
                </div>
                <div className="inter-li1 inter-li2">
                  <span>
                    <img src={require(`../assets/images/expert-htd.png`)} className="img-fluid" alt="Skillible offers Greater placement opportunities with enhanced learning." />
                  </span>
                  <div className="">
                    <h4>Greater placement opportunity with enhanced learning.</h4>
                  </div>
                </div>
                <div className="inter-li1 inter-li3">
                  <span>
                    <img src={require(`../assets/images/record-htd.png`)} className="img-fluid" alt="Skillible offers Outcome Driven Training " />
                  </span>
                  <div className="">
                    <h4> Outcome Driven Training
                    </h4>
                  </div>
                </div>
                <div className="inter-li1 inter-li4">
                  <span>
                    <img src={require(`../assets/images/placement-htd.png`)} className="img-fluid" alt="Skillible is an immersive Learning Platform" />
                  </span>
                  <div className="">
                    <h4>An Immersive Learning Platform
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-md-6 text-center float-start">
                <span>
                  <img src={require(`../assets/images/key-feature-fullstack.png`)} className="img-fluid" alt="Skillible offers training with upskilling opportunities " />
                </span>
              </div>
            </div>
          </div>
        </section>
        <section className="section course-objective mt-4 mb-5">
          <div className="container-fluid Curriculum width90pst">
            <div className="piece-content2">
              <div className="clearfix">
                <div className="heading-text">
                  <h2> Course Curriculum </h2>
                </div>
              </div>
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">  Part 0 | Introduction  </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <ul><li> Introduction to web development </li><li> Fundamental of web </li><li> VS Code installation and introduction </li><li> GIT introduction </li></ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> Part 1 | Frontend | HTML, CSS, Bootstrap </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <ul><li> HTML: table, form, div, media para etc </li><li> CSS: colour, font, border style etc. Background, inline, external, minimise etc </li><li> Bootstrap: Nav, grid, spacing, D Flex, Media </li><li> Project: Work on a project where you have show your creativity </li></ul>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingtwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetwo" aria-expanded="false" aria-controls="collapsetwo">  Part 2 | Frontend | Javascript  </button>
                  </h2>
                  <div id="collapsetwo" className="accordion-collapse collapse" aria-labelledby="headingtwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <ul><li> Javascript: Introduction of javasript with types, conditional statement, loops, console etc.
                      </li>
                        <li> Javascript: functions, array functions, variable scopes etc
                        </li>
                        <li> DOM: Introduction, manipulation, selecter method, events
                        </li>
                        <li> ES6: Introduction, keywords, Arrow functions etc
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">  Part 3 | Frontend | React  </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <ul><li> React: Introduction of react, structure of react, state, prop. </li><li> React: stateful, stateless, class based, functional componant. Componant lifecycle</li><li> React: Server side integrattion </li><li> React: Data managment </li><li> React: Handling multiple pages, Routing, SPA </li><li>Redux: How iit work</li><li>Unit testing</li><li>Project: Implemnet the learning of React in same project which done in Html session.</li></ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfour">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapseThree">  Part 4 | Backend | Mongo DB  </button>
                  </h2>
                  <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <ul><li> SQL and NoSql Concepts</li><li> Create and Manage MongoDB </li><li> MongoDB: Fundamentals </li><li> MongoDB: Curd Operations </li><li> MongoDB: Aggregation and other advance operation with pipeline. </li><li> MongoDB: Schema Desiign </li><li> MongoDB: Replication, sharding, administration, security </li><li> Project Design the database for the given application </li></ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfive">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapseThree">  Part 5 | Backend | Node js </button>
                  </h2>
                  <div id="collapsefive" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <ul><li>DSA: Data Structures: Arrays, Strings, Stacks, Queues</li><li>DSA: Time and Memory complexity</li><li>DSA: Recursion, Sorting, Searching, Two pointers</li><li> Node js: Overview, basic, setup Utilities</li><li> Node js: Console, Command Utilities</li><li> Node js: Modules, Concepts, Events </li><li> Node js with Express js, routing</li><li> Node js: Database Access </li><li>Node js: Rest API, testing with Postman</li><li>Unit testing</li><li>Project: Add the backend application in the project.</li></ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingsix">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">  Part 6 | Backend | Microservice  </button>
                  </h2>
                  <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <ul><li> Microservice Architecture understnading </li><li> Microserivce implementtation in Nodes js </li><li> Docker understanding </li><li> Deploy microservice using docker </li><li> Project: Convert the project into microservice and deploy using docker </li></ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingseven">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven">  Part 7 | Deployment </button>
                  </h2>
                  <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="headingseven" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <ul><li> Understanding of cloud </li><li> Application Deployment on AWS </li></ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingseven">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven">   Part 8 | Capston Project  </button>
                  </h2>
                  <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="headingseven" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p>Work on the industry project in a collaborative way which cover all the concept of a fullstack developer.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingseven">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven">    Part 9 | Interview preparation   </button>
                  </h2>
                  <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="headingseven" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p>Prepare you for the industry with all the concept with one to one session, mock interview which include technical and soft skills.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  )
}
import React from 'react'
import HeaderHome from './HeaderHome'
import DataCollection from './DataCollection';
export default function Disclaimer_tns({ isModal }) {

  return (

    <React.Fragment>
      {isModal === undefined ? <>
        <HeaderHome />

        <section className="banner-home-contact contactUsBanner">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="text-banner-contact">
                  <h1>Skillible - Disclaimer</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
      </> : ""}
      <section className="conatct-section contact-formsce contact-formsce1 mt-4 mb-4">
        <div className="container">
          <div className="row">
            <div className="col-mc-12">
              <div className="bg_contactForm">
                <div className="row">
                  {isModal !== true &&
                    <div className="col-md-12 pb-4 text-start hding2">
                      <h2>Disclaimer
                      </h2>
                    </div>
                  }
                  <div className="col-md-12 skillible-privacy">
                   

<p>The content on the Skillible website <a href="http://skillible.io/"> www.skillible.io </a> is intended for general informational purposes only. While we make every effort to ensure the accuracy and timeliness of our information, we cannot guarantee its completeness, reliability, or availability.</p>



                    <p className="mb-4"> Skillible is an educational technology platform designed to enhance learning experiences. However, the effectiveness of our courses varies based on factors such as individual effort, prior knowledge, and engagement levels. We do not guarantee specific outcomes or career advancements. </p>
                    <p className="mb-4"> Additionally, the reports and insights mentioned on our website are sourced from the public domain. The data in these reports is not owned or derived from our own research. </p>
                    <p className="mb-4">  By using our website, you acknowledge that any reliance on the information provided is at your own risk. Skillible is not liable for any losses or damages that may arise from your use of this site or the information it contains.</p>
                    <p className="mb-4"> For detailed inquiries or specific information about our courses and services, please feel free to contact us directly. </p>
                    <p className="mb-4">  </p>





                    
                   

                   



                  </div>
                </div>
              </div>
            </div>
            </div>
            </div>
          </section>

        </React.Fragment>
        )
}
import React, { useState } from 'react';
import {useNavigate } from "react-router-dom";
import ApplyNowForm from './ApplyNowForm';
import Home from './Home';



export default function DefaultApplyNowForm() {
    const [modalShow, setModalShow] = useState(true);
    const navigate = useNavigate();
    const handleModalClose = () => {
        navigate('/');
        setModalShow(false);
       
      }

   return (
      <React.Fragment>
        <Home />
           <ApplyNowForm show={modalShow} hide={handleModalClose} />
      </React.Fragment>
   )
}
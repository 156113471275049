import React from "react";

const DataCollection = ({ isModal }) => {
  return (
    <>
      <div className="row" id="datacollection">
        <h2 className="skillible-trems">
          <span> Types of Information we collect </span>
        </h2>


        <div className="pp4ew1 pp4ew1W mt-4">
          <div className="pp4ew2">
            <div className="dat4sr1">
              <p>   <strong>Information you provide us - </strong> Depending on the nature of the request, we may need
                to gather specific information. We might need your cell number, email address, residential address,
                photograph, and academic background. If you apply, register, or use our services, we may obtain this
                personal information from you.</p>
            </div>
            <div className="dat4sr1">
              <p><strong> Information gathered automatically - </strong> Generally, you are not required to provide
                any personal information in order to browse our website. Automated methods may be used by us, our
                third-party service providers, or other Partners (collectively, "Partners") to gather various types
                of Information about You, your computer, or any other device used to access Our Website. The following
                is a sample, non-exhaustive list of the types of automatically collected information that may be gathered:
                network or Internet protocol address, type of browser You are using (e.g., Chrome, Safari, Firefox,
                Internet Explorer), type of operating system You are using (e.g., Microsoft Windows or Mac OS),
                mobile network, device identifiers, device settings, browser settings, web pages of the Website
                You have visited, Website visited before and afterward You visited our website. The type of
                handheld or mobile device (such as iOS or Android) used to access the Website, your geographic
                location, and the articles and adverts You have accessed, seen, forwarded, and/or clicked on.  </p>
            </div>
            <div className="dat4sr1">
              <p> <strong> Voluntary Calls - </strong> We shall retain a record of the information you willingly offer us
                by electronic mail so that we can respond to you. When you register on our site or fill out a form,
                we are the only ones to receive information about you. Additionally, you might be prompted to submit
                your name, email address, or phone number when filling out a form on our website. You may access our
                website anonymously, but if you have provided us with your personal information and contact details,
                we reserve the right to contact you by phone, SMS, email, or WhatsApp to discuss our product and
                to make an offer, even if your number is DND-enabled.</p>
            </div>

          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-2">
            <div className="text-center hdbtp1 mb-0">
              <h2 className="skillible-trems">
                <span>How We May Use Information About Users </span>
              </h2>
              <p>
                <span> By providing Us with Your User Information, you agree that We may keep Your User Information and that We or any Partners who process it on Our behalf may hold it. Your User Information may be used by us and our partners for the following purposes: </span>
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="">
              <div className="pp4ew1 pp4ew1W mt-4">
                <div className="pp4ew2">
                  <div className="dat4sr1">
                    <p> Provide feedback, keep in touch with you about it, and follow up with trainees and clients who have applied or submitted. </p>
                  </div>
                  <div className="dat4sr1">
                    <p> Respond to your enquiries, fulfil your requests for the services, and share information with you about our offerings that we think you might find interesting. </p>
                  </div>
                  <div className="dat4sr1">
                    <p> Enforce the laws governing your use of our services, including but not limited to our policies and terms of service, and/or use the information you supplied in accordance with its intended use. </p>
                  </div>
                  <div className="dat4sr1">
                    <p> The provision of technical assistance for the website or in conjunction with our offerings and services. </p>
                  </div>
                  <div className="dat4sr1">
                    <p> Prevent copyright infringement and other potentially illegal acts from occurring on or through our website or services, </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="">
              <div className="pp4ew1 pp4ew1W mt-4">
                <div className="pp4ew2">
                  <div className="dat4sr1">
                    <p> Safeguard the security of our other subscribers or users. </p>
                  </div>
                  <div className="dat4sr1">
                    <p> Conduct research on how users interact with the Services or any aspect of them, including statistical analysis of user behavior that we might share with third parties in an aggregated, depersonalized form. </p>
                  </div>
                  <div className="dat4sr1">
                    <p> To make it possible for us to fulfill any legal obligations that are placed on Us. </p>
                  </div>
                  <div className="dat4sr1">
                    <p> For the purpose of informing, you via periodic communications—which could include emails—about any new functions or services/goods. These communications from Us might advertise events that third parties are hosting on our website. </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isModal === undefined &&
          <div className="row">
            <div className="col-md-12 mt-2">
              <div className="text-center hdbtp1 mb-0">
                <h2 className="skillible-trems">
                  <span>Security</span>
                </h2>
                <p>
                  <span>To Us, security is of utmost importance. All necessary security measures are in place to safeguard the privacy, accuracy, and accessibility of your user information. To prevent unauthorized or inappropriate access to Your User Information, including your personal information, we employ strict physical, electronic, and administrative measures. </span>
                </p>
                <p>
                  <span>We utilize encryption when necessary and adhere to generally established standards for the collection, storage, and protection of personal data. We save personal information for as long as it takes to fulfil your service request and then for legal and administrative needs. These could be timeframes required by laws, contracts, or other agreements; for resolving, protecting, enforcing, or defending our legal or contractual rights; required to maintain adequate and accurate business and financial records; or for how you access, update, or otherwise interact with our systems. </span>
                </p>
                <p>
                  <span>All appropriate measures will be taken by this website to protect the privacy of personal information, uploaded content, etc., and to ensure that any information you provide won't be used improperly. This website also makes available any information or personal data you provide in support of legal proceedings. While this website will take the aforementioned reasonable precautions to prevent the misuse of any personal information you submit to it, we cannot guarantee that someone won't manage to get around our security measures, including but not limited to the security measures put in place on this Web site. In light of this, entering personal information or data on this website indicates that you accept this risk and that you are waiving your right to legal action as a result. </span>
                </p>
              </div>
            </div>
          </div>
        }
        <div className="row">
          <div className="col-md-12 mt-2">
            <div className="text-center hdbtp1 mb-0">
              <h2 className="skillible-trems">
                <span>Information Disclosure: Our Policy on User Information Sharing</span>
              </h2>
              <p>
                <span></span>
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="pp4ew1 pp4ew1W psdb psdblin1 mt-2">
              <div className="pp4ew2">
                <div className="dat4sr1">
                  <p> To the client(s) and providers or Partners that we have hired to carry out business-related tasks on our behalf, for their consideration. </p>
                </div>
                <div className="dat4sr1">
                  <p> In response to procedure, such as a court order, subpoena, a request from law enforcement or a government agency, or a similar request. </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="pp4ew1 pp4ew1W psdb psdblin1 mt-2">
              <div className="pp4ew2">
                <div className="dat4sr1">
                  <p> Together with third parties, we may investigate, prevent, or take action (in our sole discretion) about potentially illegal actions, suspected fraud, circumstances involving possible threats to any person, us, or the Website, or violations of our policies, the law, or our Terms of Use, as well as to confirm or enforce compliance with the rules regulating our website. </p>
                </div>
                <div className="dat4sr1">
                  <p> We shall not disclose or divulge user information outside of India. We will abide by all applicable laws governing data protection in India that are currently in effect. </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mt-2">
            <div className="text-center hdbtp1 head-skill mb-0">
              <h2 className="skillible-trems">
                <span>To whom do we disclose your personal information? </span>
              </h2>
              <p>
                <span>We may disclose personal information for the purposes described in this privacy policy to:   </span>
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="">
              <div className="pp4ew1 pp4ew1W psdb psdblin1 pric mt-4">
                <div className="pp4ew2">
                  <div className="dat4sr1">
                    <p>
                      Our employees and related bodies corporate;
                    </p>
                  </div>
                  <div className="dat4sr1">
                    <p>
                      Professional advisers, dealers, and agents;
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="">
              <div className="pp4ew1 pp4ew1W psdb psdblin1 pric mt-4">
                <div className="pp4ew2">
                  <div className="dat4sr1">
                    <p>
                      Payment systems operators (e.g., merchants receiving card payments);
                    </p>
                  </div>
                  <div className="dat4sr1">
                    <p>
                      Our existing or potential agents, business partners, or partners;
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="">
              <div className="pp4ew1 pp4ew1W psdb psdblin1 pric">
                <div className="pp4ew2">
                  <div className="dat4sr1">

                    <p>
                      Our sponsors or promoters of any competition that we conduct via our services;
                    </p>
                  </div>
                  <div className="dat4sr1">
                    <p>
                      Other persons, including government agencies, regulatory bodies, and law enforcement agencies, or as required, authorized or permitted by law.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="">
              <div className="pp4ew1 pp4ew1W psdb psdblin1 pric">
                <div className="pp4ew2">
                  <div className="dat4sr1">

                    <p>
                      Specific third parties authorized by you to receive information held by us; and/or
                    </p>
                  </div>
                  <div className="dat4sr1">

                    <p>
                      Anyone to whom our assets or businesses (or any part of them) are transferred;
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="col-md-6">
            <div className="">
              <div className="pp4ew1 pp4ew1W psdb psdblin1 pric ">
                <div className="pp4ew2">
                  <div className="dat4sr1">
                    <p>
                      Third-party suppliers, service providers, and users (including providers for the operation of our websites and/or our business or in connection with providing our products and services to you);
                    </p>

                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </>
  );
}

export default DataCollection;
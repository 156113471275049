import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import HeaderHome from './HeaderHome';
export default function cybersecurity() {
 
return (
<React.Fragment>
   <HeaderHome />
   <HelmetProvider>
      <Helmet>
         <meta charset="UTF-8" />
         <title>Report</title>
         <meta name="description" content="An Initiative by Skillible to Upskill Women in India.
            Skillible & salesforce unites to bring more women back to the workforce." />
         <link rel="canonical" href="http://skillible.io/" />
         <meta property="og:title" content="Skillible - We Help You Succeed In Your Career!" />
         <meta property="og:description" content="An Initiative by Skillible to Upskill Women in India.
            Skillible & salesforce unites to bring more women back to the workforce." />
         <meta property="og:url" content="http://skillible.io/" />
         <meta property="og:type" content="website" />
         <meta property="og:locale" content="en_US" />
         <meta property="og:site_name" content="Skillible" />
      </Helmet>
   </HelmetProvider>
   <div>
   <section className="banner-home position-relative home-slider-skil cyber-security-banner">
               <div className="" >
                  <div className="item">
                     <div className="container">
                        <div className="row">
                           <div className="col-md-12 text-center">
                              <div className="text-banner">
                                 {/* <img alt="" src={require(`../assets/images/partner-logo.png`)} /> */}
                                 <h2> Are you ready to outsmart hackers? </h2>
                                 <h4> <span> Master Cyber Security: </span> Safeguard the Future of Technology</h4>
                                  
                                 <p>
                                 Comprehensive training to equip you with the skills to detect, prevent, and respond to cyber threats.
                                 </p>
                                 <h5> <span>Class Based -</span> Live Masterclasses   | <span>Duration- </span>  6-12 Weeks  </h5>
                                 {/* <button>
                                    <Link to="#" onClick={handleModalShow} >
                                       Enroll Now </Link>
                                 </button> */}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="sec-genai-enroll mb-4">
               <div className="container">
                  <div className="row ltr-rtln1">
                     <div className="col-md-6">
                        <div className="text-right enroll-genai-head">
                           <span><span ><span ><img alt="" aria-hidden="true" src="" /></span>
                              <img alt="" src={require(`../assets/images/home-revamp/learn.png`)} /></span></span>
                          
                        </div>
                     </div>
                     <div className="col-md-6">
                        <div className="text-left resimeExListbg mt-4">
                                
                        {/* <div className="women-tech-sn">
                           
                           <h3> What You  <span class="aictc-partner"> Will Learn</span></h3>               
                           <div className="key-highlights">
                              <ul className="">
                                 <li> Understand network vulnerabilities and cyber threat landscape  </li>
                                 <li> Master encryption techniques and secure data practices  </li>
                                 <li> Learn to perform risk assessment and implement security controls </li>
                                 <li> Conduct ethical hacking and penetration testing  </li>
                                 <li> Develop strategies for incident response and digital forensics </li>
                                 <li> Stay compliant with legal and regulatory standards</li>
                              </ul>
                           </div>
                        </div> */}


                        <div className="languapair-steps step1 hading-new-2">
                           <h2> What You Will Learn</h2>
<p>This course will equip you with <span className="deplyo-one-skil" > essential cybersecurity skills </span> to protect digital systems and data. You’ll learn how to
    develop security strategies, handle cyber incidents, and secure cloud environments. Gain hands-on experience in risk
     management, compliance, and the role of AI in cybersecurity. Prepare to design robust disaster recovery and business 
     continuity plans for critical systems.</p>



                           
                           <ul>
                              <li>
                                 <span className="icon"><i className="fas fa-check"></i></span>
                                 <div className="">Understand network vulnerabilities and cyber threat landscape
                                 </div>
                              </li>
                              <li>
                                 <span className="icon"><i className="fas fa-check"></i></span>
                                 <div className="">Master encryption techniques and secure data practices</div>
                              </li>
                              <li>
                                 <span className="icon"><i className="fas fa-check"></i></span>
                                 <div className="">Learn to perform risk assessment and implement security controls </div>
                              </li>
                              <li>
                                 <span className="icon"><i className="fas fa-check"></i></span>
                                 <div className="">Conduct ethical hacking and penetration testing </div>
                              </li>
                              <li>
                                 <span className="icon"><i className="fas fa-check"></i></span>
                                 <div className="">Develop strategies for incident response and digital forensics
                                 </div>
                              </li>
                              <li>
                                 <span className="icon"><i className="fas fa-check"></i></span>
                                 <div className="">Stay compliant with legal and regulatory standards
                                 </div>
                              </li>
                           </ul>
                         
                        </div>

                                
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="course-nutshell position-relative">
            <img className="nutshel-banner" alt="" src={require(`../assets/images/banner/nutshel-bg.png`)} />
               <div className="container">
                  <div className="row">
                     <div className="col-md-12 text-center genai-heading ">
                        <h2>
                        Experience Our Unmatched Cybersecurity Course in a Nutshell
                        </h2>
                     </div>
                     <div className="col-md-12 col-lg-5">
                        <div className="genai-circulm">
                           <div className="gen-circulm2 gen-circulm">
                              <div className="genai-img"> <span >
                                 <img alt="" src={require(`../assets/images/icon01.png`)} /></span>
                              </div>
                              <span className="genNum">1</span>
                              <div className="gentex">Flexible Learning Paths & Bilingual Support</div>
                           </div>
                           <div className="gen-circulm2 gen-tx">
                              <div className="genai-img"><span >
                                 <img alt="" src={require(`../assets/images/icon3_1.png`)} /></span>
                              </div>
                              <span className="genNum">2</span>
                              <div className="gentex">Real-Time Industry Projects </div>
                           </div>
                           <div className="gen-circulm2 gen-tx">
                              <div className="genai-img"><span >
                                 <img alt="" src={require(`../assets/images/icon1.png`)} /></span>
                              </div>
                              <span className="genNum">3</span>
                              <div className="gentex">Interactive Masterclassess</div>
                           </div>
                           <div className="gen-circulm2 gen-circulm4">
                              <div className="genai-img"><span >
                                 <img alt="" src={require(`../assets/images/icon4.png`)} /></span>
                              </div>
                              <span className="genNum">4</span>
                              <div className="gentex"> Virtual Labs & AI Tools</div>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-12 col-lg-7 text-right">
                        <div className="thikstpbg">
                           <div className="thikstp thikstp1">
                              <div className="th-Text">
                                 <div className="th-Text1"><span>60-120 </span> Hours of learning  <span className="img">
                                    <img src={require(`../assets/images/48.png`)} className="img-fluid" alt="" /></span>
                                 </div>
                              </div>
                           </div>
                           <div className="thikstp thikstp2">
                              <div className="th-Text">
                                 <div className="th-Text1"> AI-Enabled                                 </div>
                                 <div className="th-Text1"> Resume Building </div>
                              </div>
                               <div className="thikstpimg"><span>
                                  <img src={require(`../assets/images/available.png`)} className="img-fluid" alt="" /> 
                                 </span>
                              </div> 
                           </div>
                           <div className="thikstp thikstp4">
                              <div className="th-Text1"><span>Explore - </span>   Cybersecurity Tools <span>
                                 <img src={require(`../assets/images/circlprstg.png`)} className="img-fluid" alt="" /></span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>





            <section class="expert-linguists expertise-ws servs-edt mt-4 certificate-me">
         <div class="container">
            <div class="row">

          


            <div class="col-md-12 col-lg-12 mt-4">
 <div className='text-center hading-new-2'><h2>Certificate Preview:  What You’ll Earn 
                     <span class="deplyo-one-skil"> </span> </h2>
                     
</div>
               
                  <div class="certificate-genai">
                     <img  src={require(`../assets/images/home-revamp/certificate-cyber.jpg`)} 
                     class="img-fluid" alt="area-expertise-service"/>
                  </div>
               </div>


              
               
            </div>
         </div>
      </section>


      
      <section class="expert-linguists expertise-ws servs-edt mt-4 lms-banner-bg">
         <div class="container-fluid">
            <div class="row">


            <div class="col-md-12 col-lg-7">
                  <div class="rightside-all-inoneimg">
                     <img  src={require(`../assets/images/home-revamp/lms.png`)} 
                     class="img-fluid" alt="area-expertise-service"/>
                  </div>
               </div>


               <div class="col-md-12 col-lg-5">
                  <div class="ourfamliy-text hading-new-2">
                     <h2>Learn at your pace with our State-of-the-Art Learning Portal (LMS) </h2>
                     
                     <p class="expertise-para">We prioritize open communication with our learners, engaging them through emails, announcements, learner forums, and social media.  </p>

                     
                  </div>
               </div>
               
            </div>
         </div>
      </section>







            <section className="services-industry genai-heading-2">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12 text-center hding2 b-to-a-course hading-new-2">
                        <h2>A Skill-Focused  Curriculum</h2>
                        <p>Our curriculum is more than just theory; it’s designed with input from top industry professionals to ensure real-world relevance. Focused on both foundational principles and cutting-edge practices, this course immerses you in the essential world of Cybersecurity. We understand the growing demand for skilled professionals in protecting digital landscapes, and our modules are carefully crafted to equip you with hands-on expertise. </p>
                        <p> By mastering key areas like <strong> Incident Response, Cloud Security, </strong>   and <strong> Ethical Hacking, </strong>    you will become a vital asset in safeguarding systems, data, and infrastructure, meeting the urgent needs of employers today.</p>
                     </div>
                  </div>
               </div>
            </section>
            <section className="section course-objective">
               <div className="container course mt-2">
                  <div className="piece-content2">
                     <div className="clearfix">
                        <div className="heading-text">
                           <h2> Our Curriculum covers the most In-Demand Topics :</h2>
                        </div>
                     </div>
                     <h5><span class=""> Foundational Level </span></h5>
                     <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingOne">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"><strong> Module 1: </strong> Introduction to Cybersecurity

                              </button>
                           </h2>
                           <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div className="accordion-body"> Understand the core risks and threats in cybersecurity, the CIA Triad, and how organizations implement security through ISMS standards and strategies. </div>
                           </div>
                        </div>

                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingTwo">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"><strong> Module 2: </strong>   Cybersecurity Laws & Compliance (India)
                              </button>
                           </h2>
                           <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                              <div className="accordion-body"> Explore India’s cybersecurity landscape, including the IT Act, 2000, CERT-In, and key amendments, while learning basic compliance requirements. </div>
                           </div>
                        </div>
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingThree">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> <strong> Module 3: </strong>  Security Strategy & IT Infrastructure (Part 1)

                              </button>
                           </h2>
                           <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                              <div className="accordion-body"> Learn how to develop cybersecurity strategies, create security policies, and implement awareness and training programs for organizations.</div>
                           </div>
                        </div>
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingfour">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapseThree"><strong> Module 4: </strong> Introduction to Incident Response

                              </button>
                           </h2>
                           <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                              <div className="accordion-body"> Gain practical knowledge on responding to cyber incidents like malware and phishing, and learn the dos and don’ts of incident management. </div>
                           </div>
                        </div>
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingfive">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive"><strong> Module 5: </strong>  Cloud Security Fundamentals
                              </button>
                           </h2>
                           <div id="collapsefive" className="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#accordionExample">
                              <div className="accordion-body"> Understand the essentials of securing cloud environments, including deployment models and best practices for cloud security.  </div>
                           </div>
                        </div>
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingsix">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix"><strong>Module 6: </strong>  Basic Ethical Responsibilities in Cybersecurity

                              </button>
                           </h2>
                           <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#accordionExample">
                              <div className="accordion-body"> Learn the legal and ethical responsibilities in cybersecurity and the role it plays in protecting organizational integrity. </div>
                           </div>
                        </div> 
                     </div>









                     <h5 className='mt-4'><span class=""> Advanced Level </span></h5>
                     <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingOne">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"><strong> Module 1: </strong> Advanced Cybersecurity Strategies

                              </button>
                           </h2>
                           <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div className="accordion-body"> Build on the CIA Triad with advanced strategies for tackling emerging threats, developing cybersecurity resilience, and applying ISMS standards. </div>
                           </div>
                        </div>

                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingTwo">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"><strong> Module 2: </strong>   Cybersecurity Laws & Compliance (Advanced)

                              </button>
                           </h2>
                           <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                              <div className="accordion-body">Deep dive into the National Cybersecurity Policy, regulatory frameworks in financial and telecom sectors, and compliance best practices. </div>
                           </div>
                        </div>
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingThree">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> <strong> Module 3: </strong>  Security Strategy & IT Infrastructure (Part 2)

                              </button>
                           </h2>
                           <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                              <div className="accordion-body"> Explore advanced risk assessment techniques, cybersecurity controls, and ethical hacking principles to safeguard organizational infrastructure. </div>
                           </div>
                        </div>
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingfour">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapseThree"><strong> Module 4: </strong> Incident Response & Crisis Management

                              </button>
                           </h2>
                           <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                              <div className="accordion-body"> Master the Incident Response lifecycle, handling scenarios like ransomware, phishing, and understanding crisis communication in cybersecurity.</div>
                           </div>
                        </div>
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingfive">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive"><strong> Module 5: </strong>  Business Continuity & Disaster Recovery (BCDR)

                              </button>
                           </h2>
                           <div id="collapsefive" className="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#accordionExample">
                              <div className="accordion-body">Learn how to plan and execute BCDR strategies, focusing on disaster recovery classification and continuity in critical sectors. </div>
                           </div>
                        </div>
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingsix">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix"><strong>Module 6: </strong>  AI in Cybersecurity (Part 1)

                              </button>
                           </h2>
                           <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#accordionExample">
                              <div className="accordion-body"> Apply machine learning and deep learning algorithms for real-time threat detection and mitigation in cybersecurity. </div>
                           </div>
                        </div> 

                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingseven">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven"><strong>Module 7: </strong>  Emerging Technologies and Cybersecurity


                              </button>
                           </h2>
                           <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="headingseven" data-bs-parent="#accordionExample">
                              <div className="accordion-body"> Examine the impact of emerging technologies such as IoT, AI, and blockchain on cybersecurity and learn to adapt to evolving threats. </div>
                           </div>
                        </div> 


                     </div>





                     <h5 className='mt-4'> <span class="">Advanced Pro Level</span> </h5>
                     <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingOne">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"><strong> Module 1: </strong>Advanced Cybersecurity Strategy


                              </button>
                           </h2>
                           <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div className="accordion-body">Develop enterprise-level strategies for cybersecurity governance, focusing on advanced ISMS standards and compliance frameworks for multinational organizations. </div>
                           </div>
                        </div>

                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingTwo">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"><strong> Module 2: </strong>   Advanced Regulations & Compliance (Global Focus)


                              </button>
                           </h2>
                           <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                              <div className="accordion-body">Study global data protection laws (GDPR, HIPAA), the Digital India Act, and strategies for complying with international standards like KYC and NCIIPC. </div>
                           </div>
                        </div>
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingThree">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> <strong> Module 3: </strong>  Advanced Security & Risk Management


                              </button>
                           </h2>
                           <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                              <div className="accordion-body"> Implement advanced risk management models, including zero-trust architecture and case studies on ransomware and critical infrastructure protection. </div>
                           </div>
                        </div>
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingfour">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapseThree"><strong> Module 4: </strong> Advanced Incident Response & Automation


                              </button>
                           </h2>
                           <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                              <div className="accordion-body"> Learn automated incident response strategies powered by AI, and apply AI for vulnerability assessments and penetration testing.</div>
                           </div>
                        </div>
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingfive">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive"><strong> Module 5: </strong> Generative AI in Cybersecurity

                              </button>
                           </h2>
                           <div id="collapsefive" className="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#accordionExample">
                              <div className="accordion-body">Explore the future role of Generative AI in cybersecurity, from advanced threat detection to its impact on evolving cyber threats.</div>
                           </div>
                        </div>
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingsix">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix"><strong>Module 6: </strong>  Cybersecurity Team Leadership


                              </button>
                           </h2>
                           <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#accordionExample">
                              <div className="accordion-body"> Develop leadership strategies for managing high-performance cybersecurity teams, overcoming operational challenges, and mentoring professionals. </div>
                           </div>
                        </div> 

                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingseven">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven"><strong>Module 7: </strong>  Supply Chain Cybersecurity



                              </button>
                           </h2>
                           <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="headingseven" data-bs-parent="#accordionExample">
                              <div className="accordion-body"> Focus on advanced cybersecurity measures for protecting global supply chains, studying case studies of supply chain attacks and risk management models. </div>
                           </div>
                        </div> 









                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingeight">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeight" aria-expanded="false" aria-controls="collapseeight"><strong>Module 8: </strong>  Future of Cybersecurity & Ethical Responsibilities

                              </button>
                           </h2>
                           <div id="collapseeight" className="accordion-collapse collapse" aria-labelledby="headingeight" data-bs-parent="#accordionExample">
                              <div className="accordion-body"> Explore the ethical challenges in the future of cybersecurity, and learn to build long-term cybersecurity capabilities in an ever-changing landscape. </div>
                           </div>
                        </div> 


                     </div>






                  </div>
               </div>
            </section>
          
            <section className="faqScen faqScenbg mb-0 pt-5 pb-5">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12 hading-new-2">
                        <h2 className="mb-4 mt-4"> Frequently Asked Questions </h2>
                     </div>
                     <div className="bg_tabsfaq1">
                        <div className="row">
                           <div className="col-md-3">
                               <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                 <button className="nav-link active mt-0" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                     <span className="imgs">
                                       <img src={require(`../assets/images/a1_black.png`)} className="img-fluid img1" alt="Skillible- FAQ- General Questions" />
                                       <img src={require(`../assets/images/a1_white.png`)} className="img-fluid img2" alt="Skillible- FAQ- General Questions" />
                                    </span> 
                                    <span>General Questions</span>
                                 </button>
                                 <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                     <span className="imgs">
                                       <img src={require(`../assets/images/a2_black.png`)} className="img-fluid img1" alt="Skillible- FAQ- Fees & Income" />
                                       <img src={require(`../assets/images/a2_white.png`)} className="img-fluid img2" alt="Skillible- FAQ- Fees & Income" />
                                    </span> 
                                    <span> Fees & Income </span>
                                 </button>
                                 <button className="nav-link mb-0" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                     <span className="imgs">
                                       <img src={require(`../assets/images/a3_black.png`)} className="img-fluid img1" alt="Skillible- Admission Queries" />
                                       <img src={require(`../assets/images/a3_white.png`)} className="img-fluid img2" alt="Skillible- Admission Queries" />
                                    </span> 
                                    <span>Admission Queries </span>
                                 </button>
                              </div> 
                           </div>
                           <div className="col-md-9">
                              <div className="tab-content general_questions" id="v-pills-tabContent">
                                 <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                    <div className="general-s">
                                       <h3>General Questions</h3>
                                       <div className="">
                                          <div className="accordion" id="accordionExample">
                                             <div className="accordion-item mb-4" >
                                                <h2 className="accordion-header" id="heading91">
                                                   <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse91" aria-expanded="true" aria-controls="collapse91"> How long does the course last?   </button>
                                                </h2>
                                                <div id="collapse91" className="accordion-collapse collapse show" aria-labelledby="heading91" data-bs-parent="#accordionExample">
                                                   <div className="accordion-body">
                                                      <p> The course lasts a maximum of 6 weeks (about 1 and a half months).
                                                      </p>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="accordion-item">
                                                <h2 className="accordion-header" id="heading93">
                                                   <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse93" aria-expanded="false" aria-controls="collapse93"> Is this course available for offline participation?   </button>
                                                </h2>
                                                <div id="collapse93" className="accordion-collapse collapse" aria-labelledby="heading93" data-bs-parent="#accordionExample">
                                                   <div className="accordion-body">
                                                      <p> No, this course is exclusively online.  </p>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                    <div className="general-s">
                                       <h3> Fees & Income  </h3>
                                       <div className="">
                                          <div className="accordion" id="accordionExample">
                                             <div className="accordion-item">
                                                <h2 className="accordion-header" id="heading1">
                                                   <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1"> Does this course have a fee?  </button>
                                                </h2>
                                                <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample">
                                                   <div className="accordion-body">
                                                      <p>
                                                         No, there are no enrollment fees for this course.
                                                      </p>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                    <div className="general-s">
                                       <h3>Application Questions </h3>
                                       <div className="">
                                          <div className="accordion" id="accordionExample">
                                             <div className="accordion-item mb-4">
                                                <h2 className="accordion-header" id="heading1">
                                                   <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">  Is this course available for working professionals?  </button>
                                                </h2>
                                                <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample">
                                                   <div className="accordion-body">
                                                      <p>While our current focus is on colleges, we're open to considering working professionals for our course. There are currently no restrictions in place, and we're exploring opportunities to accommodate a broader audience. Feel free to reach out if you're interested! </p>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="accordion-item">
                                                <h2 className="accordion-header" id="heading2">
                                                   <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">  Is this course offered on a full-time or part-time basis?   </button>
                                                </h2>
                                                <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionExample">
                                                   <div className="accordion-body">
                                                      <ul>
                                                         <li>This course is offered on a full-time basis. </li>
                                                      </ul>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
   </div>
</React.Fragment>
)
}
import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HeaderHome from './HeaderHome';
export default function SheTrails() {

  return (
    <React.Fragment>
      <HeaderHome />
      <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <title>SHE Trails - Sassy. Headstrong. Empowered.</title>
          <meta name="description" content="An Initiative by Skillible to Upskill Women in India.
Skillible & salesforce unites to bring more women back to the workforce." />
          <link rel="canonical" href="http://skillible.io/" />
          <meta property="og:title" content="Skillible - We Help You Succeed In Your Career!" />
          <meta property="og:description" content="An Initiative by Skillible to Upskill Women in India.
Skillible & salesforce unites to bring more women back to the workforce." />
          <meta property="og:url" content="http://skillible.io/" />
          <meta property="og:type" content="website" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:site_name" content="Skillible" />

        </Helmet>
      </HelmetProvider>
      <div>
        <section className="industries-banner she-leads">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="text-banner she-leads-s">
                  <h1 className="she-leads-head"> SHE </h1>
                  <h1 className="Steadily">  SASSY  || HEADSTRONG  || EMPOWERED  </h1>
                  <h1 className="Steadily">
                    <span> An Initiative by Skillible to Upskill Indian Women. </span>
                  </h1>
                  <p className="banner-para3 she-para">Skillible & Salesforce unites to bring more women back to the workforce </p>
                </div>
              </div>
              <div className="col-md-6 text-center">
                <div className="img-banner industries-img1">
                  <div className="industries-bannerimg tech-scaller-w">
                    <span className="">
                      <img src={require(`../assets/images/she-lead-banner.png`)} className="img-fluid" alt="SHE Trails with skillible- A Skillible & Salesforce initiative " />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section bg_colr1 pt-4 pb-4">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <span>
                  <img src={require(`../assets/images/she-leads-reskill.png`)} className="img-fluid" alt="Skillible is the future of work" height="384" width="570" />
                </span>
              </div>
              <div className="col-md-6">
                <div className="Text_title text_tb_center pb-3 reskilled-hg">
                  <h2>Reskilled women is future of work </h2>
                  <p> IMF projected that 11% of jobs held by women are at risk of elimination due to digital technology, compared to 9 percent of their male counterparts. So, roughly estimated, around 26 million women’s jobs in 30 countries are at high risk of being displaced by technology within the next 20 years. </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section bg_colr2 pt-4 pb-4">
          <div className="container">
            <div className="row clearfix1">
              <div className="col-md-6 float-left">
                <div className="Text_title text_tb_center2  reskilled-hg">
                  <h5 className="small-text-s pb-2"> The solution lies in </h5>
                  <h2>
                    <span className="d_b">Upgrading, Reskilling, and Upskilling </span>
                  </h2>
                  <p> Reskilling is an imperative call to usher consequential changes in the struggle for securing gender diversity.

                    Not only for individuals but also for businesses looking to hire skilled female resources or swap those who have left.

                  </p>
                  <p>“Upskilling will enable every female employee to move out of the non-verse bracket” </p>
                </div>
              </div>
              <div className="col-md-6 text-center float-right">
                <span>
                  <img height="422" width="524" src={require(`../assets/images/she-leads2.png`)} className="img-fluid" alt="Upskill and Reskill with Skillible   " />
                </span>
              </div>
            </div>
          </div>
        </section>
        <section className="she-equipped-wor">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="hding2 colr-spanh2">
                  <h2>Skillible <span>is Equipped to Eradicate </span>All your Worries </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="skillible-upskill-uy" style={{ background: '#6612f0' }}>
            <div className="accordion-she">
              <div className="tab-she she1 active" data-color="#6612f0">
                <img src={require(`../assets/images/lack-of-upskilling.png`)} alt="Resolve your worries and upskill with Skillible" />
                <div className="caption">
                  <h2>Lack of upskilling programs</h2>
                  <p className="para-isx">If you are worried to find that one destination where you can find reliable upskilling programs then you can rest your faith in us. Skillible has not just created programs for working women but even for those who had to give up on their career and come across inevitable breaks. </p>
                </div>
              </div>
              <div className="tab-she she2" data-color="#2f154c">
                <img src={require(`../assets/images/lack-Underrepresentation.png`)} alt="Fill your confidence gap with Skillible" />
                <div className="caption">
                  <h2>Confidence Gap</h2>
                  <p>We know how it feels, a lack of confidence can cast a devastating impact not just on your competency level but on your career graph as well. A great way to narrow down this gap is to invest in our diversified courses to uplift your skill sets.</p>
                </div>
              </div>
              <div className="tab-she she3" data-color="#04439f">
                <img src={require(`../assets/images/lack-mentor.png`)} alt="Learn with Salesforce Mentors" />
                <div className="caption">
                  <h2>Lack of a mentor</h2>
                  <p>
                    Mentors are essential because they can see where we can improve and where we cannot. Skillible has a team of professionally trained and experienced mentors who will create your personalized road map to success.

                  </p>
                </div>
              </div>
              <div className="tab-she she4" data-color="#29a0f9">
                <img src={require(`../assets/images/confidence-gap.png`)} alt="Equal representation of women with Skillible" />
                <div className="caption">
                  <h2>Underrepresentation of women in Tech</h2>
                  <p>Women are not just underrepresented but underpaid as well. To overcome this shortcoming, you need to expend
                    in professional courses. Skillible can reverse this trend! </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section section1 step_change">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="heading_black mb-5">
                  <h5>Why Learn Salesforce? <br />
                    <span></span>
                  </h5>
                </div>
              </div>
              <div className="tn_assignment">
                <div className="mobilespecific">

                  <div className="text-center Submit_Assinment2">
                    <ul>
                      <li>
                        <span>
                          <img src={require(`../assets/images/opportunity1.png`)} className="img-fluid" alt="Advance your career in Salesforce with Skillible" />
                        </span>
                      </li>
                      <li>
                        <h3>An opportunity to advance in your career </h3>
                      </li>
                      <li className="number">3</li>
                    </ul>
                  </div>
                  <div className="text-center Submit_Assinment2">
                    <ul>
                      <li>
                        <span>
                          <img src={require(`../assets/images/pay-iu.png`)} className="img-fluid" alt="Earn more with Skillible and Salesforce" />
                        </span>
                      </li>
                      <li>
                        <h3>Acquire higher earning potential </h3>
                      </li>
                      <li className="number">4</li>
                    </ul>
                  </div>
                  <div className="text-center Submit_Assinment2">
                    <ul>
                      <li>
                        <span>
                          <img src={require(`../assets/images/job.png`)} className="img-fluid" alt="Get closer to your dream job with Skillible" />
                        </span>
                      </li>
                      <li>
                        <h3>Fetch your dream job </h3>
                      </li>
                      <li className="number">1</li>
                    </ul>
                  </div>
                  <div className="text-center Submit_Assinment2">
                    <ul>
                      <li>
                        <span>
                          <img src={require(`../assets/images/industry.png`)} className="img-fluid" alt="Learn Salesforce to work in your favourite domain" />
                        </span>
                      </li>
                      <li>
                        <h3> Opens up your chances to work in any sector </h3>
                      </li>
                      <li className="number">2</li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="opi-services-sec opi-services-sec2 sec4 sce991">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 opi-services opi-services2 text-end">
                <div className="opi-services-img">
                  <img src={require(`../assets/images/women-workforce-immunity.png`)} className="img-fluid opi-comm-img" alt="Every Girl Can Code with Skillible" />
                  <span>
                    <img src={require(`../assets/images/skillible-odds.png`)} alt="Every Girl Can Code " />
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="opi-servicesText hding2">
                  <h2>Skillible is set to raise the women's workforce immunity against all odds </h2>
                  <ul className="">
                    <li>
                      <div className="">Reskilling with Skillible can flip the odds, we offer significant opportunities to women,
                        especially in STEM role which are highly in demand in today’s time.
                        We believe that promoting upskilling without gender equity lens will continue to leave women behind their counterparts. </div>
                    </li>
                    <li>
                      <div className="">Skillible ensures a conclusive environment for all its learners by investing more in diverse and inclusive programs. Not just programs that uplift your career but an experience that guarantees you a robust and skillful output. </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="interpretr-sec1 sce991 mt-6">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="hding2 colr-spanh2">
                  <h2> Key <span>Benefits </span>Of Enrolling With Us </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row displ-lrt1">
              <div className="col-md-6 float-end">
                <div className="inter-li1">
                  <span>
                    <img src={require(`../assets/images/no-cost-earning.png`)} className="img-fluid" alt="No Cost learning opportunity - Skillible" />
                  </span>
                  <div className="">
                    <h4>No Cost learning opportunity </h4>
                  </div>
                </div>
                <div className="inter-li1 inter-li2">
                  <span>
                    <img src={require(`../assets/images/placement-icon.png`)} className="img-fluid" alt="Placement opportunities - Skillible" />
                  </span>
                  <div className="">
                    <h4>Placement opportunities </h4>
                  </div>
                </div>
                <div className="inter-li1 inter-li3">
                  <span>
                    <img src={require(`../assets/images/emi-o.png`)} className="img-fluid" alt="Flexible EMI Options: 6 to 36 Months! - Skillible" />
                  </span>
                  <div className="">
                    <h4>Flexible EMI Options: 6 to 36 Months!
                    </h4>
                  </div>
                </div>
                <div className="inter-li1 inter-li4">
                  <span>
                    <img src={require(`../assets/images/classroom.png`)} className="img-fluid" alt=" Instructor led classroom - Skillible" />
                  </span>
                  <div className="">
                    <h4>Instructor led classroom </h4>
                  </div>
                </div>
              </div>
              <div className="col-md-6 text-center float-start">
                <span>
                  <img src={require(`../assets/images/key-udb.png`)} className="img-fluid" alt="Key Benefits Of Enrolling With Skillible
" />
                </span>
              </div>
            </div>
          </div>
        </section>
        <section className="Document-linguists servs-edt">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="trans-serv-mandarin duvmt-text">
                  <h2>Career Reboot Program By <span>Skillible </span>
                  </h2>
                  <p className="expertise-para">An exclusive program by Skillible to help women come back into the workforce. Skillible aims to create an empowering tech ecosystem for women
                    to work, grow and sustain in their careers. </p>
                  <div className="services-expertise trans-list-28">
                    <p>HOW DO WE HELP ? </p>
                    <ul className="">
                      <li> Special programs leading the way for women in Technology. </li>
                      <li> Mentorship and upskilling opportunities for women. </li>
                      <li> Scholarship opportunities </li>
                      <li> Useful insights from Top IT leaders </li>
                      <li> Gain hands-on experience by working on <strong> Real-Time use cases.</strong>  </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 pe-0">
                <div className="dumnt-bgdottbg">
                  <div className="dumnt-bgdott">
                    <span className="bg-clr-b1"></span>
                    <img src={require(`../assets/images/career-reboot.png`)} className="img-fluid" alt="Kuhoo technologies is Skillible's Lending Partner" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section bg_belu1 pt-4 pb-4 she-program-w">
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-center bg_images1">
                <span>
                  <img src={require(`../assets/images/placement-9665.png`)} className="img-fluid" alt="Women In Tech Program By Skillible" />
                </span>
              </div>
              <div className="col-md-6">
                <div className="Text_title text_tb_center">
                  <h2>Revitalize your career with <span> Women In Tech </span>  </h2>
                  <p className="m-0"> We often lack that motivation that is needed to kick start our careers into high gear. Women in Tech is a career enhancer program, it is an effort to uplift your existing career graph. This is a program that will give you an opportunity to fetch those lucrative salary hikes which you have been kept away, from in the name of technical inadequacy. </p>
                  <div className="women-tech-sn">
                    <p>THIS PROGRAM IS INTENDED TO</p>
                    <ul className="">
                      <li>This program is exclusively available for working women.</li>
                      <li>Basic knowledge of programming skills is required.</li>
                      <li>Minimum 2 years of working experience is required. </li>
                      <li>This program is exclusively available for working women.</li>
                    </ul>
                  </div>
                  <div className="btn1"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section bg_green1  pt-4 pb-4">
          <div className="container">
            <div className="row clearfix1">
              <div className="col-md-6 float-left">
                <div className="Text_title text_tb_center">
                  <h2>Reclaim your career with <span> Career Vriddhi</span>
                  </h2>
                  <p className="m-0">As women, we often compromise with our careers and opt for inevitable career breaks. Career Vriddhi is an exclusive program to help women return to the workforce after a prolonged career break. </p>
                  <p className="m-0"> Women on a career break often come across situations where they find their area of expertise has changed a lot while they were away from work. Without upskilling/reskilling they can never think of moving back into the game.  </p>
                  <div className="women-tech-sn">
                    <p>THIS PROGRAM IS INTENDED TO </p>
                    <ul className="">
                      <li>This program is exclusively available to women on a career break. </li>
                      <li>No prior knowledge of the IT field is required. </li>
                      <li>Career break should not be more than two years. </li>
                      <li>Basic knowledge of programming skills is required.</li>
                    </ul>
                  </div>
                  <div className="btn1"></div>
                </div>
              </div>
              <div className="col-md-6 text-center float-right">
                <span>
                  <img src={require(`../assets/images/mask-group-9667.png`)} className="img-fluid" alt="Women on break can reclaim their career with Skillible" />
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>

    </React.Fragment>

  )
}
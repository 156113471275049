import React, { useState } from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { SERVICE_URL } from '../constant';
import { Link } from 'react-router-dom';
import HeaderHome from './HeaderHome';
import axios from 'axios';

export default function HireFromUs() {
   const [inputs, setInputs] = useState({
      fullName: '',
      email: '',
      designation: '',
      company: '',
      mobile: '',
   });
   const [success, setSuccess] = useState(false);
   const [error, setError] = useState(false);
   const [errorMsg, setErrorMsg] = useState("");
   const [validations, setValidations] = React.useState({
      fullName: '',
      email: '',
      designation: '',
      company: '',
      mobile: '',
      errorMessage: ''
   })

   const handleChange = (event) => {
      const { name, value } = event.target;
      setInputs(values => ({ ...values, [name]: value }));
   }

   const handleFieldValidation = (field, errorMessage) => {
      if (!inputs[field]) {
         setValidations(prevState => ({
            ...prevState,
            [field]: errorMessage
         }));
         return false;
      } else {

         if (field === 'fullName') {
            const pattern = /^[A-Za-zÀ-ÿ ,.'-]+$/;
            if (!pattern.test(inputs[field])) {
               setValidations(prevState => ({
                  ...prevState,
                  [field]: 'Please enter full Name'
               }));
               return false;
            }
         }

         if (field === 'designation') {
            const pattern = /^[A-Za-zÀ-ÿ ,.'-]+$/;
            if (!pattern.test(inputs[field])) {
               setValidations(prevState => ({
                  ...prevState,
                  [field]: 'Please enter Designation'
               }));
               return false;
            }
         }

         if (field === 'mobile') {
            const phoneRegex = /^(?:\+(?:[0-9] ?){6,14}[0-9]|(?:\+?1[2-9]\d{2}[2-9](?!11)\d{6})|(?:[789]\d{9}))$/;
            if (!phoneRegex.test(inputs[field])) {
               setValidations(prevState => ({
                  ...prevState,
                  [field]: 'Please enter valid mobile Number'
               }));
               return false;
            }
         }

         if (field === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(inputs[field])) {
               setValidations(prevState => ({
                  ...prevState,
                  [field]: 'Please enter valid Email'
               }));
               return false;
            }
         }

         else {
            setValidations(prevState => ({
               ...prevState,
               [field]: ''
            }));
         }
         return true;
      }
   };

   const handleSubmit = async (event) => {
      event.preventDefault();
      let isFormValid = true;
      setValidations(prevState => ({
         ...prevState,
         fullName: '',
         company: '',
         email: '',
         mobile: '',
         designation: '',
      }));

      // Check each field and set error messages if required
      if (!handleFieldValidation('fullName', 'Please enter your full Name')) {
         isFormValid = false;
      }

      if (!handleFieldValidation('company', 'Please enter your Company Name')) {
         isFormValid = false;
      }

      if (!handleFieldValidation('designation', 'Please enter your Designation')) {
         isFormValid = false;
      }

      if (!handleFieldValidation('email', 'Please enter your Email')) {
         isFormValid = false;
      }

      if (!handleFieldValidation('mobile', 'Please enter your Phone')) {
         isFormValid = false;
      }

      if (isFormValid) {
         const requestOptions = {
            headers: {
               'Content-Type': 'application/json',
               "x-api-key": "P3yWDK7Aur2Of8y8AJHDza4GtilPsOG06HKp66b0"
            }
         };
         var data = {
            fullname: inputs.fullName,
            emailId: inputs.email,
            phone: inputs.mobile,
            company_name: inputs.company,
            designation: inputs.designation,
            contact_reason: "Hire from us",
         };
         try {
            const result = await axios.post(SERVICE_URL + "/enquiry/company", data, requestOptions, { crossDomain: true });
            console.log(result);
            if (result.data.statusCode === "201") {
               setSuccess(true);
               setErrorMsg('');
               setError(false);
               setInputs({});
            }
            else {
               setErrorMsg(result.data.detail.message);
               setSuccess(false);
               setError(true);
            }

         }
         catch (error) {
            console.log(error);
         }
      }
   }


   return (
      <React.Fragment>
         <HeaderHome />
         <HelmetProvider>
            <Helmet>
               <meta charset="UTF-8" />
               <title>Simplify Your Tech Hiring Needs With Skillible</title>
               <meta name="description" content="Hire from our skillfully trained talent pool. We provide End-to-end talent development solutions, right from acquiring talent, training, to placement." />
               <link rel="canonical" href="http://skillible.io/" />
               <meta property="og:title" content="Skillible - We Help You Succeed In Your Career!" />
               <meta property="og:description" content="Hire from our skillfully trained talent pool. We provide End-to-end talent development solutions, right from acquiring talent, training, to placement." />
               <meta property="og:url" content="http://skillible.io/" />
               <meta property="og:type" content="website" />
               <meta property="og:locale" content="en_US" />
               <meta property="og:site_name" content="Skillible" />
            </Helmet>
         </HelmetProvider>
         <div>
            <section className="industries-banner she-leads">
               <div className="container">
                  <div className="row">
                     <div className="col-md-6">
                        <div className="text-banner hire-tr-td">
                           <h1> Choose Skillible and save <span> Upto 30%*</span> on Hiring & Talent Development </h1>
                           <p className="spend-less-hir">Now save more and spend less  on hiring resources    </p>
                           <p><span className="only-with-skill"> Only with Skillible! </span></p>
                        </div>
                     </div>
                     <div className="col-md-6 text-center">
                        <div className="img-banner pair-imgdv ndvt-2 hire-from-us-s">
                           <span className="pair-img"><img src={require(`../assets/images/pairw.png`)} className="img-fluid" alt="" /></span>
                           <div className="pairbanner_girl">
                              <img src={require(`../assets/images/banner_girl.png`)} className="img-fluid" alt="Hire and Develop Talent with Skillible" />
                           </div>
                           <div className="pewilly">
                              <div className="pewilly-img">
                                 <img src={require(`../assets/images/assessments.png`)} className="img-fluid" alt="Ensure seamless hiring with Skillible" />
                              </div>
                              <div className="pewilly-text">
                                 <p>Assessment Solution <span> Auto-proctored assessments to ensure a seamless hiring </span></p>
                              </div>
                           </div>
                           <div className="pewilly-operate">
                              <div className="pewilly-img">
                                 <img src={require(`../assets/images/built.png`)} className="img-fluid" alt="Access customized talents with Skillible" />
                              </div>
                              <div className="pewilly-text">
                                 <p>Custom-Built <span>
                                    Spend less time and money on skill development.
                                 </span>
                                 </p>
                              </div>
                           </div>
                           <div className="pewilly-transfer">
                              <div className="pewilly-img">
                                 <img src={require(`../assets/images/no-fee.png`)} className="img-fluid" alt="Get role-ready talent at zero hiring fee - Skillible" />
                              </div>
                              <div className="pewilly-text">
                                 <p>On -Boarding <span>Get role-ready talent at zero hiring fee.</span></p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="pair-sectiontext pair-sectiontext2 hd2 ndvt-3">
               <div className="container">
                  <div className="row mx-wth">
                     <div className="col-md-12 col-lg-6">
                        <div className="frenceimg-pair">
                           <div className="img-frence-pair"><img src={require(`../assets/images/candidate-dep.png`)} className="img-fluid" alt="Hire Deployment-Ready Candidates from Day One - Skillible" />
                           </div>
                        </div>
                     </div>
                     <div className="col-md-12 col-lg-6">
                        <div className="languapair-steps step1 day-dep-ts">
                           <h2>Candidates are Deployment-Ready from <span className="day-one-skil"> Day One</span>
                           </h2>
                           <p> Today's everyday technology is evolving at a much faster rate. Tech-based companies are looking out for upskilled and ready-to-deploy candidates.  <span className="deplyo-one-skil" > This search can be strenuous and expensive too.</span>
                           </p>
                           <p>   Your demand for adequate skillsets and modest resources can be met under one roof. Skillible can help enterprises and businesses to expand by finding the right resources with the most affordable prices.
                           </p>
                           <div className="formUsp">
                              <ul>
                                 <li>
                                    <span><img src={require(`../assets/images/talents-1.png`)} className="img-fluid" alt="Faster Talent Identification and Deployment - Skillible" /></span>
                                    <p>Faster Talent Identification and Deployment </p>
                                 </li>
                                 <li>
                                    <span><img src={require(`../assets/images/hire-1.png`)} className="img-fluid" alt="Hire resources on FTE or CWR term - Skillible" /></span>
                                    <p>Hire resources on FTE or CWR term  </p>
                                 </li>
                                 <li>
                                    <span><img src={require(`../assets/images/cost.png`)} className="img-fluid" alt="Reduce employee benching costs - Skillible" /></span>
                                    <p>Reduce employee benching costs </p>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="pair-sectiontext hd2 ndvt-3">
               <div className="container">
                  <div className="row displ-lrtd mx-wth">
                     <div className="col-md-12 col-lg-6 text-end">
                        <div className="frenceimg-pair">
                           <div className="img-frence-pair"><img src={require(`../assets/images/why-skill.png`)} className="img-fluid" alt="Partner with skillible" />
                           </div>
                        </div>
                     </div>
                     <div className="col-md-12 col-lg-6">
                        <div className="languapair-steps step1 ">
                           <h2>Why Skillible ?</h2>
                           <p>Partner with Skillible and hire from our skillfully trained talent pool.
                              We provide end-to-end talent development solutions, right from <span className="deplyo-one-skil" >acquiring talent, training, to placement. </span>
                           </p>
                           <ul>
                              <li>
                                 <span className="icon"><i className="fas fa-check"></i></span>
                                 <div className="">Pan India Candidates </div>
                              </li>
                              <li>
                                 <span className="icon"><i className="fas fa-check"></i></span>
                                 <div className="">Industry vetted Course Content</div>
                              </li>
                              <li>
                                 <span className="icon"><i className="fas fa-check"></i></span>
                                 <div className="">Free of Cost Hiring </div>
                              </li>
                              <li>
                                 <span className="icon"><i className="fas fa-check"></i></span>
                                 <div className="">Trained by Industry Experts </div>
                              </li>
                           </ul>
                           <button className="btn brouch-down"><i className="fa fa-download"></i>
                              <Link to="/Strategic-Talent-Partner.pdf" target="_blank" download> Download hiring brochure</Link>
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="ades-sec1 mb-5">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <div className="bg2colr1">
                           <div className="gren-part1">
                              <div className="greenbgclr">
                                 <div className="iconsv1">
                                    <span className="img0icc">
                                       <img src={require(`../assets/images/icon_.png`)} className="img-fluid" alt="One stop solution for hiring needs- Skillible" />
                                    </span>
                                    <h4>What Makes Our Techies Exceptional </h4>
                                 </div>
                                 <div className="techies-exceptional">
                                    <ul className="">
                                       <li> Hire pre- trained Full Stack Development Program </li>
                                       <li> Trained Talents </li>
                                       <li> Proven Track Records </li>
                                       <li> One Stop Acquisition </li>
                                       <li> Trained through Experienced Faculty</li>
                                       <li> High Quality Candidates</li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                           <div className="girlcntr-part1 text-center">
                              <span>
                                 <img src={require(`../assets/images/tech-excep.png`)} className="img-fluid" alt="Skillible offers best resources" />
                              </span>
                           </div>
                           <div className="gray-part1 gray-part2">
                              <span>
                                 <img src={require(`../assets/images/step.png`)} className="img-fluid" alt="" />
                                 <div className="techies-exceptional2">
                                    <ul className="">
                                       <li> Hire formerly trained Salesforce Developer </li>
                                       <li> Diverse Talent Pool for your diversified needs  </li>
                                       <li> Zero Hiring fees  </li>
                                       <li> Bulk Hiring  </li>
                                       <li> Have undergone thorough immersive learning experience   </li>
                                       <li> Pre-Trained developers </li>
                                    </ul>
                                 </div>
                              </span>
                              <div className="text-center">
                                 <a href="#hfuform" className=""> Start Hiring </a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="ourmuli-services-sec sce991">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12 text-center pb-5">
                        <div className="hding2 colr-spanh2 free-cost-skill">
                           <h2>Skillible <span>Services</span>
                           </h2>
                           <h5 className="cost-free-ts" > Free of Cost </h5>
                           <p><i> Now you can rule out the chaotic process of going through various agencies to find your star candidate.
                              Skillible is the most aptful platform filled with skilled tech talents that empowers you to hire the most
                              conventional resources instatntly. </i>
                           </p>
                           <p className="dont-forget">
                              <i> Don't Forget , that you don't have to pay a single penny for this! </i>
                           </p>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="opi-services-sec sce991">
               <div className="container">
                  <div className="row">
                     <div className="col-md-6 opi-services text-end  float-end">
                        <div className="opi-services-img">
                           <img src={require(`../assets/images/hiring-partner0.png`)} className="img-fluid opi-comm-img" alt="Become A Skillible Hiring partner" />
                           <span className="opi-comm-icon">
                              <img src={require(`../assets/images/hiring-icon-1.png`)} className="img-fluid" alt="community icon" />
                           </span>
                        </div>
                     </div>
                     <div className="col-md-6 float-start">
                        <div className="opi-servicesText ps-0 hding2">
                           <h5 className="click-away-skil"> You are just a click Away …......  </h5>
                           <h2>From Becoming a Skillible Hiring Partner </h2>
                           <ul className="">
                              <li>
                                 <div className="">Your search for the best IT talent equipped with the right amount of IT skills can help you prosper and attain your business goals. Skillible invites you to join its hiring network and promises to offers you some of the best talents customized as per your taste and business requirement. </div>
                              </li>
                              <li className="get-started-btn">
                                 <a href="#hfuform" > Get Started</a>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="why-our-section mt-5">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <div className="owl-carousel owl-theme slider-for-business dottdsn dottdsn-fxtst" id="testimon-home">
                           <div className="item">
                              <div className="row">
                                 <div className="col-md-12">
                                    <div className="why-our-text text-center testim-home-page">
                                       <h2>Testimonials</h2>
                                       <div className="name_tstitext">
                                          <div className="arrow_testimoil">&ldquo;</div>
                                          <p>" The team at Skillible has been our potent weapon in building a commendable workforce. They helped us in finding high-quality candidates even for the most challenging roles. What was most impressive was that they never sacrificed on quality. "  </p>
                                          <div className="name_tsti1">
                                             <div className="name_tsti">  Archana Yadav, VP of HR
                                                <span>Leading CRM Company </span>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="item">
                              <div className="row">
                                 <div className="col-md-12">
                                    <div className="why-our-text text-center testim-home-page">
                                       <h2>Testimonials</h2>
                                       <div className="name_tstitext">
                                          <div className="arrow_testimoil">&ldquo;</div>
                                          <p>" We have saved an enormous amount of time and energy by investing our trust in Skillible. By the time a candidate shows up for an interview, we’re certain that we are speaking to someone who is well trained and qualified.    "</p>
                                          <div className="name_tsti1">
                                             <div className="name_tsti"> Vivek Singh, QA Officer  <span> Recruitment Business Partner  </span></div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="item">
                              <div className="row">
                                 <div className="col-md-12">
                                    <div className="why-our-text text-center testim-home-page">
                                       <h2>Testimonials</h2>
                                       <div className="name_tstitext">
                                          <div className="arrow_testimoil">&ldquo;</div>
                                          <p>" Skillible quickly understood our company’s culture, candidate’s profile, and our skill needs. We have found a perfect resource within a few weeks. We will definitely connect with Skillible again! " </p>
                                          <div className="name_tsti1">
                                             <div className="name_tsti"> Shivani Singh, SQL Developer <span> Leading Automative Consultancy</span></div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="conatct-section contact-formsce contact-formsce1" id="hfuform">
               <div className="container">
                  <div className="row">
                     <div className="col-mc-12">
                        <div className="bg_contactForm">
                           <div className="row">
                              <div className="col-md-12 pb-4 text-start hding2">
                                 <h2>Find the Right Candidates <span>Today!</span>
                                 </h2>
                              </div>
                              <div className="col-12">
                                 <p id="cntrepmsg"></p>
                              </div>
                           </div>
                           <form className="row needs-validation" id="free_inquiry" onSubmit={handleSubmit}  >
                              <input type="hidden" name="country_code" id="contact_country_code" />
                              <div className="col-md-12 col-lg-6">
                                 <div className="row">
                                    <div className={`col-md-12 ${validations.fullName && 'has-error'}`}>
                                       <div className="fildsstr">
                                          <input type="text"
                                             className="form-control" name="fullName" value={inputs.fullName || ""}
                                             placeholder="Full Name" onChange={handleChange} />
                                          {validations.fullName && (
                                             <div className="error-message">{validations.fullName}</div>
                                          )}
                                       </div>

                                    </div>
                                    <div className={`col-md-12 ${validations.mobile && 'has-error'}`}>
                                       <div className="fildsstr">
                                          <input type="text" className="form-control"
                                             maxLength="10"
                                             id="inputPhone" name="mobile" onChange={handleChange} placeholder="Phone Number"
                                             value={inputs.mobile || ""} />

                                          {validations.mobile && (
                                             <div className="error-message">{validations.mobile}</div>
                                          )}
                                       </div>
                                    </div>
                                    <div className={`col-md-12 ${validations.designation && 'has-error'}`}>
                                       <div className="fildsstr">
                                          <input type="text"
                                             className="form-control" name="designation" value={inputs.designation || ""}
                                             placeholder="Designation" onChange={handleChange} />
                                          {validations.designation && (
                                             <div className="error-message">{validations.designation}</div>
                                          )}
                                       </div>

                                    </div>
                                 </div>
                              </div>
                              <div className="col-md-12 col-lg-6">
                                 <div className={`col-md-12 ${validations.email && 'has-error'}`}>
                                    <div className="fildsstr">
                                       <input type="text"
                                          className="form-control"
                                          placeholder="Email ID" name='email' value={inputs.email || ""} onChange={handleChange} />
                                       {validations.email && (
                                          <div className="error-message">{validations.email}</div>
                                       )}
                                    </div>
                                 </div>
                                 <div className={`col-md-12 ${validations.company && 'has-error'}`}>
                                    <div className="fildsstr">
                                       <input type="text"
                                          className="form-control" name="company" value={inputs.company || ""}
                                          placeholder="Company Name" onChange={handleChange} />
                                       {validations.company && (
                                          <div className="error-message">{validations.company}</div>
                                       )}
                                    </div>

                                 </div>
                              </div>
                              <div className="col-md-12 col-lg-12">
                                 <div className="text-center">
                                    <button className="btn" type="button" id="buttonSubmitNow" name="temp" onClick={(e) => handleSubmit(e)}>Submit Now</button>
                                 </div>
                              </div>
                              <div className="col-md-12 text-center ">
                                 {success ? <div className="form-check pt-3 sucss">
                                    <label className="succes-msg"> Thank you! We will get in touch with you soon!
                                    </label>
                                 </div> :
                                    <div className="form-check pt-3 deng">
                                       <label className="danger-msg" id="not-store-data"> {errorMsg}
                                       </label>
                                    </div>
                                 }

                                  {success ?
                                    <div className="form-check pt-3 sucss">
                                       <label className="succes-msg"> Thank you! We will get in touch with you soon!
                                       </label>
                                    </div>
                                    : ''
                                 }
                                 {error ?
                           <div className="form-check pt-3 deng">
                              <label className="danger-msg" id="not-store-data"> {errorMsg}
                              </label>
                           </div> : ''
                        } 
                              </div>
                              <div className="col-md-12 text-center">
                                 <div className="or_bodr1">
                                    <span>or</span>
                                 </div>
                              </div>
                              <div className="col-md-12 text-center">
                                 <div className="sign_up_link">Email us at -
                                    <a href="mailto:hire@skillible.io">hire@skillible.io</a>
                                 </div>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </div>

      </React.Fragment>
   )
}
import React, { useState } from 'react';
import { SERVICE_URL2 } from '../constant';
import { Link } from 'react-router-dom';
import HeaderHome from './HeaderHome';
import axios from 'axios';

export default function JoinHiringPartner() {

   const [inputs, setInputs] = useState({
      fullName: '',
      email: '',
      designation: '',
      company: '',
      mobile: '',
      switchbtn1: false,
      switchbtn2: false,
   });
   const [success, setSuccess] = useState(false);
   const [error, setError] = useState(false);
   const [errorMsg, setErrorMsg] = useState("");
   const [isValid, setIsValid] = useState(true);
   const [validations, setValidations] = useState({
      fullName: '',
      email: '',
      designation: '',
      company: '',
      mobile: '',
      errorMessage: '',
      switchbtn1: false,
      switchbtn2: false,
   })

   const handleChange = (event) => {
      const { name, value, type, checked } = event.target;
      const newValue = type === 'checkbox' ? checked : value;
      setInputs(values => ({ ...values, [name]: newValue }));

   }


   const handleFieldValidation = (field, errorMessage) => {
      if (!inputs[field]) {
         setValidations(prevState => ({
            ...prevState,
            [field]: errorMessage
         }));
         return false;
      } else {

         if (field === 'fullName') {
            const pattern = /^[A-Za-zÀ-ÿ ,.'-]+$/;
            if (!pattern.test(inputs[field])) {
               setValidations(prevState => ({
                  ...prevState,
                  [field]: 'Please enter full Name'
               }));
               return false;
            }
         }

         if (field === 'designation') {
            const pattern = /^[A-Za-zÀ-ÿ ,.'-]+$/;
            if (!pattern.test(inputs[field])) {
               setValidations(prevState => ({
                  ...prevState,
                  [field]: 'Please enter Designation'
               }));
               return false;
            }
         }

         if (field === 'mobile') {
            const phoneRegex = /^(?:\+(?:[0-9] ?){6,14}[0-9]|(?:\+?1[2-9]\d{2}[2-9](?!11)\d{6})|(?:[789]\d{9}))$/;
            if (!phoneRegex.test(inputs[field])) {
               setValidations(prevState => ({
                  ...prevState,
                  [field]: 'Please enter valid mobile Number'
               }));
               return false;
            }
         }

         if (field === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(inputs[field])) {
               setValidations(prevState => ({
                  ...prevState,
                  [field]: 'Please enter valid Email'
               }));
               return false;
            }
         }

         else {
            setValidations(prevState => ({
               ...prevState,
               [field]: ''
            }));
         }
         return true;
      }
   };

   const handleSubmit = async (event) => {
      event.preventDefault();
      let isFormValid = true;
      setValidations(prevState => ({
         ...prevState,
         fullName: '',
         company: '',
         email: '',
         mobile: '',
         designation: '',
      }));

      // Check each field and set error messages if required
      if (!handleFieldValidation('fullName', 'Please enter your full Name')) {
         isFormValid = false;
      }

      if (!handleFieldValidation('company', 'Please enter your Company Name')) {
         isFormValid = false;
      }

      if (!handleFieldValidation('designation', 'Please enter your Designation')) {
         isFormValid = false;
      }

      if (!handleFieldValidation('email', 'Please enter your Email')) {
         isFormValid = false;
      }

      if (!handleFieldValidation('mobile', 'Please enter your Phone')) {
         isFormValid = false;
      }

      if (!handleFieldValidation('switchbtn1', '')) {
         isFormValid = false;
      }

      if (!handleFieldValidation('switchbtn2', '')) {
         isFormValid = false;
      }

      setIsValid(isFormValid);

      if (isFormValid) {
         const requestOptions = {
            headers: {
               'Content-Type': 'application/json',
               "x-api-key": "P3yWDK7Aur2Of8y8AJHDza4GtilPsOG06HKp66b0"
            }
         };
         var data = {
            fullname: inputs.fullName,
            email: inputs.email,
            mobile: inputs.mobile,
            company_name: inputs.company,
            designation: inputs.designation,
            tnc_approve: true,
            tnc_other: true,

         };
         try {
            const result = await axios.post(SERVICE_URL2 + "/hiring-partner/join", data, requestOptions, { crossDomain: true });
            console.log(result);
            if (result.status === 200) {
               setSuccess(true);
               setErrorMsg('');
               setError(false);
               setInputs({});
            }
         }
         catch (error) {
            console.log(error);
            setSuccess(false);
            setError(true);
         }
      }
   }

   return (
      <React.Fragment>
         <HeaderHome />
         <div>
            <section className="conatct-section contact-formsce contact-formsce1 signupNewbnr" id="hfuform">
               <div className="container">
                  <div className="row">
                     <div className="col-md-7 text-left">
                        <div className="moblvieX1">
                           <div className="assignmentHelp-Text signupTextbnrx1">
                              <div className="dextpvieX1">
                                 <h1>No Hiring Fees  <span> Start, Stroll & Spring your Business with Skillible
                                 </span>
                                 </h1>
                                 <p><span className="text-bold">At Skillible, we value collaboration to achieve sustainable growth and development.
                                    As an organization we look forward to creating more value for our partners by providing them with a rich talent pool of resources. </span>
                                 </p>
                                 <p><strong>UpSkill and Cross-Skill Bench </strong></p>
                                 <p>
                                    Keep your team's skills aligned with the most in-demand software skills and certifications.
                                 </p>
                              </div>
                              <div className="signup-imgwText1 up-imgwText1">
                                 <div className="signup-imgw"><span>
                                    <img src={require(`../assets/images/growthbanner.png`)} className="img-fluid" alt="Join Us As Hiring Partner - Skillible " />
                                 </span>
                                 </div>
                                 <div className="signup-imgwText0">
                                    <div className="signup-imgwText2">
                                       <div className="signup-imgwText3">Partner with us :
                                       </div>
                                    </div>
                                    <div className="signup-imgwText5">
                                       <div className="signupbtns5">
                                          <p>Hire pre-trained</p>
                                          <ul>
                                             <li><span>Salesforce </span></li>
                                             <li><span>Full Stack Developers</span></li>
                                          </ul>
                                       </div>
                                       <div className="signupbtns6 text-left">
                                          <div className="signupbtns7">
                                             <div className="d-flex align-items-center"><span>
                                                <img src={require(`../assets/images/grow1.png`)} className="img-fluid" alt="" /></span> Save Upto 30%*
                                             </div>
                                             <p> on Hiring & Talent Development</p>
                                          </div>
                                       </div>
                                       <div className="signupbtns6 text-left">
                                          <div className="signupbtns7">
                                             <div className="d-flex align-items-center"><span>
                                                <img src={require(`../assets/images/partner.png`)} className="img-fluid" alt="" /></span> Trained by Industry Experts
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-5">
                        <div className="bg_contactForm">
                           <div className="row">
                              <div className="col-md-12 pb-4 text-start hding2">
                                 <h2>Be <span> Our Hiring Partner</span></h2>
                              </div>
                           </div>


                           <form className="row hiring-patner-form" id="free_inquiry" onSubmit={handleSubmit}>
                              <input type="hidden" name="country_code" id="contact_country_code" />
                              <div className="col-md-12">
                                 <div className="row">
                                    <div className={`col-md-12 ${validations.fullName && 'has-error'}`}>
                                       <div className="fildsstr full-na-hire">
                                          <label className="form-label">Full Name<span className='required'>*</span></label>
                                          <div className='w-100'>
                                             <input type="text"
                                                className="form-control" name="fullName" value={inputs.fullName || ""}
                                                placeholder="Full Name" onChange={handleChange} />
                                             {validations.fullName && (
                                                <div className="error-message">{validations.fullName}</div>
                                             )}
                                          </div>
                                       </div>

                                    </div>
                                    <div className="col-md-12">
                                       <div className={`col-md-12 ${validations.email && 'has-error'}`}>
                                          <div className="fildsstr full-na-hire">
                                             <label className="form-label">Email<span className='required'>*</span></label>
                                             <div className='w-100'>
                                                <input type="text"
                                                   className="form-control"
                                                   placeholder="Email ID" name='email' value={inputs.email || ""} onChange={handleChange} />
                                                {validations.email && (
                                                   <div className="error-message">{validations.email}</div>
                                                )}
                                             </div>
                                          </div>
                                       </div>

                                       <div className={`col-md-12 ${validations.mobile && 'has-error'}`}>
                                          <div className="fildsstr full-na-hire">
                                             <label className="form-label">Phone<span className='required'>*</span></label>
                                             <div className='w-100'>
                                                <input type="text" className="form-control"
                                                   maxLength="10"
                                                   id="inputPhone" name="mobile" onChange={handleChange} placeholder="Phone Number"
                                                   value={inputs.mobile || ""} />

                                                {validations.mobile && (
                                                   <div className="error-message">{validations.mobile}</div>
                                                )}
                                             </div>
                                          </div>
                                       </div>

                                       <div className={`col-md-12 ${validations.designation && 'has-error'}`}>
                                          <div className="fildsstr full-na-hire">
                                             <label className="form-label">Designation<span className='required'>*</span></label>
                                             <div className='w-100'>
                                                <input type="text"
                                                   className="form-control" name="designation" value={inputs.designation || ""}
                                                   placeholder="Designation" onChange={handleChange} />
                                                {validations.designation && (
                                                   <div className="error-message">{validations.designation}</div>
                                                )}
                                             </div>
                                          </div>

                                       </div>

                                       <div className={`col-md-12 ${validations.company && 'has-error'}`}>
                                          <div className="fildsstr full-na-hire">
                                             <label className="form-label">Company<span className='required'>*</span></label>
                                             <div className='w-100'>
                                                <input type="text"
                                                   className="form-control" name="company" value={inputs.company || ""}
                                                   placeholder="Company Name" onChange={handleChange} />
                                                {validations.company && (
                                                   <div className="error-message">{validations.company}</div>
                                                )}
                                             </div>
                                          </div>
                                       </div>

                                       <div className={`col-md-12 pt-1 ${!isValid && inputs.switchbtn1 === false && 'has-error'}`}>
                                          <div className="form-check form-switch">
                                             <input className="form-check-input" type="checkbox" name='switchbtn1' value={inputs.switchbtn1} checked={inputs.switchbtn1} onChange={handleChange} id="invalidCheck" />
                                             <label className="form-check-label" htmlFor="mySwitch">I authorize Skillible and its representatives to Call,
                                                SMS, Email or Whatsapp me about its products and offers.
                                             </label>
                                          </div>

                                       </div>
                                       <div className={`col-md-12 ${!isValid && inputs.switchbtn2 === false && 'has-error'}`}>
                                          <div className="form-check form-switch">
                                             <input className="form-check-input" type="checkbox" name='switchbtn2' value={inputs.switchbtn2} checked={inputs.switchbtn2} onChange={handleChange} id="invalidCheck1" />
                                             <label className="form-check-label" htmlFor="mySwitch">By continuing, you agree to Skillible's<Link to="#" data-bs-toggle="modal" data-bs-target="#grwowith"> Terms of Condition</Link> </label>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <div className="col-md-12 col-lg-12 mt-4">
                                 <div className="text-center">
                                    <button className="btn" type="submit">Get Started</button>
                                 </div>
                              </div>
                              <div className="col-md-12 text-center ">
                                 {success && <div className="form-check pt-3 sucss">
                                    <label className="succes-msg"> Thank you! We will get in touch with you soon!
                                    </label>
                                 </div>
                                 }

                                 {error &&
                                    <div className="form-check pt-3 deng">
                                       <label className="danger-msg" id="not-store-data"> {errorMsg}
                                       </label>
                                    </div>
                                 }

                              </div>
                              <div className="col-md-12 text-center">
                                 <div className="or_bodr1">
                                    <span>or</span>
                                 </div>
                              </div>
                              <div className="col-md-12 text-center">
                                 <div className="sign_up_link">Email us at -
                                    <a href="mailto:hire@skillible.io">hire@skillible.io</a>
                                 </div>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <div className="modal fade grwowithust" id="grwowith">
               <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                     <div className="modal-header grow-hs-skillblie">
                        <h2 className="modal-title">Skillible Hiring Partnership <span> Terms Of Use</span> </h2>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                     </div>
                     <div className="modal-body grow-hs">
                        <div className="container">
                           <div className="row">
                              <div className="col-mc-12">
                                 <div className="row">
                                    <div className="col-md-12 mt-2">
                                       <div className="popup-text-grow mb-0">
                                          <h2>
                                             <span>Accessing and using this section of the Skillible website requires agreeing to the following Terms & Conditions. </span>
                                          </h2>
                                          <p>
                                             <span>Using any part of the Skillible website constitutes your acceptance of these Terms and Privacy Policy. Skillible may update these Terms without notice, and it is your responsibility to periodically review them. Failure to do so will not hold Skillible liable for any resulting loss. If you do not agree to these Terms, do not access or use the website or its services. "You" or "user" refers to anyone accessing or using the website for any purpose.  </span>
                                          </p>
                                       </div>
                                    </div>

                                    <div className="col-md-12 termsuse">

                                       <h2>
                                          <span>1. Eligibility</span>
                                       </h2>
                                       <p className="mb-4"> This section of the website is intended solely for use by employers. Accordingly, access to and use of this section is restricted to individuals and entities that qualify as employers. Unauthorized access or use of this section by individuals or entities that do not meet the definition of an employer is strictly prohibited.   </p>


                                       <h2>
                                          <span>2. Services</span>
                                       </h2>
                                       <p className="mb-4">  For a company, partnership firm, or trust (any other except an individual), you must have the capacity to form a binding contract in our jurisdiction. You state that you have the authority to enter into a contract with Skillible and abide by all of the terms and conditions set forth in the Terms. You also agree that you will use the website in a manner consistent with all applicable laws, rules & regulations.'  </p>


                                       <h2>
                                          <span>3. Payments</span>
                                       </h2>
                                       <p className="mb-4"> 'The payment terms and procedures may vary from assignment to assignment and from client to client. Each assignment may be governed by a separate agreement which will be drafted and agreed upon between the interested parties from time to time.    </p>
                                       <p className="mb-4"> Skillible does not guarantee any payments on behalf of the company, it works only as a facilitator between the company and the independent consultant. All payments will only be made on successful and satisfactory completion of any assignment which is approved by the company.    </p>
                                       <p className="mb-4"> <span className="terms-note"> Note:  </span>

                                          Companies shall offer a minimum CTC of 4 lakhs to hire our candidates. </p>



                                       <h2>
                                          <span>4. Business And Purpose </span>
                                       </h2>
                                       <p className="mb-4"> Skillible authorizes its hiring partners to represent themselves as potential hiring partners of Skillible and to conduct hiring activities on various forums and platforms in accordance with the terms of this agreement. Skillible assures that none of its hiring partners will be charged any fees for hiring.    </p>


                                       <h2>
                                          <span>5. Introduction of Candidates </span>
                                       </h2>
                                       <p className="mb-4"> Skillible customizes and trains candidates to meet industry needs and verifies their skills and experience. Candidates may undergo background checks for verification. The Agency is responsible for the candidate's suitability for the position, and Skillible accepts upon completion of security checks.   </p>


                                       <h2>
                                          <span>6. No Poaching </span>
                                       </h2>
                                       <p className="mb-4"> 'As a Skillible client, you agree that you will not solicit, engage, empanel or contract with any independent expert consultant, introduced to you by Skillible without first informing and paying Skillible'   </p>


                                       <h2>
                                          <span>7. Non-Compete </span>
                                       </h2>
                                       <p className="mb-4"> 'During the period of the Consultants empanelment with Skillible, the consultant agrees not to, directly or indirectly; compete with Skillible by providing independent services without informing Skillible, to the client(s) to which Skillible has introduced him/her. The consultant may continue to provide services to any other clients where Skillible has had no role to play in the introduction process.'    </p>


                                       <h2>
                                          <span>8. Maintenance</span>
                                       </h2>
                                       <p className="mb-4"> Skillible may deactivate or suspend a user's access to the website and services without prior notice, for system maintenance, upgrading, testing, repairs, or related work. Skillible is not responsible for any loss, costs, or expenses incurred by the user as a result of such deactivation or suspension, and no fees or charges will be refunded.   </p>


                                       <h2>
                                          <span>9. Termination</span>
                                       </h2>
                                       <p className="mb-4"> These Terms will be in effect for all users until their account is disabled or removed by mutual agreement, the term of service is completed, or Skillible determines that the user has breached the terms, it is not in the public interest to continue providing service, or the user is declared bankrupt or winding up proceedings are initiated.   </p>


                                       <h2>
                                          <span>10. Force Majeure </span>
                                       </h2>
                                       <p className="mb-4"> Neither party is responsible for delays or defaults in fulfilling their obligations if caused by events such as fires, floods, power failures, acts of God, or restrictions that violate the agreement due to export or import regulations or requirements of India.   </p>


                                       <h2>
                                          <span>11. Limitation of Liability </span>
                                       </h2>
                                       <p className="mb-4"> Skillible is not liable for any indirect, special, or consequential loss or damage, including loss of use, data, revenue, profits, business, or third-party claims resulting from the use of its services.   </p>


                                       <h2>
                                          <span>12. Jurisdiction</span>
                                       </h2>
                                       <p className="mb-4"> 'These Terms shall be governed by and in accordance with the laws of State only and any disputes arising out of this agreement shall be settled in the state and country of the Company’s address, as stated in this agreement.   </p>



                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </React.Fragment>
   )
}
import React from "react";
import Modal from 'react-bootstrap/Modal';
const VirtualInternshipModal = ({show, hide }) => {
    const modalClose = () => {
        hide();
     }

    return(
        
        <Modal size="xl" show={show} onHide={modalClose} scrollable>
        <Modal.Header closeButton>
        <h3 className="modal-title tnc-use">Virtual  <span> Internship</span></h3>
        </Modal.Header>
        
        <div className="modal-body">
                     <div className="row">
                        <div className="col-md-12 termsuse">
                           <p className=""><strong>  DO NOT PARTICIPATE IN THIS VIRTUAL INTERNSHIP IF PROHIBITED BY LOCAL LAW. PARTICIPATION
                              IN THIS VIRTUAL INTERNSHIP CONSTITUTES YOUR ACCEPTANCE OF THESE OFFICIAL RULES.  </strong>
                           </p>
                           <p className="mb-4">  <strong> Disclaimer: </strong> This Virtual Internship does not guarantee placement offers or stipend payments during the internship period.  </p>
                           <p className="mb-4"> <strong>  Virtual Internship Description: </strong> VOID WHERE PROHIBITED BY LAW. The Virtual Internship Program is designed to elevate participants' skills for the Skillible virtual program. Participants engage in upskilling and training in cutting-edge Advanced Technology.   </p>
                           <h2>
                              <span>1. Event Organizer: </span>
                           </h2>
                           <p>Skillible
                           </p>
                           <h2>
                              <span>2. Collaborator:</span>
                           </h2>
                           <p>AICTE (“AICTE” or “Partner”) </p>
                           <h2>
                              <span>3. Virtual Internship Period:</span>
                           </h2>
                           <p>6 weeks</p>
                           <h2>
                              <span>4. Registrations: </span>
                           </h2>
                           <p>Registration for the virtual internship is valid only if completed within one month from the cohort launch date.</p>
                           <h2>
                              <span>5. Hands-on Bootcamp: </span>
                           </h2>
                           <p>The hands-on bootcamp will be conducted for 6 weeks for each cohort. </p>
                           <h2>
                              <span>6. Eligibility: </span>
                           </h2>
                           <p>Any individual with a graduate degree or diploma or higher. </p>
                           <h2 className=''>
                              <span>How to Enter  </span>
                           </h2>
                           <p> <strong> To enter the Skillible virtual internship program, complete the following steps: </strong></p>
                           <p> Complete the registration process on the AICTE Internship Portal or fill out the form on the Skillible website. </p>
                           <h2>
                              <span>Kindly Note:  </span>
                           </h2>
                           <p>Submissions not properly entered into the virtual internship will not be acknowledged. Proof of entry does not guarantee receipt by the organizer. Submissions must be received by the organizer no later than the end of the Submission Period. Only one entry per person is allowed. Illegible or incomplete submissions will be disqualified. The organizer reserves the right to disqualify any submissions not in compliance with these terms & conditions. </p>
                           <h2>
                              <span>By making a submission and participating in the virtual internship, you represent and warrant that:   </span>
                           </h2>
                           <p>- You agree to the terms and conditions of use of the Skillible website. </p>
                           <p>- You grant the Organizer an irrevocable, royalty-free, worldwide license to use your submission for marketing and promotional use. </p>
                           <p>- Your submission is original, and you have secured all necessary rights, releases, or permissions for its use. </p>
                           <p>- Your submission does not violate any intellectual property rights or the rights of any third party. </p>
                           <h2>
                              <span>Virtual Internship Completion:  </span>
                           </h2>
                           <p>All registered candidates will receive a virtual internship completion certificate upon successful completion of the program. Successful completion involves completing assigned coursework, submitting a project document and approval by the evaluator. </p>
                           <h2>
                              <span>Conduct:   </span>
                           </h2>
                           <p>By participating in the Skillible virtual internship program, Entrants agree to comply with these terms & conditions. Failure to comply may result in disqualification. Organizer reserves the right to disqualify any individual violating these terms or acting disruptively. </p>
                           <h2>
                              <span>Limitations of Liability:  </span>
                           </h2>
                           <p>By participating, Entrants release the Organizer from any liability arising from or in connection with the virtual internship. Organizer assumes no responsibility for technical malfunctions or participant injuries. </p>
                           <h2>
                              <span>Right to Terminate or Modify:  </span>
                           </h2>
                           <p>If the virtual internship cannot proceed as planned, the Organizer reserves the right to cancel, terminate, or modify the program. Organizer may also modify these terms & conditions for clarification purposes. </p>
                           <h2>
                              <span>Privacy:   </span>
                           </h2>
                           <p>By participating and ticking the relevant box on the registration page, you authorize the transfer of your personal data to the Organizer, Supporter & Partner for program administration & marketing purposes. All information will be treated in accordance with the Organizer’s privacy policy. </p>
                           <h2>
                              <span>Governing Law:   </span>
                           </h2>
                           <p>This virtual internship program is subject to the laws of Uttar Pradesh, India. Void where prohibited. </p>
                           <h2>
                              <span>Language:    </span>
                           </h2>
                           <p>The English version of these Rules governs the relationship between the parties. Any translation is for convenience only. </p>
                           <h2>
                              <span> Partner Platform:  </span>
                           </h2>
                           <p>Each partner platform will be governed by its own Terms & Conditions in addition to these rules. </p>
                           <h2>
                              <span>No Employment Relationship:    </span>
                           </h2>
                           <p>No employment relationship is created between participants and Skillible under this program. </p>
                        </div>
                     </div>
                  </div>
      </Modal>
        
    )
}

export default VirtualInternshipModal;
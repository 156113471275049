import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from "react-router-dom";
import ApplyNow from './ApplyNow';
import ApplyNowForm from './ApplyNowForm';
export default function HeaderHome() {
const [modalShow, setModalShow] = useState(false);
const location = useLocation();
const activeKey = location.pathname;
useEffect(() => {
window.scrollTo(0, 0)
}, [])
useEffect(() => {
if (location.pathname === '/home/applynow') {
setModalShow(true);
} else {
setModalShow(false);
}
}, [location.pathname]);
const handleModalShow = () => {
setModalShow(true);
}
const handleModalClose = () => {
setModalShow(false);
}
return (
<React.Fragment>
   <ApplyNow />
   <nav className="navbar navbar-expand-lg fixed-top desktop-v1ew stickyupdate">
      <div className="header_bottom sticky-header section-hero">
         <div className="container">
            <div className="row align-items-center">
               <div className="col-12">
                  <div className="header_static">
                     <div className="logo-section">
                        <Link className="" to="/">
                        <img src={require(`../assets/images/logo.png`)} className="img-fluid" alt="" />
                        </Link>
                     </div>
                     <div className="main_menu_inner">
                        <div className="main_menu">
                           <nav>
                              <ul>
                                 <li className={`nav-item ${activeKey === "/about" ? 'active' : ''}`}>
                                 <NavLink to="/about" className="nav-link">About Us</NavLink>
                                 </li>
                                 <li className={`nav-item hovercustmcss ${activeKey === "/salesforce" || activeKey === "/fullstack" || activeKey === "/genai" ? 'active' : ''}`}>
                                 <NavLink to="#" className="nav-link dropdown-toggle"> All Courses</NavLink>
                                 <ul className="dropdown-menu">
                                    <li>
                                       <Link to="/genai" className="bvxw1">
                                       Generative AI </Link>
                                    </li>
                                    <li>
                                       <Link to="/cybersecurity" className="bvxw1">
                                       Cyber Security  </Link>
                                    </li>
                                    <li>
                                       <Link to="/Vlsi" className="bvxw1">
                                       VLSI  </Link>
                                    </li>
                                    {/* 
                                    <li>
                                       <Link to="/salesforce" className="bvxw1">
                                       Salesforce Developer </Link>
                                    </li>
                                    <li>
                                       <Link to="/fullstack" className="bvxw1">
                                       Full Stack Developer </Link>
                                    </li>
                                    */}
                                 </ul>
                                 </li>
                                 <li className={`nav-item ${activeKey === "/virtual_internship" ? 'active' : ''}`}>
                                 <NavLink to="/virtual_internship" className="nav-link">Virtual Internship</NavLink>
                                 </li>
                                 {/* <li className={`nav-item hovercustmcss ${activeKey === "/she-trails" || activeKey === "/tech-scaller" ? 'active' : ''}`}>
                                 <NavLink to="#" className="nav-link dropdown-toggle">Programs</NavLink>
                                 <ul className="dropdown-menu">
                                    <li>
                                       <Link className="bvxw1" to="/she-trails">
                                       SHE Trails</Link>
                                    </li>
                                    <li>
                                       <Link className="bvxw1" to="/tech-scaller">
                                       Tech Scaller</Link>
                                    </li>
                                 </ul>
                                 </li> */}
                                 {/* <li className={`nav-item hovercustmcss ${activeKey === "/hire-from-us" || activeKey === "/hire-train-deploy" ? 'active' : ''}`}>
                                 <NavLink to="#" className="nav-link dropdown-toggle">Talent Hunt</NavLink>
                                 <ul className="dropdown-menu">
                                    <li>
                                       <Link className="bvxw1" to="/hire-from-us">
                                       Hire From Us</Link>
                                    </li>
                                    <li>
                                       <Link className="bvxw1" to="/hire-train-deploy">
                                       Hire Train Deploy</Link>
                                    </li>
                                 </ul>
                                 </li> */}
                                 {/* <li className={`nav-item hovercustmcss ${activeKey === "/faq" ? 'active' : ''}`}>
                                 <NavLink to="#" className="nav-link dropdown-toggle">Help zone</NavLink>
                                 <ul className="dropdown-menu">
                                    <li>
                                       <Link className="bvxw1" to="/faq">
                                       FAQ</Link>
                                    </li>
                                 </ul>
                                 </li> */}
                                 {/* <li className={`nav-item ${activeKey === "/press_media" ? 'active' : ''}`}>
                                 <NavLink to="/press_media" className="nav-link">Media</NavLink>
                                 </li> */}
                                 {/* 
                                 <li className="nav-item contact-btn">
                                    <NavLink to="#" className="nav-link" onClick={handleModalShow}>Apply Now</NavLink>
                                 </li>
                                 */}
                                 <li className={`nav-item hovercustmcss ${activeKey === "/hire-from-us" || activeKey === "/hire-train-deploy" ? 'active' : ''}`}>
                                 <NavLink to="#" className="nav-link dropdown-toggle">Testimonials </NavLink>
                                 <ul className="dropdown-menu">
                                    <li>
                                       <Link className="bvxw1" to="/Sucess_stories">
                                       Student Success Stories  </Link>
                                    </li>
                                    {/* 
                                    <li>
                                       <Link className="bvxw1" to="/">
                                       Partner Testimonials </Link>
                                    </li>
                                    */}
                                 </ul>
                                 </li>
                                 <li className={`nav-item hovercustmcss ${activeKey === "/hire-from-us" || activeKey === "/hire-train-deploy" ? 'active' : ''}`}>
                                 <NavLink to="#" className="nav-link dropdown-toggle">Insights </NavLink>
                                 <ul className="dropdown-menu">
                                    <li>
                                       <Link className="bvxw1" to="/report">
                                       Reports</Link>
                                    </li>
                                    <li>
                                       <Link className="bvxw1" to="/press_media">
                                       Media</Link>
                                    </li>
                                    {/* 
                                    <li>
                                       <Link className="bvxw1" to="/press_media">
                                       Blog</Link>
                                    </li>
                                    */}
                                 </ul>
                                 </li>
                                 <li className={`nav-item hovercustmcss ${activeKey === "/career" || activeKey === "/career" ? 'active' : ''}`}>
                                 <NavLink to="/career" className="nav-link dropdown-toggle">We Are Hiring  </NavLink>
                                 <ul className="dropdown-menu">
                                    <li>
                                       <Link className="bvxw1" to="/career">
                                       Current Openings </Link>
                                    </li>
                                 </ul>
                                 </li>
                                 <li className="nav-item contact-btn">
                                    <NavLink to="https://learn.skillible.io/login" className="nav-link" target="_blank">Login</NavLink>
                                 </li>
                              </ul>
                           </nav>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </nav>




   {modalShow && 
   <ApplyNowForm show={modalShow} hide={handleModalClose} />
   }
   <nav className="navbar navbar-expand-lg navbar-dark mobile-v1ew" aria-label="Eighth navbar example">
      <div className="container-fluid">
         <Link className="navbar-brand" to="/">
         <img src={require(`../assets/images/logo-black.png`)} className="img-fluid" alt="Skillible logo" />
         </Link>
         <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample07" aria-controls="navbarsExample07" aria-expanded="false" aria-label="Toggle navigation">
         <i className="fas fa-bars"></i>
         </button>
         <div className="collapse navbar-collapse" id="navbarsExample07">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
               <li className={`nav-item`}>
                  <NavLink to="/about" className="nav-link">About Us</NavLink>
               </li>
               <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle" to="/#" data-bs-toggle="dropdown">
                  All Courses </Link>
                  <ul className="dropdown-menu">
                        <li>
                        <Link to="/genai" className="bvxw1">
                        Generative AI </Link>
                    </li>
                    <li>
                        <Link to="/cybersecurity" className="bvxw1">
                        Cyber Security  </Link>
                    </li>
                    <li>
                        <Link to="/Vlsi" className="bvxw1">
                        VLSI  </Link>
                    </li>                   
                  </ul>
               </li>
               {/* <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle" to="/#" data-bs-toggle="dropdown">
                  Programs</Link>
                  <ul className="dropdown-menu">
                     <li>
                        <Link className="bvxw1" to="/she-trails">
                        SHE Trails</Link>
                     </li>
                     <li>
                        <Link className="bvxw1" to="/tech-scaller">
                        Tech Scaller</Link>
                     </li>
                  </ul>
               </li> */}
               <li className="nav-item">
                  <Link to="/virtual_internship" className="nav-link">
                  Virtual Internship</Link>
               </li> 

               {/* <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle" to="/#" data-bs-toggle="dropdown">
                  Talent Hunt </Link>
                  <ul className="dropdown-menu">
                     <li>
                        <Link className="bvxw1" to="/hire-from-us">
                        Hire From Us</Link>
                     </li>
                     <li>
                        <Link className="bvxw1" to="/hire-train-deploy">
                        Hire Train Deploy</Link>
                     </li>
                  </ul>
               </li> */}
               {/* 
               <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle" to="/#" data-bs-toggle="dropdown">
                  Help zone </Link>
                  <ul className="dropdown-menu">
                     <li>
                        <Link className="bvxw1" to="/faq">
                        FAQ</Link>
                     </li>
                  </ul>
               </li>
               */}
               {/* <li className={`nav-item ${activeKey === "/press_media" ? 'active' : ''}`}>
               <NavLink to="/press_media" className="nav-link">Media</NavLink>
               </li> */}








               <li className={`nav-item hovercustmcss ${activeKey === "/hire-from-us" || activeKey === "/hire-train-deploy" ? 'active' : ''}`}>
                                 <NavLink to="#" className="nav-link dropdown-toggle">Insights </NavLink>
                                 <ul className="dropdown-menu">
                                    <li>
                                       <Link className="bvxw1" to="/report">
                                       Reports</Link>
                                    </li>
                                    <li>
                                       <Link className="bvxw1" to="/press_media">
                                       Media</Link>
                                    </li>
                                 
                                 </ul>
                                 </li>







               {/* <li className="nav-item contact-btn">
                  <Link to="#" className="nav-link applynow-mobileview" onClick={handleModalShow}>
                  Apply Now</Link>
               </li> */}


                           <li className={`nav-item hovercustmcss ${activeKey === "/career" || activeKey === "/career" ? 'active' : ''}`}>
                                 <NavLink to="/career" className="nav-link dropdown-toggle">We Are Hiring  </NavLink>
                                 <ul className="dropdown-menu">
                                    <li>
                                       <Link className="bvxw1" to="/career">
                                       Current Openings </Link>
                                    </li>
                                 </ul>
                                 </li>


<li className="nav-item contact-btn">
                                    <NavLink to="https://learn.skillible.io/login" className="nav-link" target="_blank">Login</NavLink>
                                 </li>
            </ul>
         </div>
      </div>
   </nav>
</React.Fragment>
)
}
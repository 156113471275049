import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import HeaderHome from './HeaderHome';
export default function Vlsi() {
 
return (
<React.Fragment>
   <HeaderHome />
   <HelmetProvider>
      <Helmet>
         <meta charset="UTF-8" />
         <title>Report</title>
         <meta name="description" content="An Initiative by Skillible to Upskill Women in India.
            Skillible & salesforce unites to bring more women back to the workforce." />
         <link rel="canonical" href="http://skillible.io/" />
         <meta property="og:title" content="Skillible - We Help You Succeed In Your Career!" />
         <meta property="og:description" content="An Initiative by Skillible to Upskill Women in India.
            Skillible & salesforce unites to bring more women back to the workforce." />
         <meta property="og:url" content="http://skillible.io/" />
         <meta property="og:type" content="website" />
         <meta property="og:locale" content="en_US" />
         <meta property="og:site_name" content="Skillible" />
      </Helmet>
   </HelmetProvider>
   <div>
   <section className="banner-home position-relative home-slider-skil vlsi-banner">
               <div className="" >
                  <div className="item">
                     <div className="container">
                        <div className="row">
                           <div className="col-md-12 text-center">
                              <div className="text-banner">
                                 {/* <img alt="" src={require(`../assets/images/partner-logo.png`)} /> */}
                                 <h2> Are You Ready to Shape the Future of Chip Design?  </h2>
                                 <h4> <span> Become a VLSI Expert   </span> with Skillible</h4>
                                  
                                 <p>
                                 Comprehensive training from fundamentals to advanced techniques in VLSI design, coverying both digital and mixed-signal circuits.  
                                 </p>                             
                                 <h5> <span>Mode-</span> Project-Based Learning  <span> + </span>  Live Masterclasses  | <span>Duration- </span>  6-12 weeks </h5>
                                 {/* <button>
                                    <Link to="#" onClick={handleModalShow} >
                                       Enroll Now </Link>
                                 </button> */}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="sec-genai-enroll mb-4">
               <div className="container">
                  <div className="row ltr-rtln1">
                     <div className="col-md-6">
                        <div className="text-right enroll-genai-head">
                           <span><span ><span ><img alt="" aria-hidden="true" src="" /></span>
                              <img alt="" src={require(`../assets/images/home-revamp/vlsi-main.png`)} /></span></span>
                          
                        </div>
                     </div>
                     <div className="col-md-6">
                        <div className="text-left resimeExListbg mt-4">
                                
                        {/* <div className="women-tech-sn">
                           
                           <h3> What You  <span class="aictc-partner"> Will Learn</span></h3>               
                           <div className="key-highlights">
                              <ul className="">
                                 <li> Understand network vulnerabilities and cyber threat landscape  </li>
                                 <li> Master encryption techniques and secure data practices  </li>
                                 <li> Learn to perform risk assessment and implement security controls </li>
                                 <li> Conduct ethical hacking and penetration testing  </li>
                                 <li> Develop strategies for incident response and digital forensics </li>
                                 <li> Stay compliant with legal and regulatory standards</li>
                              </ul>
                           </div>
                        </div> */}


                        <div className="languapair-steps step1 hading-new-2">
                           <h2> What You Will Learn</h2>
                           

<p>Throughout this 12-week program, you'll dive deep into the core aspects of <span className="deplyo-one-skil" > VLSI design, </span>  gaining both theoretical knowledge and practical skills. From understanding CMOS technology to mastering the design flow, you will develop expertise that is crucial in today’s rapidly evolving semiconductor industry. Here’s a glimpse of what you’ll gain:</p>


                           <ul>
                              <li>
                                 <span className="icon"><i className="fas fa-check"></i></span>
                                 <div className="">Master the fundamentals of CMOS technology and VLSI design flow
                                 </div>
                              </li>
                              <li>
                                 <span className="icon"><i className="fas fa-check"></i></span>
                                 <div className="">Gain proficiency in digital circuit design, layout, and verification </div>
                              </li>
                              <li>
                                 <span className="icon"><i className="fas fa-check"></i></span>
                                 <div className="">Learn ASIC and FPGA design using Verilog and VHDL </div>
                              </li>
                              <li>
                                 <span className="icon"><i className="fas fa-check"></i></span>
                                 <div className="">Design for Testability (DFT) and optimize circuit performance 
                                   </div>
                              </li>
                              <li>
                                 <span className="icon"><i className="fas fa-check"></i></span>
                                 <div className="">Implement advanced SoC and semiconductor manufacturing techniques 
                                 </div>
                              </li>
                              <li>
                                 <span className="icon"><i className="fas fa-check"></i></span>
                                 <div className="">Explore emerging VLSI trends like 3D ICs, FinFET, and machine learning for VLSI 
                                 </div>
                              </li>
                           </ul>
                         
                        </div>

                                
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="course-nutshell position-relative">
            <img className="nutshel-banner" alt="" src={require(`../assets/images/banner/nutshel-bg.png`)} />
               <div className="container">
                  <div className="row">
                     <div className="col-md-12 text-center genai-heading">
                        <h2>
                        Experience Our Unmatchable VLSI Course In A Nutshell
                        </h2>
                     </div>
                     <div className="col-md-12 col-lg-5">
                        <div className="genai-circulm">
                           <div className="gen-circulm2 gen-circulm">
                              <div className="genai-img"> <span >
                                 <img alt="" src={require(`../assets/images/icon01.png`)} /></span>
                              </div>
                              <span className="genNum">1</span>
                              <div className="gentex">Real-World Industry Projects
                              </div>
                           </div>
                           <div className="gen-circulm2 gen-tx">
                              <div className="genai-img"><span >
                                 <img alt="" src={require(`../assets/images/icon3_1.png`)} /></span>
                              </div>
                              <span className="genNum">2</span>
                              <div className="gentex">Expert-Led Masterclasses
                              </div>
                           </div>
                           <div className="gen-circulm2 gen-tx">
                              <div className="genai-img"><span >
                                 <img alt="" src={require(`../assets/images/icon1.png`)} /></span>
                              </div>
                              <span className="genNum">3</span>
                              <div className="gentex">Flexible and Bilingual learning path
                              </div>
                           </div>
                           <div className="gen-circulm2 gen-circulm4">
                              <div className="genai-img"><span >
                                 <img alt="" src={require(`../assets/images/icon4.png`)} /></span>
                              </div>
                              <span className="genNum">4</span>
                              <div className="gentex"> Special Masterclass by Industry Experts</div>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-12 col-lg-7 text-right">
                        <div className="thikstpbg">
                           <div className="thikstp thikstp1">
                              <div className="th-Text">
                                 <div className="th-Text1"><span>60-120 </span> Hours of learning  <span className="img">
                                    <img src={require(`../assets/images/48.png`)} className="img-fluid" alt="" /></span>
                                 </div>
                              </div>
                           </div>
                           <div className="thikstp thikstp2">
                              <div className="th-Text">
                                 <div className="th-Text1"> AI-Enabled 
                                 </div>
                                 <div className="th-Text1"> Resume Building </div>
                              </div>
                               <div className="thikstpimg"><span>
                                  <img src={require(`../assets/images/available.png`)} className="img-fluid" alt="" /> 
                                 </span>
                              </div> 
                           </div>
                           <div className="thikstp thikstp4">
                              <div className="th-Text1"><span>Explore - </span>   10+ VLSI Tools <span>
                                 <img src={require(`../assets/images/circlprstg.png`)} className="img-fluid" alt="" /></span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>




            <section class="expert-linguists expertise-ws servs-edt mt-4 certificate-me">
         <div class="container">
            <div class="row">

          


            <div class="col-md-12 col-lg-12 mt-4">
 <div className='text-center hading-new-2'><h2>Certificate Preview:What You’ll Earn 
                     <span class="deplyo-one-skil">  </span> </h2>
                     
</div>
               
                  <div class="certificate-genai">
                     <img  src={require(`../assets/images/home-revamp/certificate-vlsi.jpg`)} 
                     class="img-fluid" alt="area-expertise-service"/>
                  </div>
               </div>


              
               
            </div>
         </div>
      </section>


      
      <section class="expert-linguists expertise-ws servs-edt mt-4 lms-banner-bg">
         <div class="container-fluid">
            <div class="row">


            <div class="col-md-12 col-lg-7">
                  <div class="rightside-all-inoneimg">
                     <img  src={require(`../assets/images/home-revamp/lms.png`)} 
                     class="img-fluid" alt="area-expertise-service"/>
                  </div>
               </div>


               <div class="col-md-12 col-lg-5">
                  <div class="ourfamliy-text hading-new-2">
                     <h2>Learn at your pace with our State-of-the-Art Learning Portal (LMS) </h2>
                     
                     <p class="expertise-para">We prioritize open communication with our learners, engaging them through emails, announcements, learner forums, and social media.  </p>

                     
                  </div>
               </div>
               
            </div>
         </div>
      </section>

            <section className="services-industry genai-heading-2">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12 text-center hding2 b-to-a-course hading-new-2">
                        <h2>A Skill-Focused  Curriculum</h2>
                        <p>Our VLSI curriculum combines industry expertise with hands-on learning, covering everything from CMOS
                            design to advanced SoC concepts. Designed by experts, it focuses on real-world applications and
                             cutting-edge tools. Through practical projects, you’ll master the latest in low-power design and semiconductor technologies. This program ensures you're equipped with skills that top employers demand in today’s fast-paced tech industry.</p>
                   
                     </div>
                  </div>
               </div>
            </section>
            <section className="section course-objective">
               <div className="container course mt-2">
                  <div className="piece-content2">
                     <div className="clearfix">
                        <div className="heading-text">
                           <h2> Our Curriculum covers the most In-Demand Topics:</h2>
                        </div>
                     </div>
                     <h5><span class=""> Foundational Level </span></h5>
                     <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingOne">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"><strong> Module 1: </strong> Introduction to VLSI Design 
                              </button>
                           </h2>
                           <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                   <ul>
                                     <li>Topics: Evolution of VLSI, Moore’s Law, VLSI Design Flow, VLSI Technology (CMOS, Bipolar, BiCMOS) </li>
                                     <li>Lab Work: Basic CMOS design and simulation exercises to understand the foundational concepts of VLSI.</li>
                                   </ul>
                                  </div>
                           </div>
                        </div>
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingTwo">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"><strong>Module 2: </strong>   Semiconductor Fundamentals 
                              </button>
                           </h2>
                           <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                   <ul>
                                    <li>Topics: Basics of Semiconductors, P-N Junction Diodes, MOSFET Characteristics </li>
                                    <li> Lab Work: Characterization of MOSFETs and basic semiconductor devices to gain hands-on experience in material behavior. </li>
                                   </ul>
                                  </div>
                           </div>
                        </div>
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingThree">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> <strong>Module 3: </strong>  Digital Logic Design for VLSI 
                              </button>
                           </h2>
                           <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                              <div className="accordion-body"> 
                                    <ul>
                                       <li>Topics: Boolean Algebra, Logic Gates, Flip-Flops, Combinational and Sequential Circuits, CMOS Logic (Static and Dynamic) 
                                       </li>
                                       <li>Lab Work: Design and simulation of basic combinational and sequential circuits to understand logic at the circuit</li>
                                    </ul>

                              </div>
                           </div>
                        </div>
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="headingfour">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapseThree"><strong> Module 4: </strong> FPGA-Based Design 
                              </button>
                           </h2>
                           <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                              <div className="accordion-body"> 
                                 <ul>
                                    <li>Topics: Introduction to FPGA and Configurable Logic, FPGA Architecture (LUTs, BRAM, DSPs), RTL Design (Verilog, VHDL) 
                                   </li>
                                    <li> Lab Work: FPGA-based design projects and simulations to develop skills in hardware design and simulation.</li>
                                 </ul>
                                  </div>
                           </div>
                        </div>                                        
                     </div>





                 <h5 className='mt-4'><span class=""> Advanced Level </span></h5>

                     <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="heading21">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse21" aria-expanded="true" aria-controls="collapse21"><strong> Module 1: </strong> Semiconductor Fundamentals (Advanced)  
                              </button>
                           </h2>
                           <div id="collapse21" className="accordion-collapse collapse show" aria-labelledby="heading21" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                   <ul>
                                     <li>Topics: MOS Capacitance, I-V Characteristics, Scaling, and Short Channel Effects.  </li>
                                     <li>Objective: Deepen your understanding of semiconductor properties and their impact on chip design. </li>
                                   </ul>
                                  </div>
                           </div>
                        </div>

                        <div className="accordion-item">
                           <h2 className="accordion-header" id="heading22">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse22" aria-expanded="false" aria-controls="collapse22"><strong>Module 2: </strong>   Digital Logic Design for VLSI (Advanced)  
                              </button>
                           </h2>
                           <div id="collapse22" className="accordion-collapse collapse" aria-labelledby="heading22" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                   <ul>
                                    <li>Topics: Power, Delay, and Area Optimization.  </li>
                                    <li>Objective: Learn optimization techniques for reducing power consumption and improving circuit performance.  </li>
                                   </ul>
                                  </div>
                           </div>
                        </div>
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="heading33">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse33" aria-expanded="false" aria-controls="collapse33"> <strong>Module 3: </strong>  VLSI Circuit Design 
                              </button>
                           </h2>
                           <div id="collapse33" className="accordion-collapse collapse" aria-labelledby="heading33" data-bs-parent="#accordionExample">
                              <div className="accordion-body"> 
                                    <ul>
                                       <li>Topics: Stick Diagrams, Layouts, Circuit Design Techniques, Timing, and Power Analysis.  
                                       </li>
                                       <li>Lab Work: Design and layout of intermediate complexity circuits to apply theory to real-world designs. </li>
                                    </ul>

                              </div>
                           </div>
                        </div>
                        <div className="accordion-item">
                           <h2 className="accordion-header" id="heading44">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse44" aria-expanded="false" aria-controls="collapse44"><strong> Module 4: </strong> Physical Design Automation  
                              </button>
                           </h2>
                           <div id="collapse44" className="accordion-collapse collapse" aria-labelledby="heading44" data-bs-parent="#accordionExample">
                              <div className="accordion-body"> 
                                 <ul>
                                    <li>Topics: Physical Design Flow (Partitioning, Placement, Routing), Standard Cells, Cell Libraries, DRC, and LVS.  
                                   </li>
                                    <li> Lab Work: Use physical design tools for layout verification and hands-on application of design principles. </li>
                                 </ul>
                                  </div>
                           </div>
                        </div>                                        
                     </div>













<h5 className='mt-4'><span class=""> Advanced Pro Level </span></h5>

<div className="accordion" id="accordionExample">
   <div className="accordion-item">
      <h2 className="accordion-header" id="heading55">
         <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse55" aria-expanded="true" aria-controls="collapse55"><strong> Module 1: </strong> VLSI Circuit Design (Advanced) 
         </button>
      </h2>
      <div id="collapse55" className="accordion-collapse collapse show" aria-labelledby="heading55" data-bs-parent="#accordionExample">
         <div className="accordion-body">
              <ul>
                <li>Topics: Floorplanning and Placement.   </li>
                <li>Objective: Learn to organize and optimize the layout of complex circuits for efficient performance.  </li>
              </ul>
             </div>
      </div>
   </div>

   <div className="accordion-item">
      <h2 className="accordion-header" id="heading66">
         <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse66" aria-expanded="false" aria-controls="collapse66"><strong>Module 2: </strong>   Physical Design Automation (Advanced) 
         </button>
      </h2>
      <div id="collapse66" className="accordion-collapse collapse" aria-labelledby="heading66" data-bs-parent="#accordionExample">
         <div className="accordion-body">
              <ul>
               <li>Topics: Power Grid Design, IR Drop Analysis, Signal Integrity (Crosstalk, IR Drop).  </li>
               <li>Objective: Understand advanced design automation techniques to optimize power delivery and signal quality.  </li>
              </ul>
             </div>
      </div>
   </div>
   <div className="accordion-item">
      <h2 className="accordion-header" id="heading77">
         <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse77" aria-expanded="false" aria-controls="collapse77"> <strong>Module 3: </strong>  System-on-Chip (SoC) Design (Advanced) 
         </button>
      </h2>
      <div id="collapse77" className="accordion-collapse collapse" aria-labelledby="heading77" data-bs-parent="#accordionExample">
         <div className="accordion-body"> 
               <ul>
                  <li>Topics: SoC Memories (DRAM, SRAM, Flash), SoC Power and Clock Management.   
                  </li>
                  <li>Objective: Learn how to manage memory and power in SoC designs for optimal performance.  </li>
               </ul>

         </div>
      </div>
   </div>
   <div className="accordion-item">
      <h2 className="accordion-header" id="heading88">
         <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse88" aria-expanded="false" aria-controls="collapse88"><strong> Module 4: </strong> Analog and Mixed-Signal Design   
         </button>
      </h2>
      <div id="collapse88" className="accordion-collapse collapse" aria-labelledby="heading88" data-bs-parent="#accordionExample">
         <div className="accordion-body"> 
            <ul>
               <li>Topics: Design of Amplifiers, ADCs, DACs, Mixed-Signal IC Design Techniques. </li>
               <li> Objective: Develop expertise in the integration of analog and digital components for mixed-signal systems. </li>
            </ul>
             </div>
      </div>
   </div>  

   <div className="accordion-item">
      <h2 className="accordion-header" id="heading99">
         <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse99" aria-expanded="false" aria-controls="collapse99"><strong> Module 5: </strong> Low Power VLSI Design    
         </button>
      </h2>
      <div id="collapse99" className="accordion-collapse collapse" aria-labelledby="heading99" data-bs-parent="#accordionExample">
         <div className="accordion-body"> 
            <ul>
               <li>Topics: Power Dissipation in CMOS, Dynamic and Static Power Reduction Techniques, Power Management in SoC.  </li>
            <li> Objective: Learn techniques to design low-power circuits and manage power consumption in SoC designs. </li>
            </ul>
             </div>
      </div>
   </div> 


    <div className="accordion-item">
      <h2 className="accordion-header" id="heading35">
         <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse35" aria-expanded="false" aria-controls="collapse35"><strong> Module 6: </strong> Memory Design  
         </button>
      </h2>
      <div id="collapse35" className="accordion-collapse collapse" aria-labelledby="heading35" data-bs-parent="#accordionExample">
         <div className="accordion-body"> 
            <ul>
               <li>Topics: Types of Memory (SRAM, DRAM, Flash), Memory Design Flow, Memory Access and Management in SoCs.   </li>
               <li>Objective: Gain skills in designing efficient memory systems for high-performance SoC architectures. </li>
            </ul>
             </div>
      </div>
   </div> 



   <div className="accordion-item">
      <h2 className="accordion-header" id="heading36">
         <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse36" aria-expanded="false" aria-controls="collapse36"><strong> Module 7: </strong> Semiconductor Fabrication and Manufacturing 
         </button>
      </h2>
      <div id="collapse36" className="accordion-collapse collapse" aria-labelledby="heading36" data-bs-parent="#accordionExample">
         <div className="accordion-body"> 
            <ul>
               <li>Topics: Overview of Semiconductor Manufacturing, Wafer Fabrication (Oxidation, Lithography, Etching), Process Integration.    </li>
               <li>Objective: Understand the entire semiconductor fabrication process, including advanced nodes (7nm, 5nm, 3nm), and India’s semiconductor ecosystem. </li>
            </ul>
             </div>
      </div>
   </div> 


   <div className="accordion-item">
      <h2 className="accordion-header" id="heading351">
         <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse351" aria-expanded="false" aria-controls="collapse35"><strong> Module 8: </strong> Advanced VLSI Concepts  
         </button>
      </h2>
      <div id="collapse351" className="accordion-collapse collapse" aria-labelledby="heading351" data-bs-parent="#accordionExample">
         <div className="accordion-body"> 
            <ul>
               <li>Topics: 3D ICs, Through-Silicon Vias (TSV), FinFET, Emerging Device Technologies, Machine Learning for VLSI, Quantum Computing.   </li>
               <li>Objective: Explore cutting-edge VLSI topics and their applications in modern and future chip designs.  </li>
            </ul>
             </div>
      </div>
   </div> 




   <div className="accordion-item">
      <h2 className="accordion-header" id="heading39">
         <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse39" aria-expanded="false" aria-controls="collapse39"><strong> Module 9: </strong> Lab and Practical Applications 
         </button>
      </h2>
      <div id="collapse39" className="accordion-collapse collapse" aria-labelledby="heading39" data-bs-parent="#accordionExample">
         <div className="accordion-body"> 
            <ul>
               <li>Topics: Power and Performance Optimization, Layout Design, DRC/LVS Check, Advanced Circuit Simulation.    </li>
               <li>Projects: Design and Optimization of a Complete SoC, Advanced VLSI Concepts Implementation (e.g., 3D ICs, FinFET), Industry-Relevant Projects (Low Power Design, High-Speed Interfaces).   </li>
            </ul>
             </div>
      </div>
   </div> 



















</div>















                  </div>
               </div>
            </section>
          
            <section className="faqScen faqScenbg mb-0 pt-5 pb-5">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12 hading-new-2">
                        <h2 className="mb-4 mt-4"> Frequently Asked Questions </h2>
                     </div>
                     <div className="bg_tabsfaq1">
                        <div className="row">
                           <div className="col-md-3">
                               <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                 <button className="nav-link active mt-0" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                     <span className="imgs">
                                       <img src={require(`../assets/images/a1_black.png`)} className="img-fluid img1" alt="Skillible- FAQ- General Questions" />
                                       <img src={require(`../assets/images/a1_white.png`)} className="img-fluid img2" alt="Skillible- FAQ- General Questions" />
                                    </span> 
                                    <span>General Questions</span>
                                 </button>
                                 <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                     <span className="imgs">
                                       <img src={require(`../assets/images/a2_black.png`)} className="img-fluid img1" alt="Skillible- FAQ- Fees & Income" />
                                       <img src={require(`../assets/images/a2_white.png`)} className="img-fluid img2" alt="Skillible- FAQ- Fees & Income" />
                                    </span> 
                                    <span> Fees & Income </span>
                                 </button>
                                 <button className="nav-link mb-0" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                     <span className="imgs">
                                       <img src={require(`../assets/images/a3_black.png`)} className="img-fluid img1" alt="Skillible- Admission Queries" />
                                       <img src={require(`../assets/images/a3_white.png`)} className="img-fluid img2" alt="Skillible- Admission Queries" />
                                    </span> 
                                    <span>Admission Queries </span>
                                 </button>
                              </div> 
                           </div>
                           <div className="col-md-9">
                              <div className="tab-content general_questions" id="v-pills-tabContent">
                                 <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                    <div className="general-s">
                                       <h3>General Questions</h3>
                                       <div className="">
                                          <div className="accordion" id="accordionExample">
                                             <div className="accordion-item mb-4" >
                                                <h2 className="accordion-header" id="heading91">
                                                   <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse91" aria-expanded="true" aria-controls="collapse91"> 1. What is VLSI, and why is it important?
                                                   </button>
                                                </h2>
                                                <div id="collapse91" className="accordion-collapse collapse show" aria-labelledby="heading91" data-bs-parent="#accordionExample">
                                                   <div className="accordion-body">
                                                      <p> VLSI (Very-Large-Scale Integration) refers to the process of creating integrated circuits by combining thousands or millions of transistors into a single chip. It’s crucial for the design and development of microprocessors, memory chips, sensors, and other semiconductor devices that power modern electronic devices like smartphones, computers, medical equipment, and automotive systems. Without VLSI, the advancement of technology as we know it would not be possible.
                                                      </p>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="accordion-item">
                                                <h2 className="accordion-header" id="heading93">
                                                   <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse93" aria-expanded="false" aria-controls="collapse93"> 2. What is the duration of the VLSI course, and how is it structured?
                                                   </button>
                                                </h2>
                                                <div id="collapse93" className="accordion-collapse collapse" aria-labelledby="heading93" data-bs-parent="#accordionExample">
                                                   <div className="accordion-body">
                                                      <p> The VLSI course is a 12-week program, structured to cover everything from the basics to advanced concepts. It is divided into three main stages:  </p>
                                                   
                                                   <ul>
                                                      <li>Beginner: Introduces the fundamentals of semiconductor technology and VLSI design flow.</li>
                                                      <li>Intermediate: Covers more detailed aspects such as FPGA-based design, physical design, and SoC concepts.</li>
                                                   <li>Advanced
                                                   : Focuses on complex topics like low power design, memory systems, and semiconductor fabrication. The course includes live masterclasses, lab work, and hands-on projects.</li>
                                                   </ul>
                                                  
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                    <div className="general-s">
                                       <h3> Fees & Income  </h3>
                                       <div className="">
                                          <div className="accordion" id="accordionExample">
                                             <div className="accordion-item">
                                                <h2 className="accordion-header" id="heading1">
                                                   <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1"> 1. Is the VLSI course free or paid?
                                                   </button>
                                                </h2>
                                                <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample">
                                                   <div className="accordion-body">
                                                      <p>
                                                      The VLSI course is a paid program. The fee covers all learning materials, access to industry tools, live masterclasses, and project-based training. This ensures that you get the best education and practical experience, making you job-ready in the VLSI industry.
                                                      </p>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                    <div className="general-s">
                                       <h3>Application Questions </h3>
                                       <div className="">
                                          <div className="accordion" id="accordionExample">
                                             <div className="accordion-item mb-4">
                                                <h2 className="accordion-header" id="heading1">
                                                   <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">  1. How can I apply for the VLSI course?
                                                   </button>
                                                </h2>
                                                <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample">
                                                   <div className="accordion-body">
                                                      <p>You can apply for the VLSI course by visiting our website and filling out the online application form. </p>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="accordion-item">
                                                <h2 className="accordion-header" id="heading2">
                                                   <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">  2. Are there any prerequisites for enrolling in the VLSI course?
                                                   </button>
                                                </h2>
                                                <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionExample">
                                                   <div className="accordion-body">
                                                         <p>
                                                         While the course is designed for students with a background in electronics and electrical engineering, a basic understanding of
                                                               digital electronics, circuit theory, and semiconductor fundamentals
                                                               is recommended. If you are new to these areas, we suggest reviewing introductory materials or taking a preparatory course before enrolling.
                                                         </p>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
   </div>
</React.Fragment>
)
}
import React from 'react'
import HeaderHome from './HeaderHome'
import DataCollection from './DataCollection';
export default function PrivacyPolicy({ isModal }) {

  return (

    <React.Fragment>
      {isModal === undefined ? <>
        <HeaderHome />

        <section className="banner-home-contact contactUsBanner">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="text-banner-contact">
                  <h1>Skillible - Privacy Policy</h1>
                  <p>This Privacy Policy Will Guide .....</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </> : ""}
      <section className="conatct-section contact-formsce contact-formsce1">
        <div className="container">
          <div className="row">
            <div className="col-mc-12">
              <div className="bg_contactForm">
                <div className="row">
                  {isModal !== true &&
                    <div className="col-md-12 pb-4 text-start hding2">
                      <h2>Privacy <span>Policy</span>
                      </h2>
                    </div>
                  }
                  <div className="col-md-12 skillible-privacy">
                    <p className=""> The
                      <a href="http://skillible.io/"> www.skillible.io </a> website offers a service or services (owned and operated by Neerinfo Solutions Private Limited).
                    </p>
                    <p className="mb-4"> The information on this website is intended to be useful to visitors. Until otherwise specified, the text and images on this site, in addition to all other items, are secured by Neerinfo Solutions' copyrights. Neerinfo Solutions Private Limited is under copyright protection. Every right is secured. All indicated trademarks are held by their respective owners. </p>
                    <p className="mb-4">
                      <strong>Purpose of collecting data – </strong> Any personal information that is provided to us while accessing our website is safeguarded by us. We think it's crucial for you to comprehend how we handle any personal details we might obtain from your interactions with us. You can have better peace of mind knowing that your personal information is secure and protected thanks to this privacy policy. "You" refers to you, the website/application user, and "Yourself" is to be interpreted in that context. Accordingly, "We" / "Us" Skillible and "Our" ought to be understood. The term "Users" denotes to all Website/application users collectively or individually, depending upon the circumstances.
                    </p>
                    <p className="mb-4">
                      <strong>Eligibility Criteria - </strong> This privacy statement is intended for everyone who visits this website, consumes its services, engages in training, or collaborates with it.
                    </p>
                    <DataCollection />

                    <div className="row">
                      <div className="col-md-12 mt-2">
                        <div className="text-center hdbtp1 head-skill mb-0">
                          <h2 className='skillible-trems'>
                            <span>Other Linked Services</span>
                          </h2>
                          <p>
                            <span>Our website might have integrations or links to other services like Facebook, Twitter, LinkedIn, and other media platforms, all of which may have information practices that differ from ours. Visitors should review the privacy policies for these other services because we have no control over the data that is sent to or collected by these outside parties. </span>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="">
                          <div className="pp4ew1 pp4ew1W psdb psdblin1 pric mt-4">
                            <div className="pp4ew2">
                              <div className="dat4sr1">
                                <p>
                                  <span>Acceptance of the Policy</span> You hereby recognize and irrevocably accept the terms of this policy by accessing, registering for, or contributing content to our website. Do not use our website or services or submit any personal information here if you disagree with this Policy.
                                </p>
                              </div>
                              <div className="dat4sr1">


                                <p>
                                  <span>Accessing or correcting your personal information  </span> You can access the personal information we hold about you by contacting us using the information below or the contact us function. Sometimes, we may not be able to provide you with access to all of your personal information and, where this is the case, we will tell you why. We may also need to verify your identity when you request your personal information. If you think that any personal information we hold about you is inaccurate, please contact us and we will take reasonable steps to ensure that it is corrected.
                                </p>


                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="">
                          <div className="pp4ew1 pp4ew1W psdb psdblin1 pric mt-4">
                            <div className="pp4ew2">
                              <div className="dat4sr1">
                                <p>
                                  <span>Changes/Updates in Privacy Policy</span> You should frequently check these policies since we may occasionally update them. You will be deemed to have accepted the privacy statement in effect at the time you use the Website.
                                </p>
                              </div>
                              <div className="dat4sr1">
                                <p>
                                  <span>Links </span> Our website may contain links to websites operated by third parties. Those links are provided for convenience and may not remain current or be maintained. Unless expressly stated otherwise, we are not responsible for the privacy practices of, or any content on, those linked websites, and have no control over or rights in those linked websites. The privacy policies that apply to those other websites may differ substantially from our Privacy Policy, so we encourage individuals to read them before using those websites.
                                </p>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="col-md-6">
                        <div className="">
                          <div className="pp4ew1 pp4ew1W psdb psdblin1 pric">
                            <div className="pp4ew2">
                              <div className="dat4sr1">

                                <p>
                                  <span>Governing Law </span> This Privacy Policy is governed by and operated in accordance with the laws of India. If any of the parties wish to seek legal recourse, they may do so by using the courts of law in New Delhi.
                                </p>


                              </div>
                              <div className="dat4sr1">

                                <p>
                                  <span>Grievance Officer </span> The Grievance Officer's name and contact information are listed below in compliance with the Information Technology Act of 2000 and the rules promulgated thereunder:
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="">
                          <div className="pp4ew1 pp4ew1W psdb psdblin1 pric ">
                            <div className="pp4ew2">
                              <div className="dat4sr1">
                                <p>
                                  <span>Raising a complaint </span> If you think we have breached the Privacy Act, or you wish to make a complaint about the way we have handled your personal information, you can contact us using the details set out below. Please include your name, email address, and/or telephone number and clearly describe your complaint. We will acknowledge your complaint and respond to you regarding your complaint within a reasonable period of time. If you think that we have failed to resolve the complaint satisfactorily, we will provide you with information about the further steps you can take.
                                </p>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="global-partner global_overflow_visble mb-4">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            {isModal !== true ?
                              <div className="global_ptnbg-bdr">
                                <div className="row">
                                  <div className="col-md-12 hding2 bttopspc text-center head-skill">
                                    <h2 className='skillible-trems'><span>Contact Us</span></h2>
                                  </div>
                                  <div className="col-md-12">

                                    <div className="you_payless">
                                      <div className="that_studio1">
                                        <p>For further information about our Privacy Policy or practices, or to access or correct your
                                          personal information, or make a complaint, please contact us using the details set out below:
                                        </p>
                                      </div>
                                      <div className="skillbile-contact">
                                        <h4> <strong> Email address: </strong> <a href="mailto:support@skillible.io">support@skillible.io</a> </h4>
                                        <span className="pay-less-img">
                                          <img src={require(`../assets/images/pay-less-img2.png`)}
                                            className="img-fluid" alt="" /></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              :
                              <div className="global-partner global_overflow_visble ">
                                <div className="container">
                                  <div className="popup-footer-contact">
                                    <div className="row">
                                      <div className="col-md-12 hding2 bttopspc text-center head-skill">
                                        <h2><span>Contact Us</span></h2>
                                        <p>For further information about our Privacy Policy or practices, or to access or correct your
                                          personal information, or make a complaint, please contact us using the details set out below:
                                        </p>
                                        <h4> <strong> Email address: </strong> <a href="mailto:support@skillible.io">support@skillible.io</a> </h4>

                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>




                  </div>
                </div>
              </div>
            </div>
            </div>
            </div>
          </section>

        </React.Fragment>
        )
}
import React from 'react'
import HeaderHome from './HeaderHome'
export default function Sitemap() {

   return (
      <React.Fragment>
         <HeaderHome />
         <section className="industries-banner she-leads">
            <div className="container">
               <div className="row">
                  <div className="col-md-4 col-4 text-end">
                     <div className="text-banner">
                        <span className="site-laptop">
                           <img src={require(`../assets/images/sitemap.png`)} className="img-fluid" alt="Explore Skillible's offerings " /></span>
                     </div>
                  </div>
                  <div className="col-md-3 col-8 text-center">
                     <div className="text-sitebanner">
                        <h1>Sitemap</h1>
                     </div>
                  </div>

                  <div className="col-md-5 text-center">
                     <div className="img-banner pair-imgdv ndvt-2 hire-from-us-s">
                        <span className="pair-img"><img src={require(`../assets/images/pairw.png`)} className="img-fluid" alt="" /></span>
                        <div className="pairbanner_girl">
                           <img src={require(`../assets/images/banner-laptop.png`)} className="img-fluid" alt="Skillible Sitemap" />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <section className="section sitemap_bg mt-5 mb-5">
            <div className="container">
               <div className="row">
                  <div className="col-md-12 mb-0 text-left">
                  </div>
                  <div className="col-sm-6 nav_pding col-lg-4 border-right mb-4">
                     <h6>
                        <img src={require(`../assets/images/all-Courses.png`)} className="img-fluid" alt="All Courses" />  All Courses  <i className="fa fa-angle-down"></i> </h6>
                     <ul>
                        <li><a className="dropdown-item" href="/genai"><i className="fa fa-angle-right"></i>  Gen AI</a></li>
                        <li><a className="dropdown-item" href="/Salesforce"><i className="fa fa-angle-right"></i>  Salesforce Developer</a></li>
                        <li><a className="dropdown-item" href="/Fullstack"><i className="fa fa-angle-right"></i>  Fullstack Developer</a></li>
                     </ul>
                  </div>
                  <div className="col-sm-6 nav_pding nav_sm_menu_bg col-lg-4 border-right mb-4">
                     <h6>
                        <img src={require(`../assets/images/program.png`)} className="img-fluid" alt="Programs" /> Programs  <i className="fa fa-angle-down"></i> </h6>
                     <ul>
                        <li><a className="dropdown-item" href="/SheTrails"><i className="fa fa-angle-right"></i>  SHE Trails</a></li>
                        <li><a className="dropdown-item" href="/TechScaller"><i className="fa fa-angle-right"></i> Tech Scaller</a></li>
                        <li><a className="dropdown-item" href="/virtual_internship"><i className="fa fa-angle-right"></i>  Virtual Internship</a></li>
                     </ul>
                  </div>
                  <div className="col-sm-6 nav_pding col-lg-4 border-right mb-4">
                     <h6><img src={require(`../assets/images/talent-hunt.png`)} className="img-fluid" alt="Talent Hunt" />  Talent Hunt <i className="fa fa-angle-down"></i> </h6>
                     <ul>
                        <li><a className="dropdown-item" href="/HireFromUs"><i className="fa fa-angle-right"></i> Hire From Us</a></li>
                        <li><a className="dropdown-item" href="/HireTrainDeploy"><i className="fa fa-angle-right"></i> Hire Train Deploy</a></li>
                     </ul>
                  </div>
                  <div className="col-sm-6 nav_pding nav_sm_menu_bg col-lg-4 border-right mb-4">
                     <h6> <img src={require(`../assets/images/help-zone.png`)} className="img-fluid" alt="Help zone" />  Help zone <i className="fa fa-angle-down"></i></h6>
                     <ul>
                        <li><a className="dropdown-item" href="/Faq"><i className="fa fa-angle-right"></i> FAQ</a></li>
                     </ul>
                  </div>
                  <div className="col-sm-6 nav_pding col-lg-4 border-right mb-4">
                     <h6> <img src={require(`../assets/images/other.png`)} className="img-fluid" alt="Other" />  Other  <i className="fa fa-angle-down"></i></h6>
                     <ul>
                        <li><a className="dropdown-item" href="/PrivacyPolicy"><i className="fa fa-angle-right"></i>  Privacy Policy </a></li>
                        <li><a className="dropdown-item" href="/TermsOfUse"><i className="fa fa-angle-right"></i>  Terms & Conditions </a></li>
                        <li><a className="dropdown-item" href="/Career"><i className="fa fa-angle-right"></i>  Careers </a></li>
                     </ul>
                  </div>

               </div>
            </div>
         </section>
      </React.Fragment>
   )
}
import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import HeaderHome from './HeaderHome';
export default function SoftwareTesting() {

  return (
    <React.Fragment>
      <HeaderHome />
      <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <title>Top Full Stack Developer Course in India | Skillible</title>
          <meta name="description" content="Learn full stack development from top industry professionals. Get job placement after completion. Enrol for the full stack developer course today!" />
          <link rel="canonical" href="http://skillible.io/" />
          <meta property="og:title" content="Top Full Stack Developer Course in India | Skillible" />
          <meta property="og:description" content="Learn full stack development from top industry professionals. Get job placement after completion. Enrol for the full stack developer course today!" />
          <meta property="og:url" content="http://skillible.io/" />
          <meta property="og:type" content="website" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:site_name" content="Skillible" />

        </Helmet>
      </HelmetProvider>
      <div>
        <section className="banner-home service-banner servs-edt testing-page">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="text-banner">
                  <h1>
                    Walk into Skillible & Walk
                    <br />

                    Out with Best Automation Testing

                    Roles with <span> Assured Placement!!  </span>
                  </h1>
                  <p className="banner-para3">
                    Skillible provides automation testing courses ranging from beginner to advanced levels. We allow you to be trained by experienced automation testing professionals and offer you with an opportunity to receive industry-relevant training with hands-on experience. Each of our courses includes a live project to help you gain real-world skills.
                  </p>
                  <p className="banner-para3">
                    <strong> Only Skillible can give you the confidence to work as a skilled professional!  </strong>
                  </p>
                  <ul className="get-quote mb-5">
                    <li>
                      <Link to="#" className="active" data-bs-toggle="modal" data-bs-target="#apply-form">
                        <button type="button">
                          Apply Now
                        </button>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 text-center">
                <div className="img-banner servs-img1">
                  <div className="service-bannerimg" >
                    <span>
                      <img src={require(`../assets/images/testing.webp`)}
                        className="img-fluid" alt="" />
                    </span>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-blue youjust-work">
          <div className="greenline-bg1">
            <img src={require(`../assets/images/bg-greenline.png`)} className="img-fluid" alt="" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <h2> You Just Work. We'll take care of your worries. </h2>
                <div className="aplus_box1 text-end">
                  <div className="tutor_havtx">
                    <span>
                      <img src={require(`../assets/images/logo.png`)} className="img-fluid" alt="" />
                    </span>
                    <div className="text-tutorscn">
                      <h4> Minimum <span> Qualification </span>
                      </h4>
                      <p> GRADUATION </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7 ps-0 pe-0">
                <div className="">
                  <img src={require(`../assets/images/right-side-img.png`)} className="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="full-stack-page sce991 mt-6">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="hding2 colr-spanh2">
                  <h2 className="mb-2"> Our <span> Reality-Based </span></h2>

                  <p className="mb-4">Learning  <strong> Approach  </strong> Is The Key To Grab Your Dream Role
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row displ-lrt1">
              <div className="col-md-6 float-end reality-based">
                <div className="inter-li1">
                  <span>
                    <img src={require(`../assets/images/interactive-workshops.png`)} className="img-fluid" alt="" />
                  </span>
                  <div className="">
                    <p>  Interactive workshops and opportunity to connect with industry seasoned professionals.
                    </p>
                  </div>
                </div>
                <div className="inter-li1 inter-li2">
                  <span>
                    <img src={require(`../assets/images/placement.png`)} className="img-fluid" alt="" />
                  </span>
                  <div className="">
                    <p>  Placement support & career guidance from our experienced career experts & mentors
                    </p>
                  </div>
                </div>
                <div className="inter-li1 inter-li3">
                  <span>
                    <img src={require(`../assets/images/record-htd.png`)} className="img-fluid" alt="" />
                  </span>
                  <div className="">
                    <p>Industry vetted Curriculum to gain deep insights of Automation Testing
                    </p>
                  </div>
                </div>
                <div className="inter-li1 inter-li4">
                  <span>
                    <img src={require(`../assets/images/project5.png`)} className="img-fluid" alt="" />
                  </span>
                  <div className="">
                    <p> Hands-on Project Based learning & quick doubt resolution for a wholesome learning experience.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 text-center float-start">
                <span>
                  <img src={require(`../assets/images/cource-overview.webp`)} className="img-fluid" alt="" />
                </span>
              </div>
            </div>
          </div>
        </section>
        <section className="opi-services-sec sce991  pb-2">
          <div className="container">
            <div className="row displ-lrt1">
              <div className="col-md-6 opi-services float-start">
                <div className="opi-services-img">
                  <img src={require(`../assets/images/software-automation.webp`)} className="img-fluid opi-comm-img" alt="" />
                </div>
              </div>
              <div className="col-md-6   float-end">
                <div className="opi-servicesText ps-0 hding2">
                  <h2>Our Course Overview </h2>
                  <ul className="">
                    <li>
                      <div className="mb-2">  Skillible's software automation testing program is specifically designed to improve software quality by increasing the depth and scope of testing. With the advent of the latest technologies, testing plays a crucial role to make any software functional. So, to deliver high-quality software products, you need to perform software testing not only in the core phase but also in the development and acceptance phases. The scope of software testing has increased as per the demand of the market and there can never be a dearth or absence of career opportunities in Software Testing.
                        The testing industry has now become quite competitive and productive and with Skillible you can achieve a new paradigm in the same. </div>
                      <div className="">
                      </div>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
<section className="orderForm keyArea">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 lft">
                <h2>Skills You will Learn  </h2>
              </div>
              <div className="col-lg-6">
                <div className="banRt">
                  <ul>
                    <li>
                      <span className="icon">
                        <img src={require(`../assets/images/angularjs.png`)} className="img-fluid" alt="" />
                      </span>Automation Testing
                    </li>
                    <li>
                      <span className="icon">
                        <img src={require(`../assets/images/bootStrap.png`)} className="img-fluid" alt="" />
                      </span>Selenium
                    </li>
                    <li>
                      <span className="icon">
                        <img src={require(`../assets/images/javascript-1.png`)} className="img-fluid" alt="" />
                      </span>API Testing
                    </li>
                    <li>
                      <span className="icon">
                        <img src={require(`../assets/images/sql.png`)} className="img-fluid" alt="" />
                      </span>Software Testing
                    </li>
                    <li>
                      <span className="icon">
                        <img src={require(`../assets/images/react.png`)} className="img-fluid" alt="" />
                      </span>Automation
                    </li>
                    <li>
                      <span className="icon">
                        <img src={require(`../assets/images/docker.png`)} className="img-fluid" alt="" />
                      </span>Testing Tools
                    </li>
                    <li>
                      <span className="icon">
                        <img src={require(`../assets/images/redux.png`)} className="img-fluid" alt="" />
                      </span>Git
                    </li>
                    <li>
                      <span className="icon">
                        <img src={require(`../assets/images/nodejs-icon-1.png`)} className="img-fluid" alt="" />
                      </span>Pipeline
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="how-t-works">
          <div className="bg-circlsce1"></div>
          <div className="bg-circlsce2"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="infrotin-projt text-center mt-5 testing-howitwork">
                  <h2> How Does This Program Work  </h2>
                  <ul>

                    <li>
                      <span>
                        <img src={require(`../assets/images/submit-application.png`)} className="img-fluid" alt="" />
                      </span>
                      <div className=""><strong>Step 1: </strong> Submit your application   </div>
                    </li>

                    <li>
                      <div className="infor-outline">
                        <img src={require(`../assets/images/top-arrow.svg`)} className="img-fluid" alt="" />
                      </div>
                      <span>

                        <img src={require(`../assets/images/candidate-reach.png`)} className="img-fluid" alt="" />
                      </span>
                      <div className=""><strong>Step 2:  </strong> Review of your Application </div>
                    </li>

                    <li>
                      <div className="infor-outline2">
                        <img src={require(`../assets/images/botton-arrow.svg`)} className="img-fluid" alt="" />
                      </div>
                      <span>
                        <img src={require(`../assets/images/candidate-eligible.png`)} className="img-fluid" alt="" />
                      </span>
                      <div className=""><strong>Step 3: </strong> Screening Round  </div>
                    </li>
                    <li>
                      <div className="infor-outline">
                        <img src={require(`../assets/images/top-arrow.svg`)} className="img-fluid" alt="" />
                      </div>
                      <span>
                        <img src={require(`../assets/images/technical-assessment.png`)} className="img-fluid" alt="" />
                      </span>
                      <div className=""><strong> Step 4: </strong> Start your journey with Skillible   </div>
                    </li>

                  </ul>

                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="professional-services mt-5">
          <span className="boy-girl-img"></span>
          <div className="container">
            <div className="row">
              <div className="col-md-7 col-lg-7 ms-auto">
                <div className="pro-hours hding2 software-eligible">
                  <h2>Eligibility Criteria </h2>
                  <p>To gain admission to this Automation Testing Job Guarantee course, the applicant must: </p>
                </div>
                <div className="software-price">
                  <h2 className="Typography__StyledText-txjhuo-0 kQPqoH Typography__StyledTypography-txjhuo-1 elXzs">₹
                    <span font-weight="900" className="sdgt-soft">3,528
                      <span className="Price__SubtitleText-sc-8aygxa-10 fwjMfT">/month</span>
                    </span>
                  </h2>
                  <div className="Box-sc-1yamx5u-0 bPKObZ">
                    <p className="Typography__StyledText-txjhuo-0 bfkOxr Typography__StyledTypography-txjhuo-1 iZfCuE">
                      Total amount ₹
                      <span font-weight="900" className="Typography__CustomText-txjhuo-4 dJFZYs">
                        39,705
                      </span>
                    </p>
                    <p className="Typography__StyledText-txjhuo-0 bfkOxr Typography__StyledTypography-txjhuo-1 jlmnzm">
                      ₹ <span className="Typography__LineThrough-txjhuo-3 fqYKlA">52,940</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-lg-5 ms-auto">
                <div className="serv-24">
                  <div className="pro-hours hding2">
                    <h2>Eligibility Criteria </h2>
                    <p>To gain admission to this Automation Testing Job Guarantee course, the applicant must: </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    <section className="section course-objective mt-4 mb-5">
          <div className="container-fluid Curriculum width90pst">
            <div className="piece-content2">
              <div className="clearfix">
                <div className="heading-text">
                  <h2> Course Curriculum </h2>
                </div>
              </div>
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">  Part 0 | Introduction  </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <ul><li> Introduction to web development </li><li> Fundamental of web </li><li> VS Code installation and introduction </li><li> GIT introduction </li></ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> Part 1 | Frontend | HTML, CSS, Bootstrap </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <ul><li> HTML: table, form, div, media para etc </li><li> CSS: colour, font, border style etc. Background, inline, external, minimise etc </li><li> Bootstrap: Nav, grid, spacing, D Flex, Media </li><li> Project: Work on a project where you have show your creativity </li></ul>
                    </div>
                  </div>
                </div>
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingtwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetwo" aria-expanded="false" aria-controls="collapsetwo">  Part 2 | Frontend | Javascript  </button>
                  </h2>
                  <div id="collapsetwo" className="accordion-collapse collapse" aria-labelledby="headingtwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <ul><li> Javascript: Introduction of javasript with types, conditional statement, loops, console etc.
                      </li>
                        <li> Javascript: functions, array functions, variable scopes etc
                        </li>
                        <li> DOM: Introduction, manipulation, selecter method, events
                        </li>
                        <li> ES6: Introduction, keywords, Arrow functions etc
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">  Part 3 | Frontend | React  </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <ul><li> React: Introduction of react, structure of react, state, prop. </li><li> React: stateful, stateless, class based, functional componant. Componant lifecycle</li><li> React: Server side integrattion </li><li> React: Data managment </li><li> React: Handling multiple pages, Routing, SPA </li><li>Redux: How iit work</li><li>Unit testing</li><li>Project: Implemnet the learning of React in same project which done in Html session.</li></ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfour">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapseThree">  Part 4 | Backend | Mongo DB  </button>
                  </h2>
                  <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <ul><li> SQL and NoSql Concepts</li><li> Create and Manage MongoDB </li><li> MongoDB: Fundamentals </li><li> MongoDB: Curd Operations </li><li> MongoDB: Aggregation and other advance operation with pipeline. </li><li> MongoDB: Schema Desiign </li><li> MongoDB: Replication, sharding, administration, security </li><li> Project Design the database for the given application </li></ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfive">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapseThree">  Part 5 | Backend | Node js </button>
                  </h2>
                  <div id="collapsefive" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <ul><li>DSA: Data Structures: Arrays, Strings, Stacks, Queues</li><li>DSA: Time and Memory complexity</li><li>DSA: Recursion, Sorting, Searching, Two pointers</li><li> Node js: Overview, basic, setup Utilities</li><li> Node js: Console, Command Utilities</li><li> Node js: Modules, Concepts, Events </li><li> Node js with Express js, routing</li><li> Node js: Database Access </li><li>Node js: Rest API, testing with Postman</li><li>Unit testing</li><li>Project: Add the backend application in the project.</li></ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingsix">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">  Part 6 | Backend | Microservice  </button>
                  </h2>
                  <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <ul><li> Microservice Architecture understnading </li><li> Microserivce implementtation in Nodes js </li><li> Docker understanding </li><li> Deploy microservice using docker </li><li> Project: Convert the project into microservice and deploy using docker </li></ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingseven">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven">  Part 7 | Deployment </button>
                  </h2>
                  <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="headingseven" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <ul><li> Understanding of cloud </li><li> Application Deployment on AWS </li></ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingseven">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven">   Part 8 | Capston Project  </button>
                  </h2>
                  <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="headingseven" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p>Work on the industry project in a collaborative way which cover all the concept of a fullstack developer.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingseven">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven">    Part 9 | Interview preparation   </button>
                  </h2>
                  <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="headingseven" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p>Prepare you for the industry with all the concept with one to one session, mock interview which include technical and soft skills.

                      </p>
                    </div>
                  </div>
                </div>
               </div>
            </div>
          </div>
        </section>
      </div>

    </React.Fragment>
  )
}
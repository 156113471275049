export const SERVICE_URL = 'https://g6lzc565fc.execute-api.ap-south-1.amazonaws.com';
export const SERVICE_URL2 = 'http://ec2co-ecsel-1ntlv8pw5yh3u-587027176.ap-south-1.elb.amazonaws.com:8000';
const prod = {
    url: {
        baseUrl: 'https://prd-skl-site-backend.azurewebsites.net',
    }
};

const dev = {
    url: {
        baseUrl: 'https://skl-ws-api-test.azurewebsites.net'
       // baseUrl: 'http://192.168.1.12:8000'
    }
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
import React, { useEffect, useState } from "react";
import HeaderHome from "./HeaderHome";
import { Helmet } from "react-helmet";
import FloatingLabelInput from "./FloatingLabelInput";
import { Alert, Button, Card, Col, Modal, Row, Spin, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { config, LOCAL_URL } from "../constant";
import { saveAs } from 'file-saver';
import { getCurrentTimestamp } from "../utils/util";

const VerifyCertificate = () => {
    const { identifier } = useParams();
    const [id, setId] = useState('');
    let apiKey = process.env.REACT_APP_API_KEY;
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = useState([]);
    const [modalImage, setModalImage] = useState('');
    const [courseName, setCourseName] = useState('');
    const [selectedCI, setSelectedCI] = useState('');
    const [images, setImages] = useState([]);
    const [isDownloading, setIsDownloading] = useState(false);
    const [errorFlag, setErrorFlag] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [openPanel, setOpenPanel] = useState(false);
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const idPattern = /^[a-zA-Z0-9]+$/;
    const url = config.url.baseUrl;
    const inputHandler = (value) => {
        setInputValue(value.trim());
        validateInput(value);
        if (value === "") {
            setError('');
        }
        else {
        }
    };

    const validateInput = (input) => {

        if (emailPattern.test(input)) {
            setError('');
            setEmail(input);
            setId('');
            return true;
        } else if (idPattern.test(input)) {
            setId(input);
            setEmail('');
            setError('');
            return true;
        } else {
            setError('Please enter a valid ID or Email Address!');
            return false;
        }

    };


    const getCertificateData = async (email, id) => {
        const requestOptions = {
            headers: {
                "X-API-Key": apiKey
            }
        };
        let payload = {};
        if (email) {
            payload.email = email;
            payload.certificateId = '';
        } else if (id) {
            payload.certificateId = id;
            payload.email = '';
        }
        try {
            const result = await axios.post(`${url}/certificate/learner`, payload, requestOptions);
            if (result.status === 200) {
                setData(result.data.data);
                setErrorFlag(false);
                if(email) {
                    navigate(`/verifycertificate`);
                }
              else {
                navigate(`/verifycertificate/${id}`);
              }

            }
        }
        catch (error) {
            console.log(error);
            setErrorFlag(true);
            setErrorMessage(error.response?.data?.detail?.message);
        }
    }

    const generateFilename = (fileType) => {
        const timestamp = getCurrentTimestamp();
        return `Skillible_certificate_${timestamp}.${fileType}`;
    }

    const getImage = async (imageUrl, mediaType) => {
        const requestOptions = {
            headers: {
                "X-API-Key": apiKey
            },
            responseType: 'blob'
        };

        try {
            const response = await axios.get(`${url}/${imageUrl}${mediaType}`, requestOptions);
            if (mediaType === 'application/pdf') {
                const blob = new Blob([response.data], { type: response.data.type });
                const filename = generateFilename('pdf');
                saveAs(blob, filename);
            }
            return response.data;
        } catch (error) {
            console.error('Error fetching image data:', error);
            return null;
        }
    };

    useEffect(() => {
        const fetchImages = async () => {
            const imagePromises = data.map(item => getImage(item.imageUrl, 'image/jpeg'));
            const imageBlobs = await Promise.all(imagePromises);
            const imageUrls = imageBlobs.map(blob => URL?.createObjectURL(blob));
            setImages(imageUrls);
        };

        fetchImages();
    }, [data]);

    useEffect(() => {
        return () => {
            images.forEach(url => URL?.revokeObjectURL(url));
        };
    }, [images]);


    // useEffect(() => {
    //     if (identifier === inputValue) {
    //     if (identifier) {
    //       if (emailPattern.test(identifier)) {
    //         getCertificateData(identifier, '');
    //         setOpenPanel(true);
    //       } else if (idPattern.test(identifier)) {
    //         getCertificateData('', identifier);
    //         setOpenPanel(true);
    //       }
    //     } else if (inputValue === '') {
    //       if (idPattern.test(identifier)) {
    //         getCertificateData('', identifier);
    //         setOpenPanel(true);
    //       } else if (emailPattern.test(identifier)) {
    //         getCertificateData(identifier, '');
    //         setOpenPanel(true);
    //       }
    //     }
    // }

    // if (identifier && inputValue === '') {
    //     if (identifier) {
    //         if (emailPattern.test(identifier)) {
    //           getCertificateData(identifier, '');
    //           setOpenPanel(true);
    //         } else if (idPattern.test(identifier)) {
    //           getCertificateData('', identifier);
    //           setOpenPanel(true);
    //         }
    //       } else if (inputValue === '') {
    //         if (idPattern.test(identifier)) {
    //           getCertificateData('', identifier);
    //           setOpenPanel(true);
    //         } else if (emailPattern.test(identifier)) {
    //           getCertificateData(identifier, '');
    //           setOpenPanel(true);
    //         }
    //       }
    // }
    //   }, [identifier, inputValue, email, id]);

    useEffect(() => {
        const validateAndFetchData = (identifier) => {
            if (emailPattern.test(identifier)) {
                getCertificateData(identifier, '');
            } else if (idPattern.test(identifier)) {
                getCertificateData('', identifier);
            }
            setOpenPanel(true);
        };

        if (identifier) {
            validateAndFetchData(identifier);
        } else if (inputValue === '' && identifier) {
            validateAndFetchData(identifier);
        }
    }, [identifier, inputValue]);

    const submitHandler = async () => {
        if (inputValue) {
            const isValid = validateInput(inputValue);
            if (isValid) {
                setError(false);
                await getCertificateData(email, id);
                setOpenPanel(true);
            }
        } else {
            setError(true);
        }
    }

    const showModal = (courseName, imageUrl, CI, type) => {
        setSelectedCI(CI);
        setOpen(true);
        setLoading(true);
        setModalImage(imageUrl);
        setCourseName(courseName);
        setTimeout(() => {
            setLoading(false);
        }, 0);
    };

    const handleDownload = async () => {
        setIsDownloading(true);
        await getImage('certificate/learner-certificate?certificate_id=' + selectedCI + '&media_type=', 'application/pdf');
        setIsDownloading(false);
    }

    const loaderStyle = {
        padding: 50,
        background: 'rgba(0, 0, 0, 0.05)',
        borderRadius: 4,
    };
    const loader = <div style={loaderStyle} />;

    return (
        <>
            <HeaderHome />
            <Helmet>
                <meta charset="UTF-8" />
                <title>Simplify Your Tech Hiring Needs With Skillible</title>
                <meta name="description" content="Hire from our skillfully trained talent pool. We provide End-to-end talent development solutions, right from acquiring talent, training, to placement." />
                <link rel="canonical" href="http://skillible.io/" />
                <meta property="og:title" content="Skillible - We Help You Succeed In Your Career!" />
                <meta property="og:description" content="Hire from our skillfully trained talent pool. We provide End-to-end talent development solutions, right from acquiring talent, training, to placement." />
                <meta property="og:url" content="http://skillible.io/" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:site_name" content="Skillible" />
            </Helmet>
            <div className="verify-certificate-details">
                <div className="container pt-3">
                    <Row gutter={16}>
                        <Col span={10}>
                            <Card className="left-field" title="Verify a Skillible Certification">
                                <p>Use this form to quickly verify the authenticity of a Skillible's certification. Simply enter the learner's email address or the unique certification ID in the field below.</p>
                                <h5 className="card-title fw-bold mt-3 mb-2">Search By:</h5>
                                <ul>
                                    <li className="card-text"><strong>Certification ID: </strong>
                                        Enter the specific ID associated with the certificate.</li>
                                    <li className="card-text"><strong>Learner Email: </strong>
                                        Enter the learner's email address to view all their associated Skillible's certifications.</li>
                                </ul>
                                <div className="d-flex justify-content-space-between mt-4">
                                    <FloatingLabelInput
                                        label="Certification ID/Email Address"
                                        placeholder=""
                                        value={inputValue}
                                        type="text"
                                        onChange={inputHandler}
                                    />
                                    <Button onClick={submitHandler} disabled={inputValue === ''} style={{ width: '150px', borderRadius: '0 5px 5px 0', marginLeft: '-2px', height: '45px' }} type="primary" size="large">Verify</Button>
                                </div>
                                {error ? <Alert message={error} type="error" showIcon /> : ""}
                            </Card>
                        </Col>
                        <Col span={14} className="report-details">
                            {openPanel &&
                                <Card title="Certificate Reports">
                                    {errorFlag ? <Alert message={errorMessage} type="warning" showIcon /> :
                                        (
                                            data && data.map((item, index) => {
                                                console.log(item);
                                                return (
                                                    <div className="card-list mb-2" key={item.id || index}>
                                                        <div className="d-flex justify-content-between">
                                                            {images[index] ? <img
                                                                onClick={() => showModal(item.courseName, images[index], item?.certificateNumber, "image/jpeg")}
                                                                className="w-50 certificate-img"
                                                                src={images[index]}
                                                                alt="certificateImage"
                                                            /> :
                                                                <Spin className="loader-field" tip="Loading" size="large">
                                                                    {loader}
                                                                </Spin>
                                                            }
                                                            <div className="details w-100">
                                                                <div className="fields-list pb-2 d-flex justify-content-between">
                                                                    <div className="h6 m-0">Certificate ID</div>
                                                                    <div>{item?.certificateNumber}</div>
                                                                </div>
                                                                <div className="fields-list py-2 d-flex justify-content-between">
                                                                    <div className="h6 m-0">Course Name</div>
                                                                    <div>{item?.courseName}</div>
                                                                </div>
                                                                <div className="fields-list py-2 d-flex justify-content-between">
                                                                    <div className="h6 m-0">Learner Name</div>
                                                                    <div>{item?.learnerName}</div>
                                                                </div>
                                                                <div className="fields-list py-2 d-flex justify-content-between">
                                                                    <div className="h6 m-0">Completion Date</div>
                                                                    <div>{item?.completeDate}</div>
                                                                </div>
                                                                <div className="fields-list py-2 d-flex justify-content-between">
                                                                    <div className="h6 m-0">Generate Date</div>
                                                                    <div>{item?.generateDate}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="description-details">
                                                            <div className="h6">Course Description</div>
                                                            <p>
                                                                {item?.courseDescription}
                                                            </p>
                                                            <div className="modules-badge row">
                                                                    {item.modules.length > 0 ? (
                                                                        <div className="col-md-6">
                                                                            <h6 style={{color: '#000'}} className="mb-1">Modules</h6>
                                                                            {item.modules.map((list, index) => (
                                                                                <p key={index}>{list}</p>
                                                                            ))}
                                                                        </div>
                                                                    ) : null}
                                                               
                                                               
                                                                {item.badges.length > 0 ? (
                                                                        <div className="col-md-6">
                                                                            <h6 style={{color: '#000'}} className="mb-1">Badges</h6>
                                                                            {item.badges.map((list, index) => (
                                                                                <p key={index}>{list}</p>
                                                                            ))}
                                                                        </div>
                                                                    ) : null}
                                                               
                                                            </div>


                                                        </div>
                                                    </div>
                                                )
                                            }

                                            )
                                        )
                                    }
                                </Card>
                            }
                        </Col>
                    </Row>
                </div>
            </div>
            <Modal
                title={<h5>Certificate <span className="bar">|</span> {courseName}</h5>} width={700}
                footer={
                    <Button onClick={handleDownload} type="primary" size="large" disabled={isDownloading}>
                        {isDownloading ? "Please wait..." : "Download Certificate"}
                        {!isDownloading && <img style={{ width: '20px' }} src={require(`../assets/images/download-icon.png`)} alt="download" />}
                    </Button>
                }
                loading={loading}
                open={open}
                onCancel={() => setOpen(false)}>
                <img className="w-100" src={modalImage} alt="course" />
            </Modal>
        </>
    )
}

export default VerifyCertificate;
// import React from 'react'
import React, { useState } from 'react';
import HeaderHome from './HeaderHome';
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import { SERVICE_URL } from '../constant';
export default function About() {


  const [inputs, setInputs] = useState({
    fullName: '',
    email: '',
    message: '',
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [validations, setValidations] = React.useState({
    fullName: '',
    email: '',
    message: '',
    errorMessage: ''
  })

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs(values => ({ ...values, [name]: value }));
  }

  const handleFieldValidation = (field, errorMessage) => {
    if (!inputs[field]) {
      setValidations(prevState => ({
        ...prevState,
        [field]: errorMessage
      }));
      return false;
    } else {

      if (field === 'fullName') {
        const pattern = /^[A-Za-zÀ-ÿ ,.'-]+$/;
        if (!pattern.test(inputs[field])) {
          setValidations(prevState => ({
            ...prevState,
            [field]: 'Please enter full Name'
          }));
          return false;
        }
      }

    

     

      if (field === 'email') {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(inputs[field])) {
          setValidations(prevState => ({
            ...prevState,
            [field]: 'Please enter valid Email'
          }));
          return false;
        }
      }

      else {
        setValidations(prevState => ({
          ...prevState,
          [field]: ''
        }));
      }
      return true;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let isFormValid = true;
    setValidations(prevState => ({
      ...prevState,
      fullName: '',
      message: '',
      email: '',
    }));

    // Check each field and set error messages if required
    if (!handleFieldValidation('fullName', 'Please enter your full Name')) {
      isFormValid = false;
    }

    if (!handleFieldValidation('company', 'Please enter your message')) {
      isFormValid = false;
    }

   

    if (!handleFieldValidation('email', 'Please enter your Email')) {
      isFormValid = false;
    }

    

    if (isFormValid) {
      const requestOptions = {
        headers: {
          'Content-Type': 'application/json',
          "x-api-key": "P3yWDK7Aur2Of8y8AJHDza4GtilPsOG06HKp66b0"
        }
      };
      var data = {
        fullname: inputs.fullName,
        emailId: inputs.email,
        phone: inputs.mobile,
        company_name: inputs.company,
        designation: inputs.designation,
        contact_reason: "Hire from us",
      };
      try {
        const result = await axios.post(SERVICE_URL + "/enquiry/company", data, requestOptions, { crossDomain: true });
        console.log(result);
        if (result.data.statusCode === "201") {
          setSuccess(true);
          setErrorMsg('');
          setError(false);
          setInputs({});
        }
        else {
          setErrorMsg(result.data.detail.message);
          setSuccess(false);
          setError(true);
        }

      }
      catch (error) {
        console.log(error);
      }
    }
  }


  return (
    <React.Fragment>
      <HeaderHome />
      <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <title>Skillible - We Help You Succeed In Your Career!</title>
          <meta name="description" content="Start or restart your career in tecnology with Skillible. Learn from seasoned industry professionals and projects. Pay Rs. 0 until you get a job of Rs. 5 LPA." />
          <link rel="canonical" href="http://skillible.io/" />
          <meta property="og:title" content="Skillible - We Help You Succeed In Your Career!" />
          <meta property="og:description" content="Start or restart your career in tecnology with Skillible. Learn from seasoned industry professionals and projects. Pay Rs. 0 until you get a job of Rs. 5 LPA." />
          <meta property="og:url" content="http://skillible.io/" />
          <meta property="og:type" content="website" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:site_name" content="Skillible" />
        </Helmet>
      </HelmetProvider>
      <div>
        <section className="industries-banner she-leads about-banner">
          <div className="container">
            <div className="row">
            
              {/* <div className="col-md-4 mt-5">
                <div className="img-banner pair-imgdv ndvt-2">
                  <span className="pair-img"><img src={require(`../assets/images/pairw.png`)} className="img-fluid" alt="" /></span>
                  <div className="pairbanner_girl">
                    <img src={require(`../assets/images/home-revamp/banner-img.png`)} className="img-fluid" alt="Skillible trains and upskills individuals in the tech domain" />
                  </div>
                </div>
              </div> */}

              <div className="col-md-12 text-center">
                <div className="text-banner hire-tr-td">

                <h3>  </h3>
                  <h1>About Us  </h1>
                  {/* <h1>Skillible Nurtures The Spirit Of <span> Equality </span>  And <span> Diversity </span> In The Tech Ecosystem. </h1> */}
                  <p className="banner-para3"> At Skillible, we empower the next generation of talent with expert-led, practical training in emerging technologies. Our industry-focused programs equip learners with the skills to succeed and adapt in the rapidly evolving global landscape.
                  </p>


                  {/* <h3> About Us</h3>
                  <h1>Skillible Nurtures The Spirit Of <span> Equality </span>  And <span> Diversity </span> In The Tech Ecosystem. </h1>
                  <p className="banner-para3">  We pledge to create a
                    workforce that is highly robust and increasingly tech-enabled. We aim to achieve greater
                    socio-economic mobility for all sections of society by reimagining the role of technology
                    in delivering corporate advantage and upskilled career advantage.</p> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pair-sectiontext pair-sectiontext2 hd2 ndvt-3">
          <div className="container">
            <div className="row mx-wth">
              <div className="col-md-12 col-lg-5">
                <div className="frenceimg-pair">
                  <div className="img-frence-pair"><img src={require(`../assets/images/home-revamp/about-t1.png`)} className="img-fluid" alt="We design complete learning solutions for Top IT roles - Skillible" />
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-7">
                <div className="languapair-steps step1 hading-new-2">
                  <h2>What We <span className="day-one-skil">Do ?</span> </h2>
                  <ul>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className=""><strong> Career Growth for All </strong>  We empower learners from diverse backgrounds—whether upskilling, transitioning, or returning after a break—to secure top roles. </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className=""><strong> Tailored Learning Solutions </strong>  Our customized programs equip both technical and non-technical professionals with cutting-edge skills, keeping them ahead in a fast-evolving tech landscape.  </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className=""><strong> Streamlined Talent Onboarding </strong>  Our Hire-Train-Deploy model delivers job-ready talent, ensuring businesses can onboard skilled professionals seamlessly. </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className=""><strong> Empowering Women                      </strong>  We support women professionals in re-entering the workforce and advancing their careers with confidence.  </div>
                    </li>
                    <li><span className="icon"><i className="fas fa-check"></i></span>
                      <div className=""><strong> Top Talent for Businesses
                      </strong>  We connect companies with highly skilled, cost-effective talent that drives business growth and innovation. </div>
                    </li>
                 
                   
                  </ul>

<p>At Skillible, we are committed to creating clear, sustainable pathways to success for both individuals and organizations, transforming talent for tomorrow’s tech-driven world.</p>



                </div>

              </div>
            </div>
          </div>
        </section>
        <section className="pair-sectiontext hd2 ndvt-3">
          <div className="container">
            <div className="row displ-lrtd mx-wth">
              <div className="col-md-12 col-lg-5 text-end">
                <div className="frenceimg-pair">
                  <div className="img-frence-pair"><img src={require(`../assets/images/about-t1.png`)} className="img-fluid" alt="How we started- Skillible " />
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-7">
                <div className="languapair-steps step1 hading-new-2">
                  <h2 className='mb-2'>A quest to bridge the skills gap </h2>

                  <p>Skillible, an emerging EdTech brand launched in 2022, was created to address the growing need for <span className="deplyo-one-skil" > industry-ready professionals </span>  and <span className="deplyo-one-skil" >  close the skills gap </span> that has long existed among learners. Our mission was clear: to build a platform that not only empowers students but also equips them with the hands-on experience that hiring leaders demand. 
                  </p>

                   <p>Backed by government partnerships, including <span class="aictc-partner"> AICTE </span> and Skill India, we set out to create a platform that not 
                    only delivers quality education but also offers real-world exposure. With a focus on emerging technologies 
                    and industry-relevant skills, Skillible has grown into a trusted partner for students and professionals 
                    looking to upskill and secure their place in the future workforce. </p>


                   <p>Our journey continues as we innovate, expand, and remain committed to bridging the skills gap, one learner at a time. </p>


                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="skillible-s-sec mt-1 missiinn">
          <div className="container">
            {/* <div className="row">
              <div className="col-md-6">
                <div className="ourfamliy-text2d missin-head our-vision-mis hading-new-2">
                  <h2>Our Mission </h2>
                  <div className="skillible-list">
                    <p className="">
                    Our goal is to maximize our performance not just in terms of figures but with respect to our clients and learners’ satisfaction. Our intent is not just to ensure equality and diversity in every workspace, but also to assist every female professional in making a spectacular comeback in her career.
                    </p>
                    
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="">
                  <img
                    src={require(`../assets/images/mision.png`)}
                    className="img-fluid"
                    alt="Skillible mission is to maximize learners' and partners' satisfaction and envision a tech ecosystem that fulfills hiring needs."
                  />
                </div>
              </div>
            </div> */}


            <div className="row">
              
              <div className="col-md-5">
                <div className="">
                  <img
                    src={require(`../assets/images/mision.png`)}
                    className="img-fluid"
                    alt="Skillible mission is to maximize learners' and partners' satisfaction and envision a tech ecosystem that fulfills hiring needs."
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="ourfamliy-text2d missin-head our-vision-mis hading-new-2">
                  <h2>Our Vision </h2>
                  <div className="skillible-list">
                    <p className="">
                    Our goal is to maximize our performance not just in terms of figures but with respect to our clients and learners’ satisfaction. Our intent is not just to ensure equality and diversity in every workspace, but also to assist every female professional in making a spectacular comeback in her career.
                    </p>
                    
                  </div>
                  <h2>Our Mission </h2>
                  <div className="skillible-list">
                    <p className="">
                    We envision a tech ecosystem where learning is enhanced through the thoughtful and application of technology. We are committed to increasing the learner-centricity and vocational relevance of education. 
                    Through our virtual labs, we are empowering learners to become tech experts. 
                    </p>
                    
                  </div>
                </div>
              </div>
            </div>





          </div>
        </section>



{/* <section className="delivering-accurate sce991">      
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5 ps-0 key-issue-imgdv">
                <div className="key-issue-img">
                  <img src={require(`../assets/images/value-commitment.jpg`)} className="img-fluid keyimg-main1" alt="Skillible spreads Equity and Diversity" />
                  <div className="transpratgdnt-keyissues">
                    <span>
                      <img src={require(`../assets/images/icon-key-issues.png`)} className="img-fluid" alt="icon key issues" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="keyissuesText">
                  <ul>
                    <h1>Values</h1>
                    <li>
                      <span className="icon">
                        <i className="fas fa-check"></i>
                      </span>
                      <div> Rooted in social responsibility, reducing inequality, and promoting economic mobility through accessible, high-quality affordable education   </div>
                    </li>
                    <h1>Commitment</h1>
                    <li>
                      <span className="icon">
                        <i className="fas fa-check"></i>
                      </span>
                      <div>Empowering learners with vocational - tech skills and career readiness, improving employability (fresh graduates and underserved communities)  </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section> */}

              {/* 
        <section className="interpretation-solutions">
          <div className="bg-circlsce1"></div>
          <div className="container">
            <div className="row width90pst">
              <div className="col-md-6">
                <div className="inter-solimgs">
                  <span className="img1">
                    <img src={require(`../assets/images/salesforce-admin.png`)} className="img-fluid" alt="Eligible Criteria" />
                  </span>
                  <span className="img2">
                    <img src={require(`../assets/images/home-revamp/value.png`)} className="img-fluid" alt="Skillible- Eligible Criteria" />
                  </span>
                </div>
              </div>
              <div className="col-md-6 text-left">
                <div className="same-steps inter-soluc1 hdsame1">
                  <h2> Values </h2>
                  <div className="services-expertise">
                    <p className=""> Rooted in social responsibility, reducing inequality, and promoting economic mobility through accessible, high-quality affordable education</p>
                  </div>

                  <h2> Commitment   </h2>
                  <div className="services-expertise">
                    <p className=""> Empowering learners with vocational - tech skills and career readiness, improving employability (fresh graduates and underserved communities) </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}


        <section className="services-industry">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center hding2 hading-new-2">
                <h2>Our Knowledge Partners</h2>
              </div>
            </div>
          </div>
        </section>


        <section className="our-knowledge-partners">
          <div className="container">
            <div className="row">


            
            <div className="col-md-12 col-lg-12 text-center">
                <div className="services-in">
                <span className="about-prtnr">
                    <img src={require(`../assets/images/home-revamp/partner-logo.png`)} className="img-fluid" alt="Benefits of Full Stack Development - Excellent Remuneration - Skillible" /></span>
                
                </div>
          </div>


              {/* <div className="col-md-6 col-lg-3 text-center">
                <div className="services-in">
                <span className="about-prtnr">
                    <img src={require(`../assets/images/home-revamp/salesforce-with-type-logo.png`)} className="img-fluid" alt="Benefits of Full Stack Development - Excellent Remuneration - Skillible" /></span>
                
                </div>
              </div>
              <div className="col-md-6 col-lg-3 text-center">
                <div className="services-in">
                <span className="about-prtnr">
                    <img src={require(`../assets/images/home-revamp/logo_aicte.png`)} className="img-fluid" alt="Benefits of Full Stack Development - Higher Productivity Rate - Skillible" /></span>
                
                </div>
              </div>
              <div className="col-md-6 col-lg-3 text-center">
                <div className="services-in">
                  <span className="about-prtnr">
                    <img src={require(`../assets/images/home-revamp/ministry-of-education.png`)} className="img-fluid" alt="Benefits of Full Stack Development - Always In Demand - Skillible" /></span>
                  
                </div>
              </div>
              <div className="col-md-6 col-lg-3 text-center">
                <div className="services-in">
                <span className="about-prtnr">
                    <img src={require(`../assets/images/home-revamp/Skill-India-Logo-PNG.png`)} className="img-fluid" alt="Benefits of Full Stack Development - Always In Demand - Skillible" /></span>
                  
                </div>
              </div>

              <div className="col-md-6 col-lg-3 text-center">
                <div className="services-in">
                <span className="about-prtnr">
                    <img src={require(`../assets/images/lnc.png`)} className="img-fluid" alt="Benefits of Full Stack Development - Always In Demand - Skillible" /></span>
                  
                </div>
              </div> */}

            </div>
          </div>
        </section>









        {/* <section className="she-equipped-worsx">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="hding2 colr-spanh2">
                  <h2> OUR INTEGRATED APPROACH:
                    <span> (Towards Our Valuable Clients & learners)</span> </h2>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="pair-sectiontext pair-sectiontext2 hd2 ndvt-3">
          <div className="container">
            <div className="row mx-wth">
              <div className="col-md-12 col-lg-6">
                <div className="frenceimg-pair">
                  <div className="img-frence-pair"><img src={require(`../assets/images/clients.png`)} className="img-fluid" alt="Skillible offers customized talents at zero hiring cost " />
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="languapair-steps step1 day-dep-ts">
                  <h2>Skilled developers are not just available on
                    <span className="day-one-skil"> job boards!</span>
                  </h2>
                  <p>Your search for skilled IT resources can only be attained through mediums that believe in
                    <span className="deplyo-one-skil" > practicing transparency and delivering quality </span>
                    training and education. Skillible can assist you through various mediums and sources.</p>
                  <p>
                    We not just offer customized training solutions but also give you the opportunity to hire trained professionals
                    or freshers at zero hiring cost. Along with this, you'll get the opportunity to reduce employee benching costs,
                    lower down higher attrition rates, and get access to productive resources from the first day of deployment. </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="pair-sectiontext hd2 ndvt-3">
          <div className="container">
            <div className="row displ-lrtd mx-wth">
              <div className="col-md-12 col-lg-6 text-end">
                <div className="frenceimg-pair">
                  <div className="img-frence-pair"><img src={require(`../assets/images/leaners.png`)} className="img-fluid" alt="Skillible offers technical skills that offers great market value " />
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="languapair-steps step1 ">
                  <h2>Skillible creates a candidate experience
                    <span className="day-one-skil"> worth bragging about</span>
                  </h2>
                  <p>With Skillible you can learn the most in-demand technical skills. These technical skills are filled with great potential and market value. We ensure that all of our courses are accessible to all segments of society. Not only working women but also those who have taken a career break. Not only professionals but also young graduates can fulfill their ambition to be a part of the tech ecosystem.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="meet-peaple team new-team">
          <div className="container-fluid">
            
            <div className="meet-peaple-bdr">
              <div className="col-md-12 text-center hd2 mb-4 hading-new-2">
              <h2>Our Management Team </h2>
              </div>
              <div className="col-md-12">
                <div className="row justify-content-center">
             

              <div className="col-md-4 col-lg-4 text-center">
                    <div className="meet-peaplet mt-3 meet-peaplet3 text-center">
                      <div className="our-team-img">
                  <figure>
                    <img src={require(`../assets/images/ankur-circle.png`)} class="team-img" alt="Ankur Goel - GROUP CEO - Skillible"/>
                  </figure>
                      </div>
                      <div className="meet-peaple-text">
                      <h3>Ankur Goel</h3>
                      <p className="postion-leader"> GROUP CEO</p>
                    
                      <a class="linkdin-profile" href="https://www.linkedin.com/in/enablinginclusion-ankur/" target="_blank"><i class="fab fa-linkedin"></i></a>
                      </div>
                    </div>
                  </div>















                  <div className="col-md-4 col-lg-4 text-center">
                    <div className="meet-peaplet mt-3 meet-peaplet3 text-center">
                      <div className="our-team-img">
                      

<figure>
                <img src={require(`../assets/images/suchita-circle.png`)} class="team-img" alt="Suchita Vishnoi - CMO"/>
              </figure>



                      </div>
                      <div className="meet-peaple-text">
                        <h3>Suchita Vishnoi</h3>
                        <p className="postion-leader"> CMO</p>
                        <a class="" href="https://www.linkedin.com/in/suchita-vishnoi/" target="_blank"><i class="fab fa-linkedin"></i></a>

                      </div>
                    </div>
                  </div>


                  <div className="col-md-4 col-lg-4 text-center">
                    <div className="meet-peaplet mt-3 meet-peaplet3 text-center">
                      <div className="our-team-img">
                     


<figure>
                <img  src={require(`../assets/images/Apurva-Shrivastava-circle.png`)} class="team-img" alt="Apurva Shrivastava - Co Founder and CBO"/>
              </figure>


                      </div>
                      <div className="meet-peaple-text">
                        <h3>Apurva Shrivastava</h3>
                        <p className="postion-leader">  CO-FOUNDER & <br/> CBO</p>
                        <a class="" href="https://www.linkedin.com/in/apurvashrivastava/" target="_blank"><i class="fab fa-linkedin"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>





        {/* <section className="conatct-section contact-formsce contact-formsce1">
          <div className="container">
            <div className="row">
              <div className="col-mc-12">
                <div className="bg_contactForm">
                  <div className="row">
                    <div className="col-md-12 pb-4 text-start hding2 hading-new-2">
                      <h2>We'll Get  <span>Back To You  </span>
                      </h2>
                    </div>
                   
                  </div>
                  <form className="row needs-validation" id="free_inquiry" >
                    <input type="hidden" name="country_code" id="contact_country_code" />
                    <div className="col-md-12">
                      <div className="row">


                        <div className={`col-md-6 ${validations.fullName && 'has-error'}`}>
                          <div className="fildsstr">
                            <input type="text"
                              className="form-control" name="fullName" value={inputs.fullName || ""}
                              placeholder="Full Name" onChange={handleChange} />
                            {validations.fullName && (
                              <div className="error-message">{validations.fullName}</div>
                            )}
                          </div>
                        </div>

                        <div className={`col-md-6 ${validations.email && 'has-error'}`}>
                        <div className="fildsstr">
                          <input type="text"
                            className="form-control"
                            placeholder="Email ID" name='email' value={inputs.email || ""} onChange={handleChange} />
                          {validations.email && (
                            <div className="error-message">{validations.email}</div>
                          )}
                        </div>
                      </div>
                       
                      </div>
                    </div>
                    <div className="col-md-12">


                     


                      <div className={`col-md-12 ${validations.company && 'has-error'}`}>
                        <div className="fildsstr">
                          <textarea type="text"
                            className="form-control" name="company" value={inputs.company || ""}
                            placeholder="message" onChange={handleChange} />
                          {validations.company && (
                            <div className="error-message">{validations.company}</div>
                          )}
                        </div>
                      </div>



                    </div>
                    <div className="col-md-12 col-lg-12">
                      <div className="text-center">
                        <button className="btn" type="button" id="buttonSubmitNow" name="temp" onClick={(e) => handleSubmit(e)}>Submit Now</button>
                      </div>
                    </div>
                    <div className="col-md-12 text-center ">
                    {success ?
                                    <div className="form-check pt-3 sucss">
                                       <label className="succes-msg"> Thank you! We will get in touch with you soon!
                                       </label>
                                    </div>
                                    : ''
                                 }
                                 {error ?
                           <div className="form-check pt-3 deng">
                              <label className="danger-msg" id="not-store-data"> {errorMsg}
                              </label>
                           </div> : ''
                        } 

                    </div>
                    <div className="col-md-12 text-center">
                      <div className="or_bodr1">
                        <span>or</span>
                      </div>
                    </div>
                    <div className="col-md-12 text-center">
                      <div className="sign_up_link">Email us at -
                        <a href="mailto:support@skillible.io">support@skillible.io</a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section> */}







      </div>

    </React.Fragment>
  )
}
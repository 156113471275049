import React from 'react'
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Header2 from './HeaderHome'
export default function Sucess_stories() {

  return (

    <React.Fragment>
      <Header2 />


      <Helmet>
        <meta charset="UTF-8" />
        <title>Top success stories to inspire you to succeed - Skillible</title>
        <meta name="description" content="Follow the path of our alumni and re-define your career strategy with Skillible." />
        <link rel="canonical" href="http://skillible.io/" />
        <meta property="og:title" content="Skillible - We Help You Succeed In Your Career!" />
        <meta property="og:description" content="Follow the path of our alumni and re-define your career strategy with Skillible." />
        <meta property="og:url" content="http://skillible.io/" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Skillible" />

      </Helmet>

      <div>
        <section className="industries-banner2d mb-3 sucess-strory-update">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="text-banner conveying56">
                  <h1>
                  
                    <span>
                    Read our inspiring success stories and re-define your career strategy

                    </span>
                  </h1>
                 
                
                  <h2 className="text-left pb-3 industries-bold-head">
                  From Our Learners
 

                  </h2>

                  <p className="box-msg-r">
                    “Skillible is proud to celebrate the success of our students who have recently completed the "Introduction to Generative AI" certification a 6-week virtual internship in Generative AI in collaboration with the AICTE. Throughout these programs, participants delved into essential topics such as prompt engineering, AI fundamentals, and the limitations of AI models. This hands-on experience has significantly enhanced their confidence and equipped them with the skills to develop innovative AI solutions. With appreciation for the mentorship and peer support they received, our graduates are eager to apply their newfound expertise in upcoming projects and further their journey in the evolving field of AI.

"
                    
                    </p>


                </div>
              </div>
              <div className="col-md-4 text-center">
               
                  <div class="newvideo-s">
                     <div class="rwd-media">
                        <iframe src={require(`../assets/images/home-revamp/student.mp4`)} width="448" height="252" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
                     </div>
                  </div>


              </div>
            </div>
          </div>
        </section>



        <section className="why-our-section testimonial-candidate mb-4">
            <div className="container">
               <div className="row">
                  <div className="col-md-12">
                     <div className="newhding2 mt-5 text-center">
                        <span className="startIconX"><img src={require(`../assets/images/half-circle.png`)} className="img-fluid" alt="half-circle-icon" /></span>
                        <span className="heartIconX"> <img src={require(`../assets/images/arrow.png`)} className="img-fluid" alt="arrow-icon" /></span>
                        <span className="thimbIconX"> <img src={require(`../assets/images/thumb-icon.png`)} className="img-fluid" alt="thumb-icon" /></span>
                        <h2 className='hading-new-2'>
                        Student Success Stories
                        </h2>
                     </div>
                     <div className="owl-carousel owl-theme slider-for-business dottdsn dottdsn-fxtst" id="testimon-home">
                        <div className="item">
                           <div className="row">
                              <div className="col-md-12">
                                 <div className="why-our-text text-center testim-home-page">
                                    <div className="name_tstitext">
                                       <div className="d-flex align-items-center slider-candidate">
                                          <div className="candidate-img">
                                             <img src={require(`../assets/images/home-revamp/raghav.png`)} className="img-fluid" alt="duall-dott-icon" />
                                          </div>
                                          <div className="testmolTextSide">
                                             <p>Raghav SK is excited to announce the completion of the Skillible Certification in "Introduction to Generative AI." This journey provided in-depth knowledge of Generative AI fundamentals, prompt engineering, and the limitations and biases of AI models. The skills gained have enhanced their confidence in AI applications and equipped them to create innovative solutions. They look forward to applying their newfound knowledge in future projects and continuing their growth in the field of AI.
                                             </p>
                                          </div>
                                          <div className="testmolTextSideImg">
                                             <img src={require(`../assets/images/duall-dott-icon.png`)} className="img-fluid" alt="duall-dott-icon" />
                                          </div>
                                       </div>
                                       <div className="name_tsti1">
                                          <div className="name_tsti"> Raghav 
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>



                        <div className="item">
                           <div className="row">
                              <div className="col-md-12">
                                 <div className="why-our-text text-center testim-home-page">
                                    <div className="name_tstitext">
                                       <div className="d-flex align-items-center slider-candidate">
                                          <div className="candidate-img">
                                             <img src={require(`../assets/images/home-revamp/Aayush.png`)} className="img-fluid" alt="duall-dott-icon" />
                                          </div>
                                          <div className="testmolTextSide">
                                             <p>Aayush Sonkusle expresses immense gratitude for the opportunity to explore AI through their internship, where they learned about cutting-edge projects and deepened their understanding of neural networks, deep learning, and data modeling. This experience provided valuable hands-on training that sharpened their skills in AI-driven solutions. They thank Skillible, the All India Council for Technical Education (AICTE), and the entire team for their guidance and support. Their insights were crucial in navigating the complexities of Generative AI  </p>
                                          </div>
                                          <div className="testmolTextSideImg">
                                             <img src={require(`../assets/images/duall-dott-icon.png`)} className="img-fluid" alt="duall-dott-icon" />
                                          </div>
                                       </div>
                                       <div className="name_tsti1">
                                          <div className="name_tsti">Aayush Sonkusle 
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>


                        <div className="item">
                           <div className="row">
                              <div className="col-md-12">
                                 <div className="why-our-text text-center testim-home-page">
                                    <div className="name_tstitext">
                                       <div className="d-flex align-items-center slider-candidate">
                                          <div className="candidate-img">
                                             <img src={require(`../assets/images/home-revamp/Saksham.png`)} className="img-fluid" alt="duall-dott-icon" />
                                          </div>
                                          <div className="testmolTextSide">
                                             <p>Saksham Bhardwaj is excited to announce the completion of a 6-week virtual internship with Skillible, focusing on Generative AI. Throughout the program, he deepened his understanding of advanced AI technologies and developed skills in creating generative models, including text generation and image synthesis. He expresses gratitude to the Skillible team for organizing a well-structured and insightful experience. This internship has further ignited his passion for AI, and he looks forward to applying his skills in future projects. </p>
                                          </div>
                                          <div className="testmolTextSideImg">
                                             <img src={require(`../assets/images/duall-dott-icon.png`)} className="img-fluid" alt="duall-dott-icon" />
                                          </div>
                                       </div>
                                       <div className="name_tsti1">
                                          <div className="name_tsti">Saksham Bhardwaj  
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>



                        <div className="item">
                           <div className="row">
                              <div className="col-md-12">
                                 <div className="why-our-text text-center testim-home-page">
                                    <div className="name_tstitext">
                                       <div className="d-flex align-items-center slider-candidate">
                                          <div className="candidate-img">
                                             <img src={require(`../assets/images/home-revamp/Saksham.png`)} className="img-fluid" alt="duall-dott-icon" />
                                          </div>
                                          <div className="testmolTextSide">
                                             <p>Sukhmeet Singh Oberoi completed a 6-week virtual internship in Generative AI at Skillible, in collaboration with the All India Council for Technical Education (AICTE). During this immersive program, he explored key modules, including the fundamentals of Generative AI, prompt engineering, and limitations of AI models, enhancing both his theoretical understanding and practical skills. He expresses gratitude to AnkurGoel and the Skillible team, as well as Chandrasekhar Buddha for the opportunity, and appreciates the support from mentors and peers. Sukhmeet is eager to apply his newfound skills in future endeavors. </p>
                                          </div>
                                          <div className="testmolTextSideImg">
                                             <img src={require(`../assets/images/duall-dott-icon.png`)} className="img-fluid" alt="duall-dott-icon" />
                                          </div>
                                       </div>
                                       <div className="name_tsti1">
                                          <div className="name_tsti">Saksham Bhardwaj  
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>





                        <div className="item">
                           <div className="row">
                              <div className="col-md-12">
                                 <div className="why-our-text text-center testim-home-page">
                                    <div className="name_tstitext">
                                       <div className="d-flex align-items-center slider-candidate">
                                          <div className="candidate-img">
                                             <img src={require(`../assets/images/home-revamp/Tanisha.png`)} className="img-fluid" alt="duall-dott-icon" />
                                          </div>
                                          <div className="testmolTextSide">
                                             <p>Tanisha Chaudhari has successfully completed the Generative AI virtual internship program by Skillible, which included six comprehensive modules that enhanced their understanding of AI-generated content. They faced the challenge of locating research papers using specific keywords but found that AI tools significantly streamlined the process. The three live sessions led by Dr. Prabhjot Singh Manocha and SuchitaVishnoi provided hands-on experience and expert guidance, which were vital for project completion. Overall, the internship improved their technical skills and highlighted the value of AI tools in research and application. </p>
                                          </div>
                                          <div className="testmolTextSideImg">
                                             <img src={require(`../assets/images/duall-dott-icon.png`)} className="img-fluid" alt="duall-dott-icon" />
                                          </div>
                                       </div>
                                       <div className="name_tsti1">
                                          <div className="name_tsti"> Tanisha Chaudhari 
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>


                        <div className="item">
                           <div className="row">
                              <div className="col-md-12">
                                 <div className="why-our-text text-center testim-home-page">
                                    <div className="name_tstitext">
                                       <div className="d-flex align-items-center slider-candidate">
                                          <div className="candidate-img">
                                             <img src={require(`../assets/images/home-revamp/Poonam.png`)} className="img-fluid" alt="duall-dott-icon" />
                                          </div>
                                          <div className="testmolTextSide">
                                             <p>Poonam Akhtare is excited to have received a certificate for completing the 
                                                Generative AI Virtual Internship Program. The program focused on key topics 
                                                such as foundational concepts, prompt engineering, and practical applications. 
                                                She expresses gratitude for the opportunity to deepen her expertise in AI and 
                                                explore its potential.</p>
                                          </div>
                                          <div className="testmolTextSideImg">
                                             <img src={require(`../assets/images/duall-dott-icon.png`)} className="img-fluid" alt="duall-dott-icon" />
                                          </div>
                                       </div>
                                       <div className="name_tsti1">
                                          <div className="name_tsti"> Poonam Akhtare  
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>





                        <div className="item">
                           <div className="row">
                              <div className="col-md-12">
                                 <div className="why-our-text text-center testim-home-page">
                                    <div className="name_tstitext">
                                       <div className="d-flex align-items-center slider-candidate">
                                          <div className="candidate-img">
                                             <img src={require(`../assets/images/home-revamp/GhanshreeBoonde.png`)} className="img-fluid" alt="duall-dott-icon" />
                                          </div>
                                          <div className="testmolTextSide">
                                             <p>GhanshreeBoonde is thrilled to announce the completion of a 6-week virtual internship in Generative AI with Skillible and the All India Council for Technical Education (AICTE). During the internship, she mastered essential topics, including the fundamentals of Generative AI and prompt engineering. She worked on a project focused on summarizing and analyzing research papers using AI tools, enhancing her technical skills and understanding of AI ethics. Ghanshree looks forward to applying her newfound skills in future projects and opportunities.</p>
                                          </div>
                                          <div className="testmolTextSideImg">
                                             <img src={require(`../assets/images/duall-dott-icon.png`)} className="img-fluid" alt="duall-dott-icon" />
                                          </div>
                                       </div>
                                       <div className="name_tsti1">
                                          <div className="name_tsti"> GhanshreeBoonde  
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>




                        <div className="item">
                           <div className="row">
                              <div className="col-md-12">
                                 <div className="why-our-text text-center testim-home-page">
                                    <div className="name_tstitext">
                                       <div className="d-flex align-items-center slider-candidate">
                                          <div className="candidate-img">
                                             <img src={require(`../assets/images/home-revamp/Shivang.png`)} className="img-fluid" alt="duall-dott-icon" />
                                          </div>
                                          <div className="testmolTextSide">
                                             <p>Shivang Bhardwaj is excited to announce the completion of the "Gen AI" Virtual Internship by Skillible, with the program running from July to August 2024. He gained valuable knowledge in Generative AI, mastering its fundamentals, key terminologies, and prompt engineering, while successfully executing a generative task. Shivang also explored the limitations and biases of AI models. He looks forward to applying these insights and skills in his future endeavors.</p>
                                          </div>
                                          <div className="testmolTextSideImg">
                                             <img src={require(`../assets/images/duall-dott-icon.png`)} className="img-fluid" alt="duall-dott-icon" />
                                          </div>
                                       </div>
                                       <div className="name_tsti1">
                                          <div className="name_tsti"> Shivang Bhardwaj   
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>


















                     </div>
                  </div>
               </div>
            </div>
         </section>

      </div>

    </React.Fragment>
  )
}
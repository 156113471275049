import React from 'react'
export default function Brochure() {

  return (
    <React.Fragment>
      <div>
        <section className="">
          <div className="container">
            <div className="row">
              <div className="col-md-12 sales-pdf-view">
                <iframe title="Salesforce Brochure" src={require(`../assets/images/Salesforce-Brochure.pdf`)} className="img-fluid"></iframe>
              </div>
            </div>
          </div>
        </section>
      </div>

    </React.Fragment>
  )
}
import React from 'react'
import EnquireLightbox from './EnquireLightbox'
import Slider from './Slider'
import HeaderHome from './HeaderHome'
import { HelmetProvider, Helmet } from 'react-helmet-async';
export default function Home() {
return (
<React.Fragment>
   <HeaderHome />
   <HelmetProvider>
      <Helmet>
         <meta charset="UTF-8" />
         <title>Skillible - We Help You Succeed In Your Career!</title>
         <meta name="description" content="Start or restart your career in tecnology with Skillible. Learn from seasoned industry professionals and projects. Pay Rs. 0 until you get a job of Rs. 5 LPA." />
         <link rel="canonical" href="http://skillible.io/" />
         <meta property="og:title" content="Skillible - We Help You Succeed In Your Career!" />
         <meta property="og:description" content="Start or restart your career in tecnology with Skillible. Learn from seasoned industry professionals and projects. Pay Rs. 0 until you get a job of Rs. 5 LPA." />
         <meta property="og:url" content="http://skillible.io/" />
         <meta property="og:type" content="website" />
         <meta property="og:locale" content="en_US" />
         <meta property="og:site_name" content="Skillible" />
      </Helmet>
   </HelmetProvider>
   <EnquireLightbox />
   <Slider />
   <section className="pair-sectiontext pair-sectiontext2 hd2 ndvt-3">
      <div className="container">
         <div className="row mx-wth">
            <div className="col-md-12 col-lg-6">
               <div className="">
                  <div className="img-frence-pair"><img src={require(`../assets/images/home-revamp/who-we-are.png`)} className="img-fluid" alt="Hire and customize talent as per your need" />
                  </div>
               </div>
            </div>
            <div className="col-md-12 col-lg-6">
               <div className="languapair-steps step1 hading-new-2">
                  <h2>Who We Are</h2>
                  <p>At the forefront of innovation, we are an EdTech firm dedicated to equipping learners with cutting-edge
                      skills in the most sought-after new-age technologies. From Generative AI and Cybersecurity to VLSI Design,
                       our mission is to empower the next generation of tech leaders by providing industry-relevant training that prepares them for real-world challenges. Through a blend of expert-led courses, hands-on learning, and future-focused content, we bridge the gap between academic knowledge and practical expertise, enabling our learners to thrive in today’s rapidly evolving tech landscape.
                  </p>
                  <ul>
                     <li>
                        <span className="icon"><i className="fas fa-check"></i></span>
                        <div className=""><strong>Industry-Relevant Training :  </strong>
                           Skillible offers cutting-edge courses designed to align with current industry standards, ensuring learners develop practical, in-demand skills.
                        </div>
                     </li>
                     <li>
                        <span className="icon"><i className="fas fa-check"></i></span>
                        <div className=""> <strong>Expert-Led Programs : </strong>
                           Our training programs are guided by industry professionals, providing deep insights and hands-on learning experiences to enhance expertise.
                        </div>
                     </li>
                     <li>
                        <span className="icon"><i className="fas fa-check"></i></span>
                        <div className=""> <strong> Immersive Internships: </strong>
                           We offer internships that simulate real-world challenges, allowing learners to gain practical knowledge and build confidence for future career success.
                        </div>
                     </li>
                     <li>
                        <span className="icon"><i className="fas fa-check"></i></span>
                        <div className=""> <strong> Future-Ready Skills:</strong>
                           Our platform focuses on key areas such as cyber security, VLSI, and AI, preparing learners for the fast-evolving technology landscape. 
                        </div>
                     </li>
                     {/* <li>
                        <span className="icon"><i className="fas fa-check"></i></span>
                        <div className=""><strong>  Career Development: </strong> 
                           Skillible empowers learners with the necessary skills for employability, bridging the gap between academic knowledge and professional readiness.
                        </div>
                     </li> */}
                  </ul>
               </div>
            </div>
         </div>
      </div>
   </section>
   <section className="she-equipped-wor">
      <div className="container">
         <div className="row">
            <div className="col-md-12 text-center">
               <div className="hding2 colr-spanh2 hading-new-2">
                  <h2>How <span> We </span> Help? </h2>
                  <p> Skillible provides practical learning solutions to help individuals thrive. Whether you're upskilling for a new role, transitioning to a tech-driven job, or enhancing your team's capabilities, we offer the right learning paths, expert guidance, and real-world projects to drive your success.
                  </p>
               </div>
            </div>
         </div>
      </div>
      <div className="skillible-upskill-uy" style={{ background: '#6612f0' }}>
      <div className="accordion-she">
         <div className="tab-she she1 active" data-color="#6612f0">
            <img src={require(`../assets/images/skill-traning.png`)} alt="Resolve your worries and upskill with Skillible" />
            <div className="caption">
            </div>
         </div>
         <div className="tab-she she2" data-color="#2f154c">
            <img src={require(`../assets/images/upskillingplatform.png`)} alt="Fill your confidence gap with Skillible" />
            <div className="caption">
            </div>
         </div>
         <div className="tab-she she3" data-color="#04439f">
            <img src={require(`../assets/images/training-development.png`)} alt="Learn with Salesforce Mentors" />
            <div className="caption">
            </div>
         </div>
         <div className="tab-she she4" data-color="#29a0f9">
            <img src={require(`../assets/images/vocational-program.png`)} alt="Equal representation of women with Skillible" />
            <div className="caption">
            </div>
         </div>
      </div>
      </div>
   </section>


   <section className="why-skillible">
      <div className="container">
         <div className="row">
            <div className="col-md-12 col-lg-5 texvst1">
               <div className="texvst-img">
                  <img src={require(`../assets/images/3.png`)} className="img-fluid" alt="Salesforce Authorized Partner" />
               </div>
               <h2>
                  Learn From Us
               </h2>
               <div className="why-skillible2">
                  <div className="row">
                     <div className="col-md-6 col-sm-6">
                        <div className="text-fee1">
                           <div className="text-fee-text">
                              <div className="text-fee2">
                                 <span className="ixon">
                                 <img src={require(`../assets/images/skill-match.png`)} alt="Skillible Skill Matcher" className="img-fluid" />
                                 </span>
                                 <h3 className=''>
                                    Industry-Relevant   <span className=''> Skills </span>
                                 </h3>
                              </div>
                              <p> Programs designed to meet current industry demands, ensuring learners stay ahead in their careers.  </p>
                           </div>
                        </div>
                        <div className="text-fee1">
                           <div className="text-fee-text">
                              <div className="text-fee2">
                                 <span className="ixon">
                                 <img src={require(`../assets/images/partner-why-skill.png`)} alt="Skillible Salesforce Authorized Partner" className="img-fluid" />
                                 </span>
                                 <h3> Government  <span> Partnerships </span>
                                 </h3>
                              </div>
                              <p>  Backed by AICTE, Skill India, and the Ministry of Education, reinforcing credibility and trust.  </p>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-6 col-sm-6 mtsg2">
                        <div className="text-fee1">
                           <div className="text-fee-text">
                              <div className="text-fee2">
                                 <span className="ixon">
                                 <img src={require(`../assets/images/pre-trained.png`)} alt="Skillible Pre-Trained Developers" className="img-fluid" />
                                 </span>
                                 <h3>  Virtual <span> Internships </span>
                                 </h3>
                              </div>
                              <p> Hands-on, practical exposure through virtual internships that simulate real-world work environments.  </p>
                           </div>
                        </div>
                        <div className="text-fee1">
                           <div className="text-fee-text">
                              <div className="text-fee2">
                                 <span className="ixon">
                                 <img src={require(`../assets/images/skill-why-skill.png`)} alt="Skillible Skill Validator" className="img-fluid" />
                                 </span>
                                 <h3> Expertly Curated  <span> Programs </span>
                                 </h3>
                              </div>
                              <p>  Content created and delivered by industry experts, blending theory with practice.  </p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            
            <div className="col-md-12 col-lg-2 bg-crl-cnet">
               {/* <div className="cliicon1 pt-4 text-center">
                  <img src={require(`../assets/images/artificial-intelligence.png`)} className="img-fluid" alt="Why Skillible" />
               </div> */}
               {/* <div className="cliicon2 text-end">
                  <img src={require(`../assets/images/5.png`)} className="img-fluid" alt="" />
               </div> */}
               <div className="crl-cnettext">
                  <div className="crl-cnettext2 where-westand">
                     <h3> The value we bring                     <span className='skillible-logo-white'> 
                     <img src={require(`../assets/images/home-revamp/white-icon.png`)}  class="img-fluid" alt=""/> </span>
                     </h3>
                  </div>
               </div>
            </div>
            <div className="col-md-12 col-lg-5 texvst1 texvst2">
               <div className="why-skillible2 ms-auto">
                  <div className="texvst-img">
                     <img src={require(`../assets/images/6.png`)} className="img-fluid" alt="Job Linked Programs offered by Skillible" />
                  </div>
                  <div className="row">
                     <div className="col-md-6 col-sm-6">
                        <div className="text-fee1">
                           <div className="text-fee-text">
                              <div className="text-fee2">
                                 <span className="ixon">
                                 <img src={require(`../assets/images/real-time.png`)} alt="Skillible Real Time Engagement" className="img-fluid" />
                                 </span>
                                 <h3> Career  <span> Advancement </span>
                                 </h3>
                              </div>
                              <p> Focus on upskilling to help students secure better job opportunities and career growth. </p>
                           </div>
                        </div>
                        <div className="text-fee1">
                           <div className="text-fee-text">
                              <div className="text-fee2">
                                 <span className="ixon">
                                 <img src={require(`../assets/images/zero-fees.png`)} className="img-fluid" alt="Enrol @ Zero Enrolment Fees" />
                                 </span>
                                 <h3> Flexible  <span> Learning </span>
                                 </h3>
                              </div>
                              <p>
                                 Online, self-paced courses and live classes to accommodate various learning styles and schedules. 
                              </p>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-6 col-sm-6 mtsg2">
                        <div className="text-fee1">
                           <div className="text-fee-text">
                              <div className="text-fee2">
                                 <span className="ixon">
                                 <img src={require(`../assets/images/job-skill.png`)} className="img-fluid" alt="Job Linked Program" />
                                 </span>
                                 <h3>  Certification and  <span> Recognition </span>
                                 </h3>
                              </div>
                              <p>  Earn certificates recognized by industry professionals and academic institutions.  </p>
                           </div>
                        </div>
                        <div className="text-fee1">
                           <div className="text-fee-text">
                              <div className="text-fee2">
                                 <span className="ixon">
                                 <img src={require(`../assets/images/emi-icon.png`)} className="img-fluid" alt="JD Enhancer" />
                                 </span>
                                 <h3>
                                    Community   <span>
                                    Support</span>
                                 </h3>
                              </div>
                              <p>
                                 Access to a network of peers, mentors, and professionals for continuous learning and collaboration. 
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <h2 className='grow-with-skillible'> Grow With Us </h2>
            </div>
         </div>
      </div>
   </section>

   <section className="ourwork home-number-count">
      <div className="container">
         <div className="row">


            <div className="col-md-12 text-center">
               <div className="ourclient ourclient1 clnt-update">
                  <span className='partner-icons-s'><img src={require(`../assets/images/home-revamp/skill-1.gif`)} className="img-fluid" alt="learners"/></span>
                  <h3 data-max="50,000">20,000 
                   <span> <i className="fas fa-plus"></i></span></h3>
                  <p>Learners upgraded their skills across various programs (Salesforce, Women in Tech, and More)</p>
               </div>
               <div className="ourclient ourclient2">
               <span className='partner-icons-s'><img src={require(`../assets/images/home-revamp/job.gif`)} className="img-fluid" alt="project"/></span>
                  <h3 data-max="70">2,000 <span> <i className="fas fa-plus"></i></span></h3>
                  <p>Internship opportunities secured for learners through various programs (Salesforce, Women in Tech, and More)</p>
               </div>
              
            </div>






            <div className="col-md-12 text-center">
               
               <div className="ourclient ourclient3">
                  <span className='partner-icons-s'><img src={require(`../assets/images/home-revamp/salary.gif`)} className="img-fluid" alt="skill"/></span>
                  {/* <h3 data-max="70">Significant </h3> */}
                  <p> Significant
                  Salary increases reported post-course completion </p>
               </div>
               <div className="ourclient ourclient4">
               <span className='partner-icons-s'><img src={require(`../assets/images/home-revamp/job-1.gif`)} className="img-fluid" alt="Word Translation"/></span>
                  {/* <h3 data-max="5,000">Majority</h3> */}
                  <p> Majority
                  of learners achieved Job Placement after completing their training
                  </p>
               </div>
               <div className="ourclient ourclient5">
               <span className='partner-icons-s'><img src={require(`../assets/images/home-revamp/certificate.gif`)} className="img-fluid" alt="Employees"/></span>
                  <p>Industry-Recognized
                  Certifications that open doors to career advancement  </p> 
               </div>
               <div className="ourclient ourclient6">
               <span className='partner-icons-s'><img  src={require(`../assets/images/home-revamp/companies.gif`)} className="img-fluid" alt="Employees"/></span>
                  <p>Trusted by
                  top companies for upskilling their teams   </p>
               </div>
            </div>







         </div>
      </div>
   </section>


 

   <section className="howcan-sec pt-5">
      <span className="howcan-botrtcolor"></span>
      <div className="container">
         <div className="row">
            <div className="col-md-7 text-left">
               <div className="howcanText hding2 skillible-extra-ben">
                  <h2> <span className='skillible-head-2'> Unlock Your Future with Skillible 🚀 
                     </span>  Skill Up, Get Hired and Thrive in the Job Market.
                  </h2>
                  <ul>
                     <li> The Job Market Needs You, but with the Right Skills: Bridge the Gap and Get Hired Faster with Skillible.
                     </li>
                     <li>
                        Explore our diverse curriculum and choose the course that resonates with you. We collaborate closely with key stakeholders - Industry leaders, faculties, educational institutions, and government bodies to deliver impactful upskilling/reskilling initiatives.
                     </li>
                     <li>
                        Enroll in these courses to unlock a gateway to opportunities, where you'll not only expand your academic knowledge but also gain real-world insights through hands-on experience with live projects and used cases.
                     </li>
                  </ul>
               </div>
            </div>
            <div className="col-md-5 text-center">
               <div className="howcanText-img">
                  <img src={require(`../assets/images/skillible-bore.png`)} className="img-fluid" alt="Skillible is an authorized Salesforce Workforce Development Partner
                     " />
               </div>
            </div>
         </div>
      </div>
   </section>


   <section className="opi-services-sec opi-services-sec2 sce991">
      <div className="container-fluid">
         <div className="row">
            <div className="col-md-6 opi-services opi-services2 text-end">
               <div className="opi-services-img">
                  <img src={require(`../assets/images/diversity.png`)} className="img-fluid opi-comm-img" alt="Skillible promotes Diversity and Inclusion In the Tech System " />
               </div>
            </div>
            <div className="col-md-6">
               <div className="opi-servicesText hding2 hading-new-2">
                  <h2> Fostering Diversity & Empowering Growth Through New-Age Technology  </h2>
                  <ul className="">
                     <li>
                        <div className=""> At Skillible, we recognized that diversity and inclusion were essential for driving innovation and long-term growth. By embracing varied perspectives, we created an environment where creativity thrived. Our programs addressed skill gaps and helped learners stay ahead in a fast-evolving job market.  </div>
                     </li>
                     <li>
                        <div className=""> In the past, we launched impactful programs like ‘Women in Tech’ and ‘Career Vriddhi,’ which empowered individuals, particularly women, with in-demand skills. These initiatives leveraged cutting-edge technologies such as AI, VLSI, and cybersecurity, bridging the gap between theory and practice, and enabling participants to make meaningful contributions in their careers.

 </div>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
   </section>




   <section className="our-partners-home main-home-r">
      <div className="container">
         <div className="row">
            <div className="col-md-12 hding2 text-center hading-new-2">
               <h2 className="pptHeadMarginSec mb-3">Partnerships that drive impact

               </h2>
               <p className="text-center">We are proudly supported by prestigious government bodies, including
                   AICTE and Skill India, under the Ministry of Education. Together, we drive impactful upskilling 
                   initiatives, empowering individuals with the skills needed to thrive in today's dynamic workforce. </p>
            </div>
            <div className="col-md-12">
               <div className="corpBanSecdimg text-center my-3" id="imageContainer">
                  <img id="fallbackImg" src={require(`../assets/images/home-revamp/partnerheader.png`)} fetchpriority="low" className="img-fluid" alt="Captioning and Transcription Services" 
                     width="500" height="375"/>
               </div>
               <div className='row mt-4'>
                  <div className="col-md-4 text-center">
                     <div className="home-partner">
                        <span><img 
                           src={require(`../assets/images/home-revamp/logo_aicte.png`)}
                           width="70" height="55" className="img-fluid" alt=""/></span>
                        <h6>AICTE ensures quality education and drives innovation in technical and professional institutions across India </h6>
                     </div>
                  </div>
                  <div className="col-md-4 text-center">
                     <div className="home-partner">
                        <span><img
                           src={require(`../assets/images/home-revamp/Skill-India-Logo-PNG.png`)}
                           width="68" height="68" className="img-fluid" alt=""/></span>
                        <h6>Skill India aims to empower youth by providing industry-relevant skills, fostering employability, and promoting entrepreneurship </h6>
                     </div>
                  </div>
                  <div className="col-md-4 text-center">
                     <div className="home-partner">
                        <span><img
                           src={require(`../assets/images/home-revamp/ministry-of-education.png`)}
                           width="72" height="48" className="img-fluid" alt=""/></span>
                        <h6>The Ministry of Education steers educational reforms to build a knowledge-driven and skilled workforce for India’s future</h6>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>




   <section class="professional-services mt-5">
         <span class="boy-girl-img"><img  src={require(`../assets/images/home-revamp/glance.png`)} 
         class="img-fluid" alt="Professional services within hours"
          /></span>
         <div class="container">
            <div class="row">
               <div class="col-md-12 col-lg-9 ms-auto">
                  <div class="pro-hours hding2 main-home-glance">
                     <h2>A preview of our courses</h2>
                     <p>At Skillible, where learning meets industry excellence, we offer courses that give you a corporate advantage and upskill your career for tomorrow’s tech-driven world. Our expert-led programs bridge the gap between education and real-world demands, empowering you to transform your talent and seize new opportunities.
                     </p>
                  </div>
                  <div class="serv-24">
                     <ul>
                        <li class="srvsstp1">
                           <div class="srvsstp">
                              <span class="img"><img src={require(`../assets/images/home-revamp/genai.png`)} 
                               class="img-fluid" alt="24X7 SUPPORT"/></span> 
                              <h3>Gen AI</h3>
                              <p>Generative AI is revolutionizing industries by creating new content, automating complex tasks, and unlocking limitless possibilities in creativity and problem-solving. </p>
                           </div>
                        </li>
                        <li class="srvsstp1">
                           <div class="srvsstp">
                              <span class="img"><img src={require(`../assets/images/home-revamp/cybersecurity.png`)} 
                              class="img-fluid" alt="COMPETITIVE COSTING"/></span>
                              <h3>Cyber Security</h3>
                              <p>Cyber Security is the frontline defence in the digital world, protecting data, systems, and networks from ever-evolving cyber threats.</p>
                           </div>
                        </li>
                        <li class="srvsstp1">
                           <div class="srvsstp">
                              <span class="img"><img src={require(`../assets/images/home-revamp/vlsi-design.png`)} 
                              class="img-fluid" alt="HIGH-QUALITY DELIVERY"/></span>
                              <h3>VLSI Design</h3>
                              <p>VLSI Design enables the creation of powerful microchips that drive today's advanced electronics, from smartphones to supercomputers, shaping the future of technology.</p>
                           </div>
                        </li>
                        <li class="srvsstp1">
                           <div class="srvsstp">
                              <span class="img"><img src={require(`../assets/images/home-revamp/employability-skills.png`)} 
                              class="img-fluid" alt="GLOBAL CREDIBILITY"/></span>
                              <h3>Employability Skills</h3>
                              <p>Employability skills are the essential soft and technical skills that empower individuals to excel in the workplace, enhancing career prospects and success. </p>
                           </div>
                        </li>
                       
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </section>








   <section className="testimonials-scn translation-a servs-edt">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="alltest-bg">
                  <div className="tstbig-img">
                    <span>
                      <img src={require(`../assets/images/da.png`)} className="" alt="Benefits Of Tech Scaler Program with skillible" />
                    </span>
                  </div>
                  <div className="translation-services-1">
                    <div className="test-text1 mantra-s">
                     <h2>The Skillible Mantra </h2>
                      <p>This approach captures Skillible’s commitment to guiding learners through a journey of continuous improvement: </p>
                    </div>

                    <div class="services-block-four mt-s">
                            <div class="inner-box">
                                <div class="icon-img-box">
                                    <img  src={require(`../assets/images/home-revamp/learn-1.png`)} alt=""/>
                                </div>
                                <h3>Learn</h3>
                                <div class="text">Build foundational knowledge with self-paced modules.</div>

                            </div>
                        </div>


                        <div class="services-block-four">
                            <div class="inner-box">
                                <div class="icon-img-box">
                                <img  src={require(`../assets/images/home-revamp/Grow.png`)} alt=""/>
                                </div>
                                <h3>Grow</h3>
                                <div class="text">Enhance skills through expert-led masterclasses and hands-on projects.  </div>

                            </div>
                        </div>

                        <div class="services-block-four" >
                            <div class="inner-box">
                                <div class="icon-img-box">
                                <img  src={require(`../assets/images/home-revamp/Succeed.png`)} alt=""/>
                                </div>
                                <h3>Succeed</h3>
                                <div class="text">Bridge the knowledge gap and advance in your career. </div>
                            </div>
                        </div>

                    <div className="services-expertise trans-list-1 good_enogh mantra-s">
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        



   
   <section className="why-our-section testimonial-candidate mb-4">
      <div className="container">
         <div className="row">
            <div className="col-md-12">
               <div className="mt-5 text-center">
                  {/* <span className="startIconX"><img src={require(`../assets/images/half-circle.png`)} className="img-fluid" alt="half-circle-icon" /></span>
                  <span className="heartIconX"> <img src={require(`../assets/images/arrow.png`)} className="img-fluid" alt="arrow-icon" /></span>
                  <span className="thimbIconX"> <img src={require(`../assets/images/thumb-icon.png`)} className="img-fluid" alt="thumb-icon" /></span>
                 */}
                
                
                  <h2 className='hading-new-2'>
                     From Learners To Achievers
                  </h2>
               </div>
               <div className="owl-carousel owl-theme slider-for-business dottdsn dottdsn-fxtst" id="testimon-home">
                  <div className="item">
                     <div className="row">
                        <div className="col-md-12">
                           <div className="why-our-text text-center testim-home-page">
                              <div className="name_tstitext">
                                 <div className="d-flex align-items-center slider-candidate">
                                    <div className="candidate-img">
                                       <img src={require(`../assets/images/sowthamini.png`)} className="img-fluid" alt="duall-dott-icon" />
                                    </div>
                                    <div className="testmolTextSide">
                                       <p>With a master's degree in computer application and 3 years of software engineering experience,
                                          she took a career break in 2014 due to a medical emergency. Discovering Skillible through social
                                          media, she embraced no-cost training, excelling in Salesforce development. Her performance led
                                          to her selection for an upskilling program, resulting in her employment as a Software Engineer
                                          at Neer Info Solution.
                                       </p>
                                    </div>
                                    <div className="testmolTextSideImg">
                                       <img src={require(`../assets/images/duall-dott-icon.png`)} className="img-fluid" alt="duall-dott-icon" />
                                    </div>
                                 </div>
                                 <div className="name_tsti1">
                                    <div className="name_tsti"> Software Developer <span>Sowthamini Subramanian</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="item">
                     <div className="row">
                        <div className="col-md-12">
                           <div className="why-our-text text-center testim-home-page">
                              <div className="name_tstitext">
                                 <div className="d-flex align-items-center slider-candidate">
                                    <div className="candidate-img">
                                       <img src={require(`../assets/images/hiral.png`)} className="img-fluid" alt="duall-dott-icon" />
                                    </div>
                                    <div className="testmolTextSide">
                                       <p>Holding a master's degree in computer science and having accumulated over 10 years of experience as an assistant professor,
                                          her career took an unexpected turn in 2018. Her journey led her to the digital landscape through LinkedIn, where Skillible emerged as a valuable find. She appreciated the Salesforce training and scholarships it offered, particularly the sessions conducted by Laxmi Narayan Pandey and Mr. Vikas Gupta, which kindled her interest. Despite encountering challenges,
                                          her unwavering determination guided her to her current position as a technical instructor at Salesforce.
                                       </p>
                                    </div>
                                    <div className="testmolTextSideImg">
                                       <img src={require(`../assets/images/duall-dott-icon.png`)} className="img-fluid" alt="duall-dott-icon" />
                                    </div>
                                 </div>
                                 <div className="name_tsti1">
                                    <div className="name_tsti"> Hiral Kanani<span> Salesforce Developer</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <section className="tree-plant sce991">
      <div className="container">
         <div className="row">
            <div className="col-md-12">
               <div className="text-center">
                  <h2>By planting a tree for each student enrolled,<br /> Skillible promises to protect the environment. </h2>
               </div>
            </div>
         </div>
      </div>
   </section>
</React.Fragment>
)
}
import React, { useState, useEffect } from 'react';
import { Input, Select } from 'antd';
import '../assets/css/FloatingLabelInput.css';

const { Option } = Select;
const FloatingLabelInput = ({ label, placeholder = '', value = '', type = 'text', onChange, options = [] }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [inputValue, setInputValue] = useState(value);
  
    useEffect(() => {
      setInputValue(value);
    }, [value]);
  
    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(!!inputValue);
    const handleChange = (e) => {
      const value = type === 'select' ? e : e.target.value;
      setInputValue(value);
      if (onChange) {
        onChange(value);
      }
    };
  
    return (
      <div className={`floating-label-input ${isFocused || inputValue ? 'focused' : ''}`}>
        <label>{label}</label>
        {type === 'select' ? (
          <Select
            placeholder={placeholder}
            value={inputValue}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            onDropdownVisibleChange={(open) => setIsFocused(open)}
          >
            {options.map((option, index) => (
              <Option key={index} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        ) : (
          <Input
            type={type}
            placeholder={placeholder}
            value={inputValue}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            allowClear={type === 'search'}
          />
        )}
      </div>
    );
  };
  
  export default FloatingLabelInput;
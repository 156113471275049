import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HeaderHome from './HeaderHome';
import ApplyNowForm from './ApplyNowForm';
export default function TechScaller() {
  const [modalShow, setModalShow] = useState(false);
  const handleModalShow = () => {
    setModalShow(true);
  }

  const handleModalClose = () => {
    setModalShow(false);
  }

  return (
    <React.Fragment>
      <HeaderHome />
      <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <title>Tech Scaler - Upskill to Get Better Job Opportunities</title>
          <meta name="description" content="Are you a working professional? Do you feel there is something stopping your growth? Upskill with Skillible today to get better job opportunities." />
          <link rel="canonical" href="http://skillible.io/" />
          <meta property="og:title" content="Tech Scaler - Upskill to Get Better Job Opportunities" />
          <meta property="og:description" content="Are you a working professional? Do you feel there is something stopping your growth? Upskill with Skillible today to get better job opportunities." />
          <meta property="og:url" content="http://skillible.io/" />
          <meta property="og:type" content="website" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:site_name" content="Skillible" />

        </Helmet>
      </HelmetProvider>
      <div>
        <section className="industries-banner">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="text-banner">
                  <h1>A program for Tech Fanatics: <span> Upskill, Reskill & Prepare for the Future of Work </span>
                  </h1>
                  <p className="banner-para3">
                    In a recent McKinsey study, 87% of respondents said their company already faces skill gaps or expects to within the
                    next few years. </p>
                  <p className="banner-para3">
                    An upskilled individual can safeguard himself/herself against layoffs and furloughs.
                    Similarly, a business can develop
                    progressive upskilled culture by investing more in enhancing the skills of the workforce. </p>
                  <ul className="get-quote mb-5 tech-scaler-btn">
                    <li>
                      <Link to="#" className="active" onClick={handleModalShow}>
                        <button type="button">Apply Now</button>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 text-center">
                <div className="img-banner industries-img1">
                  <div className="industries-bannerimg tech-scaller-w">
                    <span className="img">
                      <img src={require(`../assets/images/tech-scaller-banner.png`)} className="img-fluid" alt="Upskill And Reskill with Skillible  " />
                    </span>
                    <span className="icon2">
                      <img src={require(`../assets/images/tech-scaller-icon-2.png`)} className="img-fluid" alt="Upskill in software development with skillible" />
                    </span>
                    <span className="icon3">
                      <img src={require(`../assets/images/tech-scaller-icon-3.png`)} className="img-fluid" alt="code icon - skillible" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="opi-services-sec sce991">
          <div className="container">
            <div className="row displ-lrt1">
              <div className="col-md-6 opi-services float-start">
                <div className="opi-services-img">
                  <img src={require(`../assets/images/workforcce_ag.png`)} className="img-fluid opi-comm-img" alt="Use Skillible to create an Agile workforce" />
                  <span className="opi-comm-icon">
                    <img src={require(`../assets/images/tech-manual-icon.png`)} className="img-fluid" alt="The key To Workforce Agility - Skillible" />
                  </span>
                </div>
              </div>
              <div className="col-md-6   float-end">
                <div className="opi-servicesText ps-0 hding2">
                  <h2>The key To Workforce Agility</h2>
                  <ul className="">
                    <li>
                      <div className="mb-2">Our "Skill-First" model has helped us create a balanced workplace that closes the demand-supply gap. Skillible, as a learning and training platform, believes in filling skill gaps to achieve long-term growth and development.
                        By doing so, we can provide our learners with the best learning opportunities to find a new or upskilled role.
                        Not just amateurs but also organizations can use our learning and development platform to upskill their employees and create an agile workforce.
                      </div>
                      <div className="">
                        Skillible understands how time-consuming and exhausting the hiring process can be. And this is where our "talent pipeline" comes in. We offer you the most accessible tools to access our pool of resources and give you the confidence to function with the most diversified talent pipeline available in the tech industry. </div>
                    </li>




                  </ul>



                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mandr-linguists1 servs-edt">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-6 pe-0">
                <div className="mandr-linguists1-img ">
                  <div className="mand-trans-img">
                    <span>
                      <img src={require(`../assets/images/tech-scaller-why.png`)} className="img-fluid" alt="Tech Scaler with Skillible
" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="trans-serv-mandarin mandrain-text">
                  <h2>Tech Scaler with Skillible </h2>
                  <p className="expertise-para but-why-skli">But why? </p>
                  <div className="services-expertise trans-list-2">
                    <p>Have a look at our intangible features :</p>
                    <ul className="">
                      <li>In depth curriculum aligned with Industry Standards. </li>
                      <li> Learners are mentored by industry veterans. </li>
                      <li>Practical Experience through Real Life Projects</li>
                      <li>Expert trainers from leading tech organization. </li>
                      <li>Rapid doubt resolution platform</li>
                      <li>Total career support </li>
                      <li>Placement Support </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="our-clientbg1 sce991">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center hding2">
                <h2> This Course is for: </h2>
              </div>
              <div className="col-md-4 text-center mb-2">
                <div className="client-text text1">
                  <span>
                    <img src={require(`../assets/images/working-individals.png`)} className="img-fluid" alt="Skillible offers courses to individuals who want to upskill " />
                  </span>
                  <h3>Working individals who are looking forward to upskill.</h3>
                </div>
              </div>
              <div className="col-md-4 text-center mb-2">
                <div className="client-text text1">
                  <span>
                    <img src={require(`../assets/images/salesforce-development-fullstack.png`)} className="img-fluid" alt="Skillible offers Salesforce and FullStack Development courses" />
                  </span>
                  <h3>Individuals inspiring to learn Salesforce Development or Fullstack courses </h3>
                </div>
              </div>


              <div className="col-md-4 text-center">
                <div className="client-text text1">
                  <span>
                    <img src={require(`../assets/images/job-hike.png`)} className="img-fluid" alt="Achieve Growth Hikes with Skillible" />
                  </span>
                  <h3>Individuals looking out for job hikes.</h3>
                </div>
              </div>


            </div>
          </div>
        </section>
        <section className="testimonials-scn translation-a servs-edt">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="alltest-bg">
                  <div className="tstbig-img">
                    <span>
                      <img src={require(`../assets/images/why-choose-banner.png`)} className="" alt="Benefits Of Tech Scaler Program with skillible" />
                    </span>
                  </div>
                  <div className="translation-services-1">
                    <div className="test-text1">
                      <h2>Why Choose This Programme</h2>
                      <p> The demand for customized tech professionals has significantly increased in the tech ecosystem. The Tech Scaler program is an initiative to enhance learners' existing skills and upskill them for the ever-changing world of technology. This program can help you grow as a tech expert and equip you with tools and strategies to master problem-solving methods.</p>
                    </div>
                    <div className="services-expertise trans-list-1 good_enogh">
                      <h2>Benefits of this Program: -  </h2>
                      <ul className="">
                        <li> Gain on-the-job training and valuable experience by participating in our live Bootcamp classes. </li>
                        <li> Gain practical experience through real-world projects. </li>
                        <li> Career assistance provided by a dedicated recruitment team </li>
                        <li> Extensive curriculum created by top professionals. </li>
                        <li> A golden chance to learn from accomplished IT leaders</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="foundationl-cources programe-offer-skil">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center hding2">
                <h2> Programs we offer </h2>
              </div>
              <div className="col-md-6">
                <div className="foundational-cour">
                  <div className="foundationl-cources3">
                    <span>
                      <img src={require(`../assets/images/salesforce.png`)} className="img-fluid" alt="Programs offered by Skillible - Salesforce Development Program   " />
                    </span>
                  </div>
                  <div className="foundationl-cources4">
                    <div className="foundationl-cources5">
                      <p> If you are somebody who is new but passionate about programming, then this is the most functional course for you. Learn to develop enterprise-level Salesforce CRM solutions for your organization only with Skillible ! </p>
                      <h4 className="bottom-line-h">
                        <Link href="Salesforce"> Learn more </Link>
                      </h4>
                      <ul className="coach-footer">
                        <li>
                          <i className='fa fa-calendar blue-color'></i> Duration- <span> 12 Weeks</span>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="foundational-cour">
                  <div className="foundationl-cources3">
                    <span>
                      <img src={require(`../assets/images/fullstack.png`)} className="img-fluid" alt="Programs offered by Skillible - Full-Stack Development" />
                    </span>
                  </div>
                  <div className="foundationl-cources4">
                    <div className="foundationl-cources5">
                      <p> The demand for Full-Stack Developers has considerably increased at the present time. A developer can traverse through various job profiles in multiple industries. The program has been specially designed to give you the crux of front-end and back-end Java web developing technologies. So, get ready to learn and build end-to-end applications and much more only with Skillible. </p>
                      <h4 className="bottom-line-h">
                        <Link href="Fullstack"> Learn more </Link>
                      </h4>
                      <ul className="coach-footer">
                        <li>
                          <i className="fa fa-calendar" aria-hidden="true"></i>
                          <span>Duration- 26 Weeks </span>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="services-industry">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center hding2 ">
                <h2>Payment Modes </h2>
              </div>
            </div>
          </div>
        </section>
        <section className="services-industry2">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-4 text-center">
                <div className="services-in">
                  <span className="trans-img">
                    <img src={require(`../assets/images/isa.png`)} className="img-fluid" alt="Payment modes offered by Skillible - Sign an ISA and convert fees into easy EMI's" />
                  </span>
                  <div className="services-intext">
                    <h3>ISA</h3>
                    <p>You can sign an Income Share Agreement with Skillible to convert your fees into easy EMIs based on your salary and repayment capacity. </p>
                    <Link href="">
                      <i className="fas fa-chevron-right fa-fw"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 text-center">
                <div className="services-in">
                  <span className="trans-img">
                    <img src={require(`../assets/images/Sponsored.png`)} className="img-fluid" alt=" Payment modes offered by Skillible-  avail sponsorship" />
                  </span>
                  <div className="services-intext">
                    <h3>Sponsored</h3>
                    <p>You can opt for our sponsorship program where your employer sponsors the tuition fee for the skills they require. </p>
                    <Link href="">
                      <i className="fas fa-chevron-right fa-fw"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 text-center">
                <div className="services-in">
                  <span className="trans-img">
                    <img src={require(`../assets/images/Upfront.png`)} className="img-fluid" alt="Payment modes offered by Skillible- On Time Payment" />
                  </span>
                  <div className="services-intext">
                    <h3>Upfront</h3>
                    <p>You can also choose to pay your tuition fee in one installment for the course. </p>
                    <Link href="">
                      <i className="fas fa-chevron-right fa-fw"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sec-allin help-centersec">
          <div className="container-fluid">
            <div className="row">
              <div className="card shadow-none">
                <div className="col-md-12">
                  <div className="myProjectTabsText">
                    <div className="text-center hdwishlist pt-0 text-center hding2 ">
                      <h2>How to apply</h2>

                    </div>
                    <div className="col-md-12 text-center">
                      <div className="invite-clientScBg">
                        <div className="invite-clientSc1">
                          <div className="invite-clientimg invite1 text-start">
                            <img src={require(`../assets/images/step-1.png`)} className="img-fluid" alt="How To Apply in Skillible- step 1- Choose Program of your choice" />
                          </div>
                          <div className="invite-clientText">
                            <ul>
                              <li>
                                <span className="invite-client-icon">
                                  <img src={require(`../assets/images/step-1-icon1.png`)} className="img-fluid" alt="Programme icon - Skiillibe" />
                                </span>
                                <div className="clientTextdv">
                                  <div className="clientTextdv1">Step One </div>
                                  <div className="clientTextdv2">Choose the <span>appropriate</span> programme </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="invite-clientScBg2">
                        <div className="invite-clientSc1">
                          <div className="invite-clientText">
                            <ul>
                              <li>
                                <span className="invite-client-icon">
                                  <img src={require(`../assets/images/step-2-icon2.png`)} className="img-fluid" alt="How To Apply in Skillible- step 2- Choose your course of choice " />
                                </span>
                                <div className="clientTextdv">
                                  <div className="clientTextdv1">Step Two </div>
                                  <div className="clientTextdv2">Choose your course <span> of choice</span>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="invite-clientimg text-end invite2">
                            <img src={require(`../assets/images/step-2.png`)} className="img-fluid" alt="course icon - Skiillibe" />
                          </div>
                        </div>
                      </div>

                      <div className="invite-clientScBg3">
                        <div className="invite-clientSc1">

                          <div className="invite-clientimg text-start invite3">
                            <img src={require(`../assets/images/step-3.png`)} className="img-fluid" alt="How To Apply in Skillible - step 3 - Complete the admission process

" />
                            <div className="inviteText2">
                              <button className="invite-clients2" onClick={handleModalShow}>Apply Now</button>
                            </div>
                          </div>
                          <div className="invite-clientText">
                            <ul>
                              <li>
                                <span className="invite-client-icon">
                                  <img src={require(`../assets/images/step-3-icon3.png`)} className="img-fluid" alt="admission process icon - Skiillibe" />
                                </span>
                                <div className="clientTextdv">
                                  <div className="clientTextdv1">Step Three </div>
                                  <div className="clientTextdv2">Complete the <span> admission </span> process </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {modalShow && <ApplyNowForm show={modalShow} hide={handleModalClose} />}
      </div>

    </React.Fragment>
  )
}